import React from 'react'
import { EmptyPlaceholder } from '../../../EmptyPlaceholder'
import { CommentListItem } from './components/CommentListItem'
import { Comment } from '../../Comments.types'

type Props = {
    comments: Comment[]
    isLoading: boolean
    onRemoveComment: (commentId: string) => void
    checkIfCommentIsLoading: (commentId: string) => boolean
    minimized: boolean
}

export const CommentList = ({
    comments = [],
    isLoading,
    onRemoveComment,
    checkIfCommentIsLoading,
    minimized,
}: Props) => {
    const isCommentsLoading = isLoading && !comments.length
    const isCommentsEmpty = !isLoading && !comments.length

    return (
        <>
            {isCommentsLoading && <EmptyPlaceholder text="Fetching Comments..." />}
            {isCommentsEmpty && (
                <EmptyPlaceholder title="No Comments to display" subtitle="Be first to add a Comment" />
            )}
            {comments.map((comment) => {
                const handleRemove = () => onRemoveComment(comment._id)
                const isLoading = checkIfCommentIsLoading(comment._id)
                return (
                    <CommentListItem
                        key={comment._id}
                        comment={comment}
                        onRemove={handleRemove}
                        isLoading={isLoading}
                        minimized={minimized}
                    />
                )
            })}
        </>
    )
}
