import React, { useEffect, useState } from 'react'
import { useStyles } from '../../style/views'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Fade from '@mui/material/Fade'
import { useHistory } from 'react-router-dom'
import store from '../../redux/store'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import { useAuth0 } from '../../contexts/auth0-context'
import { setStrategy } from '../../redux/actions'
import { connect } from 'react-redux'
import { EmptyPlaceholder } from '../../shared/components/EmptyPlaceholder'

function mapDispatchToProps(dispatch) {
    return {
        setStrategy: (strat) => dispatch(setStrategy(strat)),
    }
}

const columns = [
    { id: 'createdAt', label: 'Snapshot Date' },
    { id: 'note', label: 'Note' },
    { id: 'creatorName', label: 'Created By' },
    {
        id: 'arrow',
        label: '',
        align: 'center',
    },
]

function ConnectedSnapshotTable(props) {
    const classes = useStyles()
    const history = useHistory()
    const { isAuthenticated, getTokenSilently } = useAuth0()
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [dataRows, setDataRows] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(false)

    useEffect(() => {
        // This fires when component mounts
        if (forceRefresh) {
            setForceRefresh(false)
        }

        if (!isAuthenticated) {
            return
        }

        getSnapshots()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceRefresh, isAuthenticated])

    async function getSnapshots() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let strat = store.getState().strategy
        let incomingSnapshots = await callAPI(
            endpoints.getStrategy.url + '?sourceId=' + (strat.sourceId ? strat.sourceId : strat._id),
            endpoints.getStrategy.method,
            token
        )

        if (incomingSnapshots.success) {
            createDataRows(
                incomingSnapshots.data.length > 0
                    ? incomingSnapshots.data.sort((a, b) => (a.createdAt < b.createdAt) - (a.createdAt > b.createdAt))
                    : []
            )
        } else {
            setLoaded(true)
        }
    }

    async function createDataRows(data) {
        let arr = []
        if (data && data.length) {
            for (const strategy of data) {
                arr.push({
                    strategy: strategy,
                    _id: strategy._id,
                    creatorName: strategy.creatorName,
                    note: strategy.snapshotNote,
                    createdAt: new Date(strategy.createdAt).toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    }),
                })
            }
        }
        setDataRows(arr)
        setLoaded(true)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const isSnapshotDataEmpty = !dataRows?.length;

    return (
        <Fade in={loaded} timeout={800}>
            <div className={classes.root}>
                {isSnapshotDataEmpty && (
                        <EmptyPlaceholder
                            title="No Snapshots to display"
                            subtitle="Select 'Save Snapshot' under 'Actions' menu to capture a new Snapshot"
                            sx={{ mt: 6 }}
                        />
                )}
                {!isSnapshotDataEmpty && (
                    <>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    fontSize: '1vw',
                                                    minWidth: column.minWidth,
                                                }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {dataRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                {columns.map((column) => {
                                                    const value = row[column.id]
                                                    if (column.id === 'arrow') {
                                                        return (
                                                            <TableCell
                                                                hover
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{
                                                                    fontSize: '.9vw',
                                                                }}
                                                                onClick={() => {
                                                                    props.setStrategy(row.strategy)
                                                                    history.push('/')
                                                                }}>
                                                                <ArrowForwardIcon color="primary" />
                                                            </TableCell>
                                                        )
                                                    } else {
                                                        return (
                                                            <TableCell
                                                                hover
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{
                                                                    fontSize: '.9vw',
                                                                }}>
                                                                {column.format && typeof value === 'number'
                                                                    ? column.format(value)
                                                                    : value}
                                                            </TableCell>
                                                        )
                                                    }
                                                })}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={dataRows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{ color: 'white' }}
                        />
                    </>
                )}
            </div>
        </Fade>
    )
}

const SnapshotTable = connect(null, mapDispatchToProps)(ConnectedSnapshotTable)

export default SnapshotTable
