import React, { useEffect, useState } from 'react'
import EvidenceTable from './EvidenceTable'
import '../../style/Views.scss'
import Grid from '@mui/material/Grid'
import LeftPanel from './../Support/PageLeftPanel'
import { ReactComponent as EvidenceIcon } from '../../assets/images/Ev_Icon.svg'
import { useHistory } from 'react-router-dom'
import { isStrategySelected } from '../../utilities/helpers'
import store from '../../redux/store'

export default function Evidence(props) {
    const history = useHistory()
    const [formData, setFormData] = useState({})

    function createNew(incomingData) {
        setFormData(incomingData)
    }

    // if no strategy selected, navigate to strategy selection page
    useEffect(() => {
        if (!isStrategySelected()) {
            history.push('/roadmaps')
        }
    })

    return (
        <Grid container item spacing={2} direction='column' sx={{ p: 2 }}>
            <LeftPanel imgsrc={EvidenceIcon}
                       text={(store.getState() && store.getState().settings?.displayNames?.evidence) || 'Loading'}
                       id={'evidence'} createNew={createNew} />
            <EvidenceTable newFormData={formData} autoOpenState={props.location.autoOpenData} />
        </Grid>
    )
}
