import React, { useEffect, useState } from 'react'
import MarketAccessGoal from './MarketAccessGoal'
import './gap.scss'
import * as _ from 'underscore'
import store from '../../redux/store'
import GapAssessment from './GapAssessment'
import ReimbursableFile from './ReimbursableFile'
import Fade from '@mui/material/Fade'
import Backdrop from '@mui/material/Backdrop'
import callSecureAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import { useAuth0 } from '../../contexts/auth0-context'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import EvidenceGapsRevamp from './EvidenceGapsRevamp'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { Button, DialogContent } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import imagepop from '../../assets/images/prodov-2EDIT2.png'
import { makeStyles } from '@mui/styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
    dialog: {
        borderRadius: '24px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        maxWidth: 'none',
        width: 588,
        height: 'max-content',
        padding: '30px',
        paddingTop: '50px',
        position: 'relative',
    },

    title: {
        fontWeight: 'semibold',
        color: '#808080',
        textAlign: 'left',
        fontSize: '16px',
    },
    evaluateText: {
        color: '#000000',
        fontSize: '24px',
        lineHeight: 1.3,
        fontWeight: '500',
        position: 'relative',
    },
    exploreText: {
        color: '#4D4D4D',
        fontSize: '16px',
        lineHeight: 1.5,
        fontWeight: 'regular',
    },

    primaryButton: {
        padding: '12px 35px',
        width: '100%',
    },
    outlinedButton: {
        padding: '12px 35px',
        width: '100%',
    },
    buttonGridItem: {
        padding: '0 8px',
    },
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function GapTable(props) {
    const { isAuthenticated, getTokenSilently, loading } = useAuth0()

    const classes = useStyles()
    const history = useHistory()
    const [gapAnalysis, setGapAnalysis] = useState({})
    const [gapDescription, setGapDescription] = useState('')
    const [newGapDescription, setNewGapDescription] = useState('')
    const [loaded, setLoaded] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(false)
    const [isFreeMode, setFreeMode] = useState(false)
    const isFreeTrial = useSelector((state) => state?.freeTrialStatus?.isFreeTrial)

    const { newFormData } = props

    let storeGapAnalysis = useSelector((state) => state.gapAnalysisView)

    async function addNewMarketAccessGoal(goal) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callSecureAPI(
            endpoints.addMarketAccessGoal.url + gapAnalysis._id,
            endpoints.addMarketAccessGoal.method,
            token,
            goal
        )
        setForceRefresh(true)
    }

    useEffect(() => {
        // track 'newFormData' from props for changes
        // if it changes, it means the Create Button is sending data
        if (!_.isEmpty(newFormData) && isAuthenticated) {
            gapAnalysis.goals.push(newFormData)
            addNewMarketAccessGoal(newFormData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newFormData])

    useEffect(() => {
        // This fires when component mounts and any time the VM list is modified
        if (forceRefresh) {
            setForceRefresh(false)
        }

        if (isAuthenticated && !loading) {
            getGapAnalysis()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceRefresh, isAuthenticated])

    async function getGapAnalysis() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let gap = await callSecureAPI(
            endpoints.getGapAnalysis.url + store.getState().strategy._id,
            endpoints.getGapAnalysis.method,
            token
        )
        setGapDescription(gap.data.description)
        setNewGapDescription(gap.data.description)
        setGapAnalysis(gap.data)
        setLoaded(true)
    }

    async function updateRating(newRating) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callSecureAPI(
            endpoints.updateGapAnalysis.url + gapAnalysis._id,
            endpoints.updateGapAnalysis.method,
            token,
            {
                overallSummaryRating: newRating,
                description: gapAnalysis.description,
                goals: gapAnalysis.goals,
                strategyId: gapAnalysis.strategyId,
                organizationId: gapAnalysis.organizationId,
                gapAssessment: gapAnalysis.gapAssessment,
                reimbursableFile: gapAnalysis.reimbursableFile,
            }
        )
        setForceRefresh(true)
    }

    async function deleteGoal(index) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        // updating gap analysis with new goals
        await callSecureAPI(
            endpoints.deleteMarketAccessGoal.url + gapAnalysis._id + '/' + index,
            endpoints.deleteMarketAccessGoal.method,
            token
        )
        setForceRefresh(true)
    }

    function handleTrialModalClose() {
        history.push('/')
    }

    return (
        <Fade in={loaded} timeout={800}>
            <Grid container item xs>
                {!_.isEmpty(gapAnalysis) && (
                    <>
                        <Dialog
                            open={isFreeTrial}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => handleTrialModalClose()}
                            aria-labelledby="alert-dialog-slide-gap"
                            aria-describedby="alert-dialog-slide-gap"
                            PaperProps={{ className: classes.dialog }}
                            style={{ backdropFilter: 'blur(5px)' }}>
                            <DialogTitle id="alert-dialog-slide-gap" className={classes.title}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="title2">Gap Analysis</Typography>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography variant="body8">
                                            Allows you to evaluate product data packages against Value and Access goals
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body4">
                                            To use the Gap Analysis and unlock all ATLAS features please upgrade your
                                            ATLAS free trial.
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img
                                            src={imagepop}
                                            alt="Landscape"
                                            style={{ width: 211, height: 211, borderRadius: '50%', margin: '15px', objectFit: 'cover' }}
                                        />
                                    </Grid>
                                    <Grid item container spacing={2} justifyContent="flex-end">
                                        <Grid item xs="auto">
                                            <Button
                                                fullWidth
                                                variant="contained-sm-button"
                                                onClick={() => handleTrialModalClose()}>
                                                OK
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>

                        {storeGapAnalysis && storeGapAnalysis === 'gapAssessment' && (
                            <GapAssessment goal={gapAnalysis.goals} />
                        )}
                        {storeGapAnalysis && storeGapAnalysis === 'reimbursableFile' && (
                            <ReimbursableFile element={gapAnalysis.reimbursableFile} />
                        )}
                        {storeGapAnalysis && storeGapAnalysis === 'evidenceGaps' && (
                            <EvidenceGapsRevamp element={gapAnalysis.evidenceGaps} />
                        )}
                    </>
                )}
            </Grid>
        </Fade>
    )
}
