import Typography from '@mui/material/Typography'
import React, { useEffect, useRef, useState } from 'react'
import Stack from '@mui/material/Stack'
import { CommentInput } from './components/CommentInput'
import { useCommentsQueries } from './useCommentsQueries'
import { useCommentsStyles } from './Comments.styles'
import { CommentList } from './components/CommentList'
import usePrevious from '../../hooks/usePrevious'
import { CommentsProps } from './Comments.types'
import clsx from 'clsx'

export const Comments = ({ dataId, dataType, dataCategory, minimized = false }: CommentsProps) => {
    const styles = useCommentsStyles()
    const commentsListContainerRef = useRef<HTMLDivElement>(null)

    const [inputValue, setInputValue] = useState('')

    const commentRemovingQueue = useRef<Record<number, boolean>>({})

    const addCommentToRemovingQueue = (commentId: string) => {
        commentRemovingQueue.current[commentId] = true
    }

    const deleteCommentFromRemovingQueue = (commentId: string) => {
        delete commentRemovingQueue.current[commentId]
    }

    const checkIfCommentIsInRemovingQueue = (commentId: string) => {
        return commentRemovingQueue.current[commentId]
    }

    const clearInputValue = () => {
        setInputValue('')
    }

    const onPostComment = () => {
        clearInputValue()
        scrollToTheCommentListBottom()
    }

    const { getCommentsQuery, postCommentMutation, removeCommentMutation } = useCommentsQueries({
        dataId,
        dataType,
        dataCategory,
        onPostComment,
        onRemoveComment: deleteCommentFromRemovingQueue,
    })
    const previousCommentsData = usePrevious(getCommentsQuery.data)

    const scrollToTheCommentListBottom = (behavior: ScrollBehavior = 'smooth') => {
        setTimeout(
            () =>
                commentsListContainerRef?.current.scrollTo({
                    top: commentsListContainerRef?.current.scrollHeight,
                    behavior,
                }),
            0
        )
    }

    useEffect(() => {
        const isInitialFetch = !previousCommentsData && !!getCommentsQuery.data
        if (isInitialFetch) {
            scrollToTheCommentListBottom('auto')
        }
    }, [getCommentsQuery.data, previousCommentsData])

    const handleAddComment = () => {
        postCommentMutation.mutate(inputValue)
    }

    const handleRemoveComment = (commentId: string) => {
        removeCommentMutation.mutate(commentId)
        addCommentToRemovingQueue(commentId)
    }

    return (
        <Stack
            className={clsx(styles.container, minimized && styles.minimizedContainer)}
            position="relative"
            overflow="hidden"
            height="100%"
            width="100%">
            {!minimized && (
                <Typography
                    className={styles.title}
                    textAlign="center"
                    // @ts-expect-error theme is not typed properly
                    variant="title3">
                    Comments
                </Typography>
            )}

            <Stack
                ref={commentsListContainerRef}
                flex={1}
                overflow="auto"
                className={clsx(styles.commentList, minimized && styles.commentListMinimized)}>
                <CommentList
                    comments={getCommentsQuery.data}
                    isLoading={getCommentsQuery.isLoading}
                    onRemoveComment={handleRemoveComment}
                    checkIfCommentIsLoading={checkIfCommentIsInRemovingQueue}
                    minimized={minimized}
                />
            </Stack>

            <Stack p={2} pt={0}>
                <CommentInput
                    value={inputValue}
                    onChange={setInputValue}
                    onSubmit={handleAddComment}
                    isLoading={getCommentsQuery.isLoading || postCommentMutation.isLoading}
                />
            </Stack>
        </Stack>
    )
}
