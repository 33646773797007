import callAPI from '../../../API/callSecureAPI'
import endpoints from '../../../API/endpoints'
import { ValueNarrative } from './useGetValueNarrativesQuery'
import { useAuth0 } from '../../../contexts/auth0-context'
import { useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useInvalidateGetValueNarrativesQuery } from './useInvalidateGetValueNarrativesQuery'

type UseAddValueNarrativeMutationProps = {
    onAddValueNarrative?: () => void
}

export const useAddValueNarrativeMutation = ({ onAddValueNarrative }: UseAddValueNarrativeMutationProps) => {
    const { getTokenSilently } = useAuth0()

    // @ts-expect-error store is not typed properly
    const strategyId = useSelector((state) => state.strategy?._id)
    // @ts-expect-error store is not typed properly
    const organizationId = useSelector((state) => state.strategy?.organizationId)

    const addValueNarrative = async (valueNarrative: Pick<ValueNarrative, 'name' | 'categoryId'>) => {
        try {
            const token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
            const { url, method } = endpoints.createValueNarrative

            const postObject = {
                ...valueNarrative,
                valueMessages: [],
                strategyId,
                organizationId,
            }

            await callAPI(url, method, token, postObject)
        } catch (e) {
            console.log(e)
        }
    }

    const { invalidateGetValueNarrativesQuery } = useInvalidateGetValueNarrativesQuery()

    return useMutation(addValueNarrative, {
        onSuccess: async () => {
            await invalidateGetValueNarrativesQuery()
            onAddValueNarrative?.()
        },
    })
}
