import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export const useCommentInputStyles = makeStyles((theme: Theme) => ({
    root: {
        borderRadius: '12px !important',
        backgroundColor: theme.palette.figma.grayscale.contentWrapper,
        '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `${theme.palette.figma.primary} !important`,
            }
        },
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `${theme.palette.figma.primaryHover} !important`,
            }
        }
    },
    input: {
        '&::placeholder': {
            fontSize: '16px',
        },
    },
    notchedOutline: {
        color: `${theme.palette.figma.grayscale.textHier2} !important`,
        '-webkit-text-fill-color': `${theme.palette.figma.grayscale.textHier2} !important`,
        borderColor: `${theme.palette.figma.grayscale.stroke} !important`,
    },
    iconButton: {
        color: `${theme.palette.figma.grayscale.stroke} !important`,
        padding: `${theme.spacing(1)} !important`,
        margin: `${theme.spacing(-1)} !important`,
    }
}))
