import React, { useEffect } from 'react'
import SnapshotTable from './SnapshotTable'
import Grid from '@mui/material/Grid'
import { useHistory } from 'react-router-dom'
import { isStrategySelected } from '../../utilities/helpers'

export default function Snapshots() {
    const history = useHistory()

    // if no strategy selected, navigate to strategy selection page
    useEffect(() => {
        if (!isStrategySelected()) {
            history.push('/roadmaps')
        }
    })

    return (
        <Grid container item>
            <SnapshotTable />
        </Grid>
    )
}
