import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'
import { Auth0Provider } from './contexts/auth0-context'
import './style/styles.scss'
import { Provider } from 'react-redux'
import store from './redux/store/index'
import { QueryClient, QueryClientProvider } from 'react-query'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, info) {
        // Example "componentStack":
        //   in ComponentThatThrows (created by App)
        //   in ErrorBoundary (created by App)
        //   in div (created by App)
        //   in App
        console.log(error, info.componentStack)
    }

    render() {
        if (this.state.hasError) {
            localStorage.removeItem('state')
            // delete browser cache and hard reload
            window.location.reload(true)
            // You can render any custom fallback UI
            return this.props.fallback
        }

        return this.props.children
    }
}

const queryClient = new QueryClient()

ReactDOM.render(
    <ErrorBoundary fallback={<></>}>
        <Auth0Provider>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </Provider>
        </Auth0Provider>
    </ErrorBoundary>,
    document.getElementById('root')
)
