import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Grid from '@mui/material/Grid'
import { ReactComponent as UploadIcon } from '../../assets/images/upload-bottom.svg'
import DescriptionIcon from '@mui/icons-material/Description'


const Dropzone = ({ generate, setFile }) => {
    const dropzoneStyle = {
        width: '99%',
        height: '120px',
        border: '2px dashed #0049EA',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px',
        paddingRight: '10px',
    }
    const [files, setFiles] = useState([])
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles?.length) {
            setFiles(acceptedFiles)
        }
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, multiple: false,
    })

    useEffect(() => {
        // Revoke the data uris to avoid memory leaks
        if (files?.length) {
            generate({ link: files[0] })
            return () => files.forEach(file => URL.revokeObjectURL(file.preview))
        }
    }, [files])

    const removeFile = name => {
        setFiles(files => files.filter(file => file.name !== name))
        setFile({})
    }

    return (
        <form>
            {files.length === 0 && (
                <a href={'javascript:void(0)'} style={{ color: 'inherit' }}>
                    <Grid container
                          {...getRootProps({
                              style: dropzoneStyle,
                          })}
                        sx={{ backgroundColor: 'background.paper' }}
                    >
                        <input {...getInputProps()} />
                        <Grid container alignItems='center' justifyContent='center' direction='column'>
                            <Grid item>
                                <UploadIcon style={{ height: '37px', width: '37px', color: '#0049EA', marginTop: '16px' }}/>
                            </Grid>
                            {isDragActive ? (
                                <Grid item><p>Drop PDF here...</p></Grid>
                            ) : (
                                <Grid item><p>Drop PDF / Click To Upload</p></Grid>
                            )}
                        </Grid>
                    </Grid>
                </a>

            )}

            {/* Preview */}
            <Grid container xs={12}
                  direction='column'
                  alignItems='center'
                  justifyContent='center'>
                {files.map(file => (
                    <Grid item border='2px blue solid' padding={2} borderRadius={'6px'}>
                        <button
                            type='button'
                            onClick={() => removeFile(file.name)}
                        >del
                        </button>
                        <p>
                            {file.name}
                        </p>
                        <DescriptionIcon color='primary' />
                    </Grid>

                ))}
            </Grid>
        </form>
    )
}

export default Dropzone
