export function setStrategy(payload) {
    return { type: 'SET_STRATEGY', payload }
}
export function setVMFilter(payload) {
    return { type: 'SET_VM_FILTER', payload }
}
export function setUserRecord(payload) {
    return { type: 'SET_USER_RECORD', payload }
}

export function setSelectedMarket(payload) {
    return { type: 'SET_SELECTED_MARKET', payload }
}

export function setGapAnalysisView(payload) {
    return { type: 'SET_GAP_ANALYSIS_VIEW', payload }
}

export function setSettings(payload) {
    return { type: 'SET_SETTINGS', payload }
}

export function setActivtyTableNumberOfItems(payload) {
    return { type: 'SET_ACTIVITY_TABLE_NUMBER', payload }
}

export function setSITableNumberOfItems(payload) {
    return { type: 'SET_SI_TABLE_NUMBER', payload }
}

export function setValueMessageTableNumberOfItems(payload) {
    return { type: 'SET_VM_TABLE_NUMBER', payload }
}

export function setEvidenceTableNumberOfItems(payload) {
    return { type: 'SET_EVIDENCE_TABLE_NUMBER', payload }
}

export function setSelectedOneDriveFile(payload) {
    return { type: 'SET_ONEDRIVE_FILE', payload }
}

export function setSearchResults(payload) {
    return { type: 'SET_SEARCH_RESULTS', payload }
}

export function setSearchQuery(payload) {
    return { type: 'SET_SEARCH_QUERY', payload }
}

export function setAutoOpenPanel(payload) {
    return { type: 'SET_AUTOOPEN_PANEL', payload }
}

export function reduxLogout() {
    return { type: 'LOGOUT' }
}

export function setFreeTrialStatus(payload) {
    return { type: 'SET_FREE_TRIAL_STATUS', payload }
}

export function setSnapshotModeNotification(payload) {
    return { type: 'SET_SNAPSHOT_MODE_NOTIFICATION', payload }
}

export function setSelectedValueNarrative(payload) {
    return { type: 'SET_SELECTED_VALUE_NARRATIVE', payload }
}

export function setSelectedValueNarrativeCategory(payload) {
    return { type: 'SET_SELECTED_VALUE_NARRATIVE_CATEGORY', payload }
}

export function setValueNarrativesViewMode(payload) {
    return { type: 'SET_VALUE_NARRATIVES_VIEW_MODE', payload }
}
