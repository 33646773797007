import { COLORS } from '../../../../style/colors'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { SvgIcon } from '@mui/material'
import React from 'react'
import { useIsDarkMode } from '../../../../shared/hooks/useIsDarkMode'
import { ReactComponent as GreenCircleIcon } from '../../../../assets/images/green_circle.svg'
import { ReactComponent as OrangeTriangleIcon } from '../../../../assets/images/orange_triangle.svg'
import { ReactComponent as RedSquareIcon } from '../../../../assets/images/red_square.svg'
import Stack from '@mui/material/Stack'

export const ValueNarrativesLegend = () => {
    const isDarkMode = useIsDarkMode()

    const statusesContainerStyles = {
        p: 2,
        borderRadius: 4,
        boxShadow: 0,
        backgroundColor: isDarkMode ? 'background.paper' : COLORS.lightQuillGrey,
    }

    return (
        <Stack direction="column" py={2}>
            <Typography variant="h5" sx={{ mb: 0.5, pl: 2 }}>
                Status
            </Typography>
            <Paper sx={statusesContainerStyles}>
                <Grid container columnGap={2} rowGap={1}>
                    <Grid item xs>
                        <Stack direction="column" gap={1}>
                            <Stack direction="row" gap={1} alignItems="center">
                                <SvgIcon fontSize="small" component={GreenCircleIcon} inheritViewBox />
                                <Typography
                                    // @ts-expect-error theme is not typed properly
                                    variant="body3">
                                    Supported
                                </Typography>
                            </Stack>
                            <Stack direction="row" gap={1} alignItems="center">
                                <SvgIcon fontSize="small" component={OrangeTriangleIcon} inheritViewBox />
                                <Typography
                                    // @ts-expect-error theme is not typed properly
                                    variant="body3">
                                    Supported-suboptimal
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs="auto">
                        <Stack direction="row" gap={1} alignItems="center">
                            <SvgIcon fontSize="small" component={RedSquareIcon} inheritViewBox />
                            <Typography
                                // @ts-expect-error theme is not typed properly
                                variant="body3">
                                Aspirational
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>
        </Stack>
    )
}
