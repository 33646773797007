import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import Fade from '@mui/material/Fade'
import TablePanel from '../Support/TablePanel'
import store from '../../redux/store'
import { useAuth0 } from '../../contexts/auth0-context'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import Grid from '@mui/material/Grid'
import { connect, useSelector } from 'react-redux'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as AddCircleIcon } from '../../assets/images/add-circle.svg'
import { ReactComponent as GreenCircleIcon } from '../../assets/images/green_circle.svg'
import { ReactComponent as OrangeTriangleIcon } from '../../assets/images/orange_triangle.svg'
import { ReactComponent as RedSquareIcon } from '../../assets/images/red_square.svg'
import { setStrategy, setValueMessageTableNumberOfItems } from '../../redux/actions'
import Typography from '@mui/material/Typography'
import { EnhancedTableHead, getComparator, stableSort, timeout } from '../../utilities/helpers'
import { GlobalStyles, SvgIcon } from '@mui/material'
import { EmptyPlaceholder } from '../../shared/components/EmptyPlaceholder'
import { arrayMove } from '@dnd-kit/sortable'
import { SortableTable } from '../../shared/components/SortableTable'
import Box from '@mui/material/Box'
import { useLocation } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'

function createData(value, status, evidence, activities, id, order, panel, isCountrySpecific) {
    return { value, status, evidence, activities, id, order, panel, isCountrySpecific }
}

function mapDispatchToProps(dispatch) {
    return {
        setStrategy: (strat) => dispatch(setStrategy(strat)),
        setVMTableNumberOfRows: (num) => dispatch(setValueMessageTableNumberOfItems(num)),
    }
}

function ConnectedVMTable(props) {
    const { getTokenSilently } = useAuth0()
    const [page, setPage] = React.useState(0)
    const [dataRows, setDataRows] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [vmData, setVMData] = useState({})
    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const [valueMessageForPanel, setValueMessageForPanel] = useState({})
    const [updateTableFromPanel, setUpdateTableFromPanel] = useState(false)
    const [navigationStack, setNavigationStack] = useState([])

    // Columns declared as const weren't loading Store in time for render.
    // eslint-disable-next-line no-unused-vars

    const [columns] = useState([
        { id: 'index', label: '', align: '', headPad: '' },
        { id: 'value', label: 'Title', align: 'left', headPad: 2.5 },
        { id: 'status', label: 'Status', align: 'center', headPad: 2.5 },
        {
            id: 'evidence',
            label:
                (store.getState().settings?.displayNames?.evidence &&
                    store.getState().settings?.displayNames?.evidence) ||
                'Loading',
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
            headPad: 2.5,
        },
        {
            id: 'activities',
            label:
                (store.getState().settings?.displayNames?.activities &&
                    store.getState().settings?.displayNames?.activities) ||
                'Loading',
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
            headPad: 2.5,
        },
        { id: 'panel', label: '', align: '', headPad: 2.5 },
    ])

    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('')

    const itemsToDisplay = useSelector((state) => state.valueMessagesTableNumberOfItems || 10)

    const handleSortDataRows = async (sortingEvent) => {
        const { active, over } = sortingEvent

        if (!over || active.id === over.id) {
            return
        }

        const indexOfDestinationRow = sortingEvent.over.data.current.sortable.index
        const indexOfSourceRow = sortingEvent.active.data.current.sortable.index
        const newDataRows = arrayMove(dataRows, indexOfSourceRow, indexOfDestinationRow)
        setDataRows(newDataRows)

        const token = await getTokenSilently({
            audience: 'https://atlas.aesara.com',
        })
        const ids = newDataRows.map((rowData) => rowData.id)
        await callAPI(endpoints.reorderVMs.url, endpoints.reorderVMs.method, token, ids)

        setOrderBy('index')
    }

    const vmDisplayName = store.getState().settings?.displayNames?.valueMessage || 'Loading'

    const location = useLocation()

    async function checkForAutoPanel() {
        if (location?.autoOpenData?.autoOpenData) {
            setValueMessageForPanel(location?.autoOpenData?.autoOpenData)
            setNavigationStack(location?.autoOpenData.previousDataStack ? location?.autoOpenData.previousDataStack : [])
            await timeout(700)
            setIsPanelOpen(true)
        }
    }

    async function getVMData() {
        createDataRows(props.valueMessages)

        setLoaded(true)
        let vms = {}
        for (let vm of props.valueMessages) {
            vms[vm._id] = vm
        }
        setVMData(vms)
        if (valueMessageForPanel) {
            setValueMessageForPanel(vms[valueMessageForPanel._id])
        }
    }

    useEffect(() => {
        if (updateTableFromPanel) {
            setUpdateTableFromPanel(false)
            props.onUpdate();
        }

        getVMData()

    }, [updateTableFromPanel, props.valueMessages])

    useEffect(() => {
        checkForAutoPanel()
    }, [location?.autoOpenData?.isAutoOpenPanel])

    function createDataRows(data) {
        let arr = []
        data.forEach((vm) => {
            let dataRow = createData(
                // vm.priority,
                vm.name,
                vm.status,
                vm.linkedEvidence.length,
                vm.linkedActivities.length,
                vm._id,
                vm.order,
                '',
                vm.isCountrySpecific ? vm.isCountrySpecific : false
            )
            arr.push(dataRow)
        })
        arr.sort((a, b) => a.order - b.order)
        setDataRows(arr)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        props.setVMTableNumberOfRows(+event.target.value)
        setPage(0)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    function handlePanelOpen(id) {
        setValueMessageForPanel(vmData[id])
        setIsPanelOpen(true)
    }

    function handlePanelClose() {
        setValueMessageForPanel({})
        setIsPanelOpen(false)
        setNavigationStack([])
        location.autoOpenData = null
    }

    const globalStyles = (
        <GlobalStyles
            styles={{
                '.MuiTableCell-root': {
                    borderBottom: 'none !important',
                },
            }}
        />
    )

    const sortedDataRows = stableSort(dataRows, getComparator(order, orderBy)).slice(
        page * itemsToDisplay,
        page * itemsToDisplay + itemsToDisplay
    )
    const isValueMessagesDataEmpty = !sortedDataRows?.length

    const displayName =
        (store.getState().settings?.displayNames?.valueMessages &&
            store.getState().settings?.displayNames?.valueMessages) ||
        'Loading'
    return (
        <>
            {globalStyles}
            <Fade in={loaded} timeout={800}>
                <Grid container item xs>
                    <Box display="flex" gap={1} alignItems='center' py={1} width="100%">
                        <Typography color="figma.grayscale.textHier1" variant="h4" fontWeight={700}>
                            {displayName}
                        </Typography>
                        <IconButton onClick={props.handleCreateButtonClick}>
                            <SvgIcon component={AddCircleIcon} color="primary" inheritViewBox fontSize="medium" />
                        </IconButton>
                    </Box>
                    {isValueMessagesDataEmpty && (
                        <EmptyPlaceholder
                            title={`No ${vmDisplayName} to display`}
                            subtitle={`Click 'Add New' on the left panel to create a new ${vmDisplayName}`}
                        />
                    )}
                    {!isValueMessagesDataEmpty && (
                        <>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table" size="small">
                                    <EnhancedTableHead
                                        columns={columns}
                                        order={order}
                                        orderBy={orderBy}
                                        align={columns.align}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <SortableTable.Body data={sortedDataRows} onSort={handleSortDataRows}>
                                        {({ rowData, index, rowProps }) => (
                                            <SortableTable.Row {...rowProps}>
                                                {columns.map((column) => {
                                                    const value = rowData[column.id]
                                                    if (column.id === 'status') {
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                sx={{ border: 'none' }}>
                                                                <Tooltip title={value}>
                                                                    <Box>
                                                                        {value === 'Supported' && (
                                                                            <SvgIcon
                                                                                fontSize="small"
                                                                                component={GreenCircleIcon}
                                                                                inheritViewBox
                                                                            />
                                                                        )}
                                                                        {value === 'Supported-suboptimal' && (
                                                                            <SvgIcon
                                                                                fontSize="small"
                                                                                component={OrangeTriangleIcon}
                                                                                inheritViewBox
                                                                            />
                                                                        )}
                                                                        {value === 'Aspirational' && (
                                                                            <SvgIcon
                                                                                fontSize="small"
                                                                                component={RedSquareIcon}
                                                                                inheritViewBox
                                                                            />
                                                                        )}
                                                                    </Box>
                                                                </Tooltip>
                                                            </TableCell>
                                                        )
                                                    } else if (column.id === 'index') {
                                                        return (
                                                            <TableCell
                                                                hover
                                                                key={column.id}
                                                                align={column.align}
                                                                sx={{
                                                                    border: 'none',
                                                                    borderTopLeftRadius: index === 0 ? 14 : 0,
                                                                    borderBottomLeftRadius:
                                                                        index ===
                                                                        dataRows.slice(
                                                                            page * itemsToDisplay,
                                                                            (page + 1) * itemsToDisplay
                                                                        ).length -
                                                                            1
                                                                            ? 14
                                                                            : 0,
                                                                }}>
                                                                <Typography variant="body9" sx={{ color: '#808080' }}>
                                                                    {index + page * itemsToDisplay + 1}.
                                                                </Typography>
                                                            </TableCell>
                                                        )
                                                    } else if (column.id === 'value' && rowData['isCountrySpecific']) {
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                sx={{ border: 'none' }}>
                                                                <Typography variant="body1">
                                                                    {column.format && typeof value === 'number'
                                                                        ? column.format(value)
                                                                        : value}
                                                                </Typography>
                                                            </TableCell>
                                                        )
                                                    } else if (column.id === 'panel') {
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                sx={{
                                                                    border: 'none',
                                                                    borderTopRightRadius: index === 0 ? 14 : 0,
                                                                    borderBottomRightRadius:
                                                                        index ===
                                                                        dataRows.slice(
                                                                            page * itemsToDisplay,
                                                                            (page + 1) * itemsToDisplay
                                                                        ).length -
                                                                            1
                                                                            ? 14
                                                                            : 0,
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => handlePanelOpen(rowData.id)}>
                                                                <IconButton
                                                                    sx={(theme) => ({
                                                                        padding: theme.spacing(1.5),
                                                                        marginInline: theme.spacing(-0.75),
                                                                    })}>
                                                                    <ArrowForwardIosIcon color="primary" />
                                                                </IconButton>
                                                            </TableCell>
                                                        )
                                                    } else {
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                sx={{ border: 'none' }}>
                                                                <Typography
                                                                    variant={
                                                                        column.format && typeof value === 'number'
                                                                            ? 'h4'
                                                                            : 'body1'
                                                                    }
                                                                    color={
                                                                        column.format && typeof value === 'number'
                                                                            ? 'primary'
                                                                            : ''
                                                                    }>
                                                                    {column.format && typeof value === 'number'
                                                                        ? column.format(value)
                                                                        : value}
                                                                </Typography>
                                                            </TableCell>
                                                        )
                                                    }
                                                })}
                                            </SortableTable.Row>
                                        )}
                                    </SortableTable.Body>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                component="div"
                                count={dataRows.length}
                                rowsPerPage={itemsToDisplay}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    )}
                    {isPanelOpen && (
                        <TablePanel
                            title={valueMessageForPanel?.name}
                            data={valueMessageForPanel}
                            type={'valueMessage'}
                            closePanel={handlePanelClose}
                            openPanelAction={true}
                            updateParent={setUpdateTableFromPanel}
                            navigationStack={navigationStack}
                        />
                    )}
                </Grid>
            </Fade>
        </>
    )
}

const VMTable = connect(null, mapDispatchToProps)(ConnectedVMTable)

export default VMTable
