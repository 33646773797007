import React, { ComponentProps } from 'react'
import Box from '@mui/material/Box'
import { Tabs } from '@mui/material/'
import Tab from '@mui/material/Tab'
import { ValueNarrativeCategoryTabContent } from './ValueNarrativeCategoryTabContent'
import { ValueNarrativesAllCategoriesTabContent } from './ValueNarrativesAllCategoriesTabContent'
import { ValueNarrative, ValueNarrativeCategory } from '../../queries/useGetValueNarrativesQuery'
import { EmptyPlaceholder } from '../../../../shared/components/EmptyPlaceholder'
import { useValueNarrativeCategoryColor } from '../../hooks/useValueNarrativeCategoryColor'

type Props = {
    isAIEnabled: boolean
    isAllCategorySelected: boolean
    categories: ValueNarrativeCategory[]
    selectedCategory: ValueNarrativeCategory
    selectedNarrative: ValueNarrative | null
    handleSelectCategory: (categoryId: string) => void
    handleSelectNarrative: (categoryId: string, narrativeId: string) => void
}

export const TAB_HEIGHT = '42px'

export const ValueNarrativeCategoryTabsWrapper = ({
    isAIEnabled,
    isAllCategorySelected,
    selectedCategory,
    selectedNarrative,
    handleSelectCategory,
    handleSelectNarrative,
    categories,
}: Props) => {
    const handleClickOnValueNarrative = (categoryId: string, narrativeId: string) => {
        handleSelectCategory(categoryId)
        handleSelectNarrative(categoryId, narrativeId)
    }

    const { getCategoryColorByIndex } = useValueNarrativeCategoryColor()
    const selectedCategoryColor = getCategoryColorByIndex(categories.indexOf(selectedCategory))

    const tabProps: Partial<ComponentProps<typeof Tab>> = {
        sx: {
            height: TAB_HEIGHT,
            maxHeight: TAB_HEIGHT,
            minHeight: TAB_HEIGHT,
            borderTop: '1px solid',
            borderTopColor: 'transparent !important',
            borderBottom: '1px solid',
            borderColor: 'figma.grayscale.stroke',
            fontSize: '16px',
            fontWeight: '400',
            fontFamily: 'acumin-pro !important',
            textTransform: 'capitalize',
            color: 'figma.grayscale.textHier2',
            minWidth: '220px',
            maxWidth: '25vw',
            paddingInline: '12px',
            paddingBlock: '4px',
            ' > div': {
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                ' > span': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                },
            },
            '&.Mui-selected': {
                borderTopWidth: '2px',
                borderBottomWidth: '2px',
                borderColor: 'figma.primary',
                fontWeight: 600,
                lineHeight: '24px',
                ' > div': {
                    backgroundColor: 'figma.primary2',
                },
            },
        },
        wrapped: true,
        disableRipple: true,
    }

    const getNumberOfNarrativesPerCategory = (categoryId: string) => {
        if (categoryId === 'All') {
            return categories.reduce((acc, item) => acc + item.narratives.length, 0)
        }
        return categories.find((category) => category._id === categoryId)?.narratives?.length
    }

    return (
        <Box ml={-2} sx={{ flexGrow: 1, display: 'flex', height: '100%', width: '100%' }}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                scrollButtons="auto"
                value={selectedCategory._id}
                onChange={(_, category) => handleSelectCategory(category)}
                sx={{
                    borderRight: 1,
                    borderColor: 'figma.grayscale.stroke',
                    width: 'max-content',
                    minWidth: 'max-content',
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                }}>
                <Tab
                    label={
                        <div>
                            <span>{`All (${getNumberOfNarrativesPerCategory('All')})`}</span>
                        </div>
                    }
                    value={'All'}
                    {...tabProps}
                />
                {categories
                    .sort((x, y) => x.order - y.order)
                    .map((category) => (
                        <Tab
                            key={category._id}
                            label={
                                <div>
                                    <span>{`${category.name} (${getNumberOfNarrativesPerCategory(
                                        category._id
                                    )})`}</span>
                                </div>
                            }
                            value={category._id}
                            {...tabProps}
                        />
                    ))}
            </Tabs>
            <Box pl={3} pb={3} flex={1}>
                {isAllCategorySelected ? (
                    <ValueNarrativesAllCategoriesTabContent
                        categories={categories}
                        handleClickOnValueNarrative={handleClickOnValueNarrative}
                    />
                ) : (
                    <>
                        {selectedNarrative ? (
                            <ValueNarrativeCategoryTabContent
                                isAIEnabled={isAIEnabled}
                                selectedCategoryColor={selectedCategoryColor}
                                selectedCategory={selectedCategory}
                                selectedNarrative={selectedNarrative}
                                handleSelectNarrative={handleSelectNarrative}
                            />
                        ) : (
                            <Box pt={4}>
                                <EmptyPlaceholder
                                    title="No Value Narratives to display"
                                    subtitle="Click 'Add New' on the left panel to create a new Value Narrative"
                                />
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Box>
    )
}
