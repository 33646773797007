import { useAuth0 } from '../../../contexts/auth0-context'
import callSecureAPI from '../../../API/callSecureAPI'
import endpoints from '../../../API/endpoints'
import { useSelector } from 'react-redux'
import callAPI from '../../../API/callSecureAPI'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Comment, CommentsProps } from './Comments.types'
import { checkIfTableDataType } from './Comments.helpers'
import { useMemo } from 'react'

interface UseCommentsQueriesProps extends CommentsProps {
    onPostComment?: () => void
    onRemoveComment?: (commentId: string) => void
}

const TWO_MINUTES = 1000 * 60 * 2;

export const useCommentsQueries = ({
    dataId,
    dataType,
    dataCategory,
    onPostComment,
    onRemoveComment,
}: UseCommentsQueriesProps) => {
    // @ts-expect-error store is not typed properly
    const userId = useSelector((state) => state.userRecord?._id)
    // @ts-expect-error store is not typed properly
    const strategyId = useSelector((state) => state.strategy?._id)
    // @ts-expect-error store is not typed properly
    const organizationId = useSelector((state) => state.strategy?.organizationId)

    const { getTokenSilently } = useAuth0()

    const isTableDataType = useMemo(() => checkIfTableDataType(dataType), [dataType])

    const getComments = async () => {
        const token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        if (isTableDataType) {
            const { url, method } = endpoints.getTableComments

            const response = await callSecureAPI(
                url + dataType + '/' + dataId + (dataCategory ? `/${dataCategory}` : ''),
                method,
                token
            )

            return response?.data || []
        } else {
            const { url, method } = endpoints.getDataComments

            const response = await callSecureAPI(url + dataId, method, token)

            return response?.data || []
        }
    }

    const postComment = async (text: string) => {
        const { url, method } = endpoints.postDataComment

        const token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        await callSecureAPI(url, method, token, {
            text,
            userId,
            dataType,
            dataId,
            strategyId,
            organizationId,
            category: dataCategory,
        })
    }

    const removeComment = async (commentId: string) => {
        const { url, method } = endpoints.deleteDataComment

        const token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        await callAPI(url + commentId, method, token)
    }

    const queryClient = useQueryClient()

    const getCommentsQuery = useQuery<Comment[]>(['comments', dataId, dataType, dataCategory], getComments, {
        refetchOnWindowFocus: 'always',
        refetchInterval: TWO_MINUTES,
        cacheTime: TWO_MINUTES,
        staleTime: TWO_MINUTES
    })

    const invalidateGetCommentsQuery = async () => {
        await queryClient.invalidateQueries('comments')
    }

    const postCommentMutation = useMutation(postComment, {
        onSuccess: async () => {
            await invalidateGetCommentsQuery()
            onPostComment?.()
        },
    })

    const removeCommentMutation = useMutation(removeComment, {
        onSuccess: async () => {
            await invalidateGetCommentsQuery()
        },
        onSettled: async (data, error, commentId) => {
            onRemoveComment?.(commentId)
        },
    })

    return {
        getCommentsQuery,
        postCommentMutation,
        removeCommentMutation,
    }
}
