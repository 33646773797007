import React, { useEffect, useState } from 'react'

import Stack from '@mui/material/Stack'
import { ValueNarrativeCategoryTabsWrapper } from './components/ValueNarrativeCategoryTabs/ValueNarrativeCategoryTabsWrapper'
import { useHistory, useLocation } from 'react-router-dom'
import { ValueNarrativesHeader } from './components/ValueNarrativesHeader/ValueNarrativesHeader'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import store from '../../redux/store'
import { useAuth0 } from '../../contexts/auth0-context'
import { useValueNarratives } from './useValueNarratives'
import { Fade } from '@mui/material/'
import { useSelector } from 'react-redux'

const ValueNarrativesView = () => {
    const history = useHistory()

    // @ts-expect-error store is not typed properly
    const strategyId = useSelector((state) => state.strategy?._id)

    useEffect(() => {
        if (!strategyId) {
            history.push('/roadmaps')
        }
    }, [strategyId])

    const { isAuthenticated, getTokenSilently } = useAuth0()
    const [isAIEnabled, setIsAIEnabled] = useState(false)

    async function authenticateAI() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingOrg = await callAPI(
            endpoints.getOrganizationById.url + store.getState().strategy.organizationId,
            endpoints.getOrganizationById.method,
            token
        )
        if (incomingOrg.success && incomingOrg?.data?.isAIEnabled) {
            setIsAIEnabled(true)
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            authenticateAI()
        }
    }, [isAuthenticated])

    const {
        isCategoriesDataReady,
        isAllCategorySelected,
        categories,
        selectedCategory,
        selectedNarrative,
        handleSelectCategory,
        handleSelectNarrative,
    } = useValueNarratives()

    const location = useLocation()

    useEffect(() => {
        const categoryId = location.autoOpenData?.autoOpenData?.categoryId
        const narrativeId = location.autoOpenData?.autoOpenData?.narrativeId

        if (categoryId && narrativeId) {
            handleSelectCategory(categoryId)
            handleSelectNarrative(categoryId, narrativeId)
        } else {
            location.autoOpenData = null
        }
    }, [location?.autoOpenData?.isAutoOpenPanel])

    return (
        <Fade in timeout={800}>
            <Stack p={2} gap={1} flex={1}>
                {isCategoriesDataReady && (
                    <>
                        <ValueNarrativesHeader isAllCategorySelected={isAllCategorySelected} selectedCategory={selectedCategory} categories={categories} />
                        <ValueNarrativeCategoryTabsWrapper
                            isAIEnabled={isAIEnabled}
                            isAllCategorySelected={isAllCategorySelected}
                            categories={categories}
                            selectedCategory={selectedCategory}
                            selectedNarrative={selectedNarrative}
                            handleSelectCategory={handleSelectCategory}
                            handleSelectNarrative={handleSelectNarrative}
                        />
                    </>
                )}
            </Stack>
        </Fade>
    )
}

export default ValueNarrativesView
