export const formatFreeTrialEndDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
    }

    const localeString = date.toLocaleDateString('en-US', options)

    const [weekday, month, day] = localeString.split(' ')

    return `${weekday} ${day} ${month}`
}
