import React, { useEffect, useState } from 'react'
import store from '../../redux/store'
import endpoints from '../../API/endpoints'
import callAPI from '../../API/callSecureAPI'
import { useAuth0 } from '../../contexts/auth0-context'
import { connect, useSelector } from 'react-redux'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'
import parse from 'autosuggest-highlight/parse'
import SearchIcon from '@mui/icons-material/Search'
import { styled, lighten, darken } from '@mui/system'
import match from 'autosuggest-highlight/match'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import Link from '@mui/material/Link'
import SvgIcon from '@mui/material/SvgIcon'
import { setSearchQuery, setSearchResults } from '../../redux/actions'

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}))

const GroupItems = styled('ul')({
    padding: 0,
})

function mapDispatchToProps(dispatch) {
    return {
        setSearchResults: (results) => dispatch(setSearchResults(results)),
        setSearchQuery: (query) => dispatch(setSearchQuery(query)),
    }
}

function ConnectedSearchBar(props) {
    const history = useHistory()
    const { isAuthenticated, getTokenSilently, loading } = useAuth0()
    const [searchData, setSearchData] = useState([])
    const [open, setOpen] = React.useState(false)
    const [forceRefresh, setForceRefresh] = React.useState(false)

    const [searchText, setSearchText] = React.useState('')

    let dataLabels = ['Value Messages', 'Evidence', 'Activities']
    const isDarkMode = useSelector((state) => (state?.userRecord ? state?.userRecord?.isDarkMode : true))
    let strat = useSelector((state) => state.strategy)

    useEffect(() => {
        if (isAuthenticated) {
            getSearchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [strat, loading])

    useEffect(() => {
        if (forceRefresh) {
            setForceRefresh(false)
            setOpen(false)
        }
    }, [forceRefresh])

    async function getSearchData() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let vms = await callAPI(
            endpoints.getValueMessagesForTable.url + store.getState().strategy._id,
            endpoints.getValueMessagesForTable.method,
            token
        )
        let ev = await callAPI(
            endpoints.getEvidenceForTable.url + store.getState().strategy._id,
            endpoints.getEvidenceForTable.method,
            token
        )
        let acts = await callAPI(
            endpoints.getActivitiesForTable.url + store.getState().strategy._id,
            endpoints.getActivitiesForTable.method,
            token
        )

        let aggData = []
        aggData = aggData.concat(
            vms.data.map((x) => {
                return {
                    text: x.name,
                    type: 'Value Messages',
                    id: x._id,
                    data: x,
                }
            })
        )
        aggData = aggData.concat(
            ev.data.map((x) => {
                return {
                    text: x.name,
                    type: 'Evidence',
                    id: x._id,
                    data: x,
                }
            })
        )
        aggData = aggData.concat(
            acts.data.map((x) => {
                return {
                    text: x.title,
                    type: 'Activities',
                    id: x._id,
                    data: x,
                }
            })
        )

        setSearchData(aggData)
    }

    function nullifySearchQuery() {
        console.log('nullify')
        setOpen(false)
        setSearchText('')
        props.setSearchQuery('')
        setForceRefresh(true)
        props.manualClickAway()
    }

    return (
        <Autocomplete
            freeSolo
            id="combo-box-demo"
            options={searchData}
            open={open}
            sx={{ backgroundColor: isDarkMode ? '#1f2127' : '#EBF1FD', minWidth: 350 }}
            onClose={nullifySearchQuery}
            groupBy={(option) => option.type}
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoFocus
                    value={searchText}
                    inputValue={searchText}
                    sx={{ borderRadius: 1 }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            props.setSearchQuery(params.inputProps.value)
                            setSearchText('')
                            e.stopPropagation()
                            history.push('/search')
                            setOpen(false)
                        }
                    }}
                    onChange={(e) => {
                        if (e.target.value.length > 0) {
                            setOpen(true)
                        }
                        props.setSearchQuery(e.target.value)
                        setSearchText(e.target.value)
                    }}
                    placeholder={'Search'}
                />
            )}
            renderGroup={(params) => (
                <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                </li>
            )}
            clearOnEscape={true}
            filterOptions={(options, state) => {
                let filtered = options.filter(
                    (item) =>
                        String(item.text).toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        dataLabels.includes(item.text)
                )
                props.setSearchResults(filtered)
                return filtered
            }}
            loadingText={'Loading...'}
            noOptionsText={'No results to display'}
            getOptionLabel={(option) => option.text}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.text, inputValue, { insideWords: true })
                let parts = parse(option.text, matches)
                let idLink = { 'Value Messages': 'value-narratives', Activities: 'activities', Evidence: 'evidence' }

                let fullText = ''
                for (let part of parts) {
                    fullText = fullText.concat(part.text)
                }
                if (fullText === 'Value Messages' || fullText === 'Evidence' || fullText === 'Activities') {
                    parts = [{ text: fullText, highlight: false }]
                }
                return (
                    <Link
                        style={{ textDecoration: 'none', color: 'black' }}
                        component={RouterLink}
                        to={{
                            pathname: '/' + idLink[option.type],
                            autoOpenData: {
                                isAutoOpenPanel: true,
                                autoOpenData: option.data,
                            },
                        }}
                        onClick={(e) => {
                            nullifySearchQuery()
                        }}
                        underline="none">
                        <li {...props}>
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                            textDecoration: part.highlight ? 'underline' : 'none',
                                            color: isDarkMode ? '#e5e4e4' : 'black',
                                        }}>
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        </li>
                    </Link>
                )
            }}
            getOptionDisabled={(option) => !!dataLabels.find((element) => element === option.text)}
        />
    )
}

const SearchBar = connect(null, mapDispatchToProps)(ConnectedSearchBar)

export default SearchBar
