import { makeStyles } from '@mui/styles'

export const useCommentsStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.figma.grayscale.tier7,
        fontFamily: 'acumin-pro !important',
        minWidth: '320px',
    },
    minimizedContainer: {
        backgroundColor: theme.palette.figma.grayscale.contentWrapper,
        borderRadius: theme.spacing(2),
        boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.17)',
    },
    title: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderColor: theme.palette.figma.grayscale.stroke,
        color: theme.palette.figma.grayscale.tier1,
        marginInline: `${theme.spacing(2)} !important`,
        marginTop: `${theme.spacing(1)} !important`,
        marginBottom: `0 !important`,
        paddingBottom: theme.spacing(1),
    },
    commentList: {
        gap: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(6),
        paddingInline: theme.spacing(2),
    },
    commentListMinimized: {
        gap: 0,
        paddingBottom: theme.spacing(4),
    },
}))
