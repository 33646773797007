import { createStore } from 'redux'
import { rootReducer } from '../reducers'

const persistedState = () => {
    try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null) {
            return undefined
        }
        return JSON.parse(serializedState)
    } catch (err) {
        return undefined
    }
}

const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (err) {}
}

const store = createStore(rootReducer, persistedState())

store.subscribe(() => {
    saveState(store.getState())
})

export default store
