import AddValueNarrativeModal from './AddValueNarrativeModal'
import { Fade, SvgIcon } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { ReactComponent as VMIcon } from '../../../../assets/images/VM_Icon.svg'
import React, { useState } from 'react'
import { useIsSnapshotMode } from '../../../../shared/hooks/useIsSnapshotMode'
import { useIsReadonlyMode } from '../../../../shared/hooks/useIsReadonlyMode'
import { ValueNarrativesLegend } from './ValueNarrativesLegend'
import { ValueNarrativeCategory } from '../../queries/useGetValueNarrativesQuery'
import Box from '@mui/material/Box'

type Props = {
    isAllCategorySelected: boolean
    selectedCategory: ValueNarrativeCategory
    categories: ValueNarrativeCategory[]
}

export const ValueNarrativesHeader = ({ selectedCategory, categories, isAllCategorySelected }: Props) => {
    const [isAddValueNarrativeModalOpen, setIsAddValueNarrativeModalOpen] = useState(false)

    const isSnapshotMode = useIsSnapshotMode()
    const isReadonlyMode = useIsReadonlyMode()

    return (
        <>
            <AddValueNarrativeModal
                isOpen={isAddValueNarrativeModalOpen}
                handleClose={() => setIsAddValueNarrativeModalOpen(false)}
                categories={categories}
                selectedCategory={selectedCategory}
            />
            <Fade in timeout={800} unmountOnExit mountOnEnter>
                <Grid container alignItems="flex-start" gap={4}>
                    <Grid container item xs spacing={2}>
                        <Grid
                            container
                            item
                            alignItems="center"
                            spacing={2}
                            sx={{
                                flexWrap: 'nowrap',
                                textWrap: 'nowrap',
                            }}>
                            <Grid item>
                                <SvgIcon component={VMIcon} fontSize="large" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h1">Value Narratives</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item>
                                {!isSnapshotMode && !isReadonlyMode && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setIsAddValueNarrativeModalOpen(true)}
                                        style={{ width: 140, height: 52, borderRadius: '14px' }}>
                                        Add New
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box visibility={isAllCategorySelected ? 'hidden' : 'visible'}>
                        <ValueNarrativesLegend />
                    </Box>
                </Grid>
            </Fade>
        </>
    )
}
