import React from 'react'
import Grid from '@mui/material/Grid'
import { Box, Paper, Typography } from '@mui/material/'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as AlarmBellRingIcon } from '../../assets/images/alarm-bell-ring.svg'
import { COLORS } from '../../style/colors'
import { formatFreeTrialEndDate } from './FreeTrialWidget.helpers'
import { useFreeTrialWidgetStyles } from './FreeTrialWidget.styles'
import { MultiDeterminateCircularProgress } from '../../shared/components/MultiDeterminateCircularProgress'

type Props = {
    days: number
    months: number
    percentage: number
    endDate: string
}

export const FreeTrialWidget = ({ percentage, months, days, endDate }: Props) => {
    const styles = useFreeTrialWidgetStyles()

    const formattedEndDate = formatFreeTrialEndDate(new Date(endDate))

    return (
        <Paper className={styles.container}>
            <MultiDeterminateCircularProgress
                value={percentage}
                color={COLORS.caribbeanGreen}
                backgroundColor={COLORS.granite}
            />
            <Stack className={styles.innerContainer}>
                <Typography
                    // @ts-expect-error theme is not typed properly
                    variant="title2">
                    ATLAS Free
                </Typography>

                <Grid container px={3}>
                    <Grid item xs={6} gap={1}>
                        <Stack alignItems="center" justifyContent="center">
                            <Typography className={styles.largePrimaryText}>{months}</Typography>
                            <Typography
                                // @ts-expect-error theme is not typed properly
                                variant="body4">
                                months
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} gap={0.5}>
                        <Stack alignItems="center" justifyContent="center">
                            <Typography className={styles.largePrimaryText}>{days}</Typography>
                            <Typography
                                // @ts-expect-error theme is not typed properly
                                variant="body4">
                                days
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>

                <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                    <SvgIcon component={AlarmBellRingIcon} inheritViewBox fontSize="small" color="primary" />
                    <Typography
                        // @ts-expect-error theme is not typed properly
                        variant="body6"
                        color="primary">
                        Upgrade {formattedEndDate}
                    </Typography>
                </Box>
            </Stack>
        </Paper>
    )
}
