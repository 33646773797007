import React, { useEffect, useState } from 'react'
import { useStyles } from '../../style/views'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Fade from '@mui/material/Fade'
import * as _ from 'underscore'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import { useAuth0 } from '../../contexts/auth0-context'

const columns = [
    { id: 'slug', label: 'Slug' },
    { id: 'owner', label: 'Owner' },
    { id: 'visits', label: 'Visits' },
    { id: 'arrow', label: '', align: 'center' },
]

export default function TracelinkTable(props) {
    const classes = useStyles()
    const { isAuthenticated, getTokenSilently } = useAuth0()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [dataRows, setDataRows] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(false)
    //const [linkedPillars, setLinkedPillars] = useState({})

    const { newFormData } = props

    useEffect(() => {
        // This fires when component mounts and any time the VM list is modified
        if (forceRefresh) {
            setForceRefresh(false)
        }

        if (!isAuthenticated) {
            return
        }

        getTracelinkData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceRefresh, isAuthenticated])

    useEffect(() => {
        // track 'newFormData' from props for changes
        // if it changes, it means the Create Button is sending data
        if (!_.isEmpty(newFormData)) {
            newTracelink(newFormData).then(() => {
                setForceRefresh(true)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newFormData])

    async function getTracelinkData() {
        console.log('inside getTracelinkData')
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingLinks = await callAPI(
            endpoints.getTracelinksForTable.url,
            endpoints.getTracelinksForTable.method,
            token
        )

        if (incomingLinks.success) {
            createDataRows(
                incomingLinks.data.length > 0
                    ? incomingLinks.data.sort((a, b) => (a.createdAt > b.createdAt) - (a.createdAt < b.createdAt))
                    : []
            )
        } else {
            setLoaded(true)
        }
    }

    async function newTracelink() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callAPI(endpoints.createTracelink.url, endpoints.createTracelink.method, token, newFormData)
    }

    async function createDataRows(data) {
        //console.log(data)
        let arr = []
        //let newObj = {}
        //let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        if (data && data.length) {
            for (const link of data) {
                let dataRow = { slug: link.slug, owner: link.owner, visits: link.visits.length, id: link._id }
                arr.push(dataRow)
            }
        }
        setDataRows(arr)
        setLoaded(true)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (
        <Fade in={loaded} timeout={800}>
            <div className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            fontSize: '1vw',
                                            minWidth: column.minWidth,
                                        }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {dataRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const value = row[column.id]

                                            if (column.id === 'arrow') {
                                                return (
                                                    <TableCell
                                                        hover
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{
                                                            fontSize: '.9vw',
                                                        }}>
                                                        <Link
                                                            component={RouterLink}
                                                            underline="none"
                                                            to={{
                                                                pathname: '/tracelink/' + row.id,
                                                            }}
                                                            onClick={() => alert('Coming soon')}>
                                                            <ArrowForwardIcon />
                                                        </Link>
                                                    </TableCell>
                                                )
                                            } else {
                                                return (
                                                    <TableCell
                                                        hover
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{
                                                            fontSize: '.9vw',

                                                            whiteSpace: 'break-spaces',
                                                        }}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                )
                                            }
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dataRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {dataRows.length === 0 && (
                    <div style={{ textAlign: 'center', marginTop: '40px' }}>
                        <h3>No Tracelinks to display.</h3>
                        <p>Click 'Add New' on the left panel to create a new Tracelink.</p>
                    </div>
                )}
            </div>
        </Fade>
    )
}
