import React, { useEffect, useState } from 'react'
import { useStyles } from '../../style/views'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Grid from '@mui/material/Grid'
import store from '../../redux/store'
import { useAuth0 } from '../../contexts/auth0-context'
import TableHead from '@mui/material/TableHead'
import Chip from '@mui/material/Chip'
import { connect, useSelector } from 'react-redux'
import { setSearchResults } from '../../redux/actions'
import { Link as RouterLink } from 'react-router-dom'
import Typography from '@mui/material/Typography'

function mapDispatchToProps(dispatch) {
    return {
        setSearchResults: (results) => dispatch(setSearchResults(results)),
    }
}

const eventTypeColorMapping = {
    'Value Messages': '#0288d1',
    Activities: '#2e7d32',
    Evidence: '#ed6c02',
}

function ConnectedSearchLandingPage(props) {
    const nameMapping = {
        'Value Messages': 'Value Message',
        Activities: 'Activity',
        Evidence: 'Evidence',
    }

    const pathMapping = {
        'Value Messages': '/value-narratives',
        Activities: '/activities',
        Evidence: '/evidence',
    }

    function getStateMapping(type, id) {
        if (type === 'Value Messages') {
            return {
                vmId: id,
            }
        } else if (type === 'Evidence') {
            return {
                evidenceId: id,
            }
        } else if (type === 'Activities') {
            return {
                activityId: id,
            }
        }
        return {}
    }

    const classes = useStyles()
    const { isAuthenticated, getTokenSilently } = useAuth0()
    const [forceRefresh, setForceRefresh] = useState(false)

    const searchResults = useSelector((state) => state.searchResults || [])
    const searchQuery = useSelector((state) => state.searchQuery || '')

    useEffect(() => {
        // This fires when component mounts and any time the VM list is modified
        if (forceRefresh) {
            setForceRefresh(false)
        }

        if (!isAuthenticated) {
            return
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceRefresh, isAuthenticated])

    return (
        <Grid container sx={{ p: 2 }}>
            <h1>{`Search Results${!!searchQuery ? ' for ' + `'${searchQuery}'` : ''}`}</h1>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Data</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searchResults
                            .filter((x) => x.type !== 'disabled')
                            .map((result, i) => (
                                <TableRow
                                    component={RouterLink}
                                    to={{
                                        pathname: pathMapping[result.type],
                                        autoOpenData: {
                                            isAutoOpenPanel: true,
                                            autoOpenData: result.data,
                                        },
                                    }}
                                    key={i}>
                                    <TableCell>
                                        <Chip
                                            label={<Typography variant="chip">{nameMapping[result.type]}</Typography>}
                                            sx={{ backgroundColor: eventTypeColorMapping[result.type], color: 'white' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <p>{result.text}</p>
                                    </TableCell>
                                </TableRow>
                            ))}

                        {searchResults && searchResults.length === 3 && (
                            <h2 style={{ textAlign: 'center' }}>No Results</h2>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

const SearchLandingPage = connect(null, mapDispatchToProps)(ConnectedSearchLandingPage)

export default SearchLandingPage
