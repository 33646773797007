import { useSelector } from 'react-redux'

const SANOFI_ORGANIZATION_ID = '603d718dabe119e3a2b22688';

export const useIsSanofiOrganization = () => {
    // @ts-expect-error redux store's state is not properly typed
    const userRecordOrganizationId = useSelector((state) => (state?.userRecord?.organizationId ));
    // @ts-expect-error redux store's state is not properly typed
    const strategyOrganizationId = useSelector((state) => (state?.strategy?.organizationId ));

    const isSanofiOrganization = userRecordOrganizationId === SANOFI_ORGANIZATION_ID || strategyOrganizationId === SANOFI_ORGANIZATION_ID;

    return isSanofiOrganization;
}
