import React, { useEffect, useState } from 'react'
import { setStrategy, setSettings, setUserRecord } from '../../redux/actions'
import { useAuth0 } from '../../contexts/auth0-context'
import { connect, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { StatisticsWidget } from './Widgets/StatisticsWidget'
import ActivityStatusWidget from './Widgets/ActivityStatusWidget'
import { ReactComponent as ActivityIcon } from '../../assets/images/Act_Icon.svg'
import { Checkbox, ListItemIcon, ListItemText, Menu, Popover } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import store from '../../redux/store'
import ManagerialBarChart from './ManagerialViewBarCharts'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { quarterOfYear } from '../../utilities/helpers'
import highchartsGantt from 'highcharts/modules/gantt'
import Paper from '@mui/material/Paper'
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import SvgIcon from '@mui/material/SvgIcon'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import CloseIcon from '@mui/icons-material/Close'
import { EmptyPlaceholder } from '../../shared/components/EmptyPlaceholder'
import Box from '@mui/material/Box'

Chart.register(ArcElement, Tooltip, Legend, Title)

function mapDispatchToProps(dispatch) {
    return {
        setStrategy: (strat) => dispatch(setStrategy(strat)),
        setSettings: (settings) => dispatch(setSettings(settings)),
        setUserRecord: (userRecord) => dispatch(setUserRecord(userRecord)),
    }
}

highchartsGantt(Highcharts)

function ConnectedMetricsGrid(props) {
    const { user, isAuthenticated, getTokenSilently } = useAuth0()
    const [isLoaded, setIsLoaded] = useState(false)
    const [popoverAnchor, setPopoverAnchor] = useState(null)
    const [isMainMenuOpen, setIsMainMenuOpen] = useState(Boolean(popoverAnchor))
    const [isPopoverMenuOpen, setIsPopoverMenuOpen] = useState(Boolean(popoverAnchor))
    const [redraw, setRedraw] = useState(false)
    const [value, setValue] = useState(0)
    const [checkedStrategyMap, setCheckedStrategyMap] = useState({})
    const [strategyMap, setStrategyMap] = useState({})
    const [activities, setActivities] = useState([])
    const [deliverables, setDeliverables] = useState([])
    const [deliverableRefreshes, setDeliverableRefreshes] = useState(2)
    const [valueMessages, setValueMessages] = useState([])
    const [allActivities, setAllActivities] = useState([])
    const [onTrackActivities, setOnTrackActivities] = useState([])
    const [delayedActivities, setDelayedActivities] = useState([])
    const [forceRefresh, setForceRefresh] = useState(false)
    const [strategiesForOrganization, setStrategiesForOrganization] = useState([])
    const [ganttChartData, setGanttChartData] = useState([])
    const [pieChartData, setPieChartData] = useState({})
    const [pieLoaded, setPieLoaded] = useState(false)
    const [actCategories, setActCategories] = useState([])
    const [originalRoadmapIDs, setOriginalRoadmapIDs] = useState([])
    // const organizationId = useSelector((state) => (state.userRecord?.organizationId ))
    const userRecord = useSelector((state) => state.userRecord);
    const widgetVisibility = useSelector((state) => (state?.userRecord ? state?.userRecord?.metricsVisibility : {}))
    const orgId = useSelector((state) => state.strategy.organizationId)
    const isDarkMode = useSelector((state) =>
        !state.userRecord ? true : state?.userRecord?.isDarkMode === undefined ? true : state?.userRecord?.isDarkMode,
    )
    const [open, setOpen] = useState(false)
    const [dialogTitle, setDialogTitle] = useState('')
    const [dialogActivities, setDialogActivities] = useState([])

    // Manages the state of the widget checkboxes
    const [checkedWidgetTypes, setCheckedWidgetTypes] = useState({
        'Completed Activities': true,
        'Initiated Activities': true,
        'Planned Activities': true,
        'On Hold Activities': true,
        'Cancelled Activities': true,
        'RWD Study Activities': true,
        'Dossier Activities': true,
        'Known Value Messages': true,
        'Aspirational Value Messages': true,
        'Activities Bar Graph': true,
        Deliverables: true,
        'Activity Timing': true,
        'Activities Pie Charts': true,
        // 'Paid Budget': true,
        // 'Accrued Budget': true,
        // 'Planned Budget': true,
    })
    // Hardcoded list of widgets we offer. When adding new ones, update the checkboxes above to include the key
    let widgetTypes = {
        'Planned Activities': {
            displayName: 'Planned Activities',
            icon: ActivityIcon,
            calculateValue: function() {
                return activities.filter((activity) => activity.status === 'Planned')
            },
        },
        'Initiated Activities': {
            displayName: orgId === '603d718dabe119e3a2b22688' ? 'Ongoing Activities' : 'Initiated Activities',
            icon: ActivityIcon,
            calculateValue: function() {
                return activities.filter((activity) => activity.status === 'Initiated')
            },
        },
        'On Hold Activities': {
            displayName: orgId === '603d718dabe119e3a2b22688' ? 'On-hold Activities' : 'On Hold Activities',
            icon: ActivityIcon,
            calculateValue: function() {
                return activities.filter((activity) => activity.status === 'On Hold')
            },
        },
        'Completed Activities': {
            displayName: 'Completed Activities',
            icon: ActivityIcon,
            calculateValue: function() {
                return activities.filter((activity) => activity.status === 'Completed')
            },
        },
        'Cancelled Activities': {
            displayName: 'Cancelled Activities',
            icon: ActivityIcon,
            calculateValue: function() {
                return activities.filter((activity) => activity.status === 'Cancelled')
            },
        },
        // 'RWD Study Activities': {
        //     icon: ActivityIcon,
        //     calculateValue: function () {},
        // },
        // 'Dossier Activities': {
        //     icon: ActivityIcon,
        //     calculateValue: function () {},
        // },
        'Known Value Messages': {
            displayName: 'Known Value Messages',
            icon: ActivityIcon,
            calculateValue: function() {
                return valueMessages.filter((vm) => vm.known)
            },
        },
        'Aspirational Value Messages': {
            displayName: 'Aspirational Value Messages',
            icon: ActivityIcon,
            calculateValue: function() {
                return valueMessages.filter((vm) => !vm.known)
            },
        },
        //
        // 'Paid Budget': {
        //     icon: ActivityIcon,
        //     calculateValue: function () {},
        // },
        // 'Accrued Budget': {
        //     icon: ActivityIcon,
        //     calculateValue: function () {},
        // },
        // 'Planned Budget': {
        //     icon: ActivityIcon,
        //     calculateValue: function () {},
        // },
    }

    function setUserWidgetVisibility() {
        let newObj = {}
        for (let x of Object.keys(checkedWidgetTypes)) {
            newObj[x] = widgetVisibility?.[x] ?? false
        }
        setCheckedWidgetTypes(newObj)
    }

    useEffect(() => {
        if (!isAuthenticated) {
            return
        }

        getStrategies(store.getState().userRecord.organizationId)
        getSettings(store.getState().userRecord.organizationId)
        getAllActivites()
        getAllDeliverables()
        getAllValueMessages()
        setUserWidgetVisibility()

        let newCategories = Array.from(store.getState().settings.activityCategories)
        newCategories.push('---')
        setActCategories(newCategories)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceRefresh, isAuthenticated])

    function handleClose() {
        setOpen(false)
    }

    async function prepareDataForPieCharts(categoryMap) {
        let pieData = {}
        let overallInitiated = 0,
            overallPlanned = 0,
            overallCompleted = 0,
            overallOnHold = 0,
            overallCancelled = 0,
            overallNoStatus = 0
        let backgroundPalette = [
            'rgba(46,133,255, 0.8)',
            'rgba(252,138,34, 0.8)',
            'rgba(92,52,228,0.8)',
            'rgba(181,0,37,0.8)',
            'rgba(28, 196, 141, 0.8)',
            'rgba(177,173,183,0.8)',
        ]
        let borderPalette = [
            'rgba(46,133,255, 1)',
            'rgba(252,138,34, 1)',
            'rgba(92,52,228,1)',
            'rgba(181,0,37,1)',
            'rgba(28, 196, 141, 1)',
            'rgba(177,173,183,1)',
        ]
        let sanBackgroundPalette = [
            'rgba(35,0,76, 1)',
            'rgba(122,0,230, 1)',
            'rgba(0,127,173,1)',
            'rgba(218, 58, 22, 1)',
            'rgba(38,133,0,1)',
            'rgba(177,173,183,1)',
        ]
        let sanBorderPalette = [
            'rgba(35,0,76, 0.8)',
            'rgba(122,0,230, 0.8)',
            'rgba(0,127,173,0.8)',
            'rgba(218, 58, 22, 0.8)',
            'rgba(38,133,0,0.8)',
            'rgba(177,173,183,0.8)',
        ]
        for (let category of Object.keys(categoryMap)) {
            let planned = 0,
                initiated = 0,
                completed = 0,
                onHold = 0,
                cancelled = 0,
                noStatus = 0
            for (let act of categoryMap[category]) {
                if (act.status === 'Completed') {
                    completed += 1
                    overallCompleted += 1
                } else if (act.status === 'Planned') {
                    planned += 1
                    overallPlanned += 1
                } else if (act.status === 'On Hold') {
                    onHold += 1
                    overallOnHold += 1
                } else if (act.status === 'Initiated') {
                    initiated += 1
                    overallInitiated += 1
                } else if (act.status === 'Cancelled') {
                    cancelled += 1
                    overallCancelled += 1
                } else {
                    noStatus += 1
                    overallNoStatus += 1
                }
            }

            pieData[category] = {
                labels: ['Planned', 'Initiated', 'On Hold', 'Cancelled', 'Completed', 'No Status'],
                datasets: [
                    {
                        label: category + ' Status',
                        data: [planned, initiated, onHold, cancelled, completed, noStatus],
                        backgroundColor:
                            orgId === '603d718dabe119e3a2b22688' ? sanBackgroundPalette : backgroundPalette,
                        borderColor: orgId === '603d718dabe119e3a2b22688' ? sanBorderPalette : borderPalette,
                        borderWidth: 1,
                    },
                ],
                text: planned + initiated + onHold + completed + cancelled + noStatus,
            }
        }
        pieData['overall'] = {
            labels: ['Planned', 'Initiated', 'On Hold', 'Cancelled', 'Completed', 'No Status'],
            datasets: [
                {
                    label: 'Overall Activity Status',
                    data: [
                        overallPlanned,
                        overallInitiated,
                        overallOnHold,
                        overallCancelled,
                        overallCompleted,
                        overallNoStatus,
                    ],
                    backgroundColor: orgId === '603d718dabe119e3a2b22688' ? sanBackgroundPalette : backgroundPalette,
                    borderColor: orgId === '603d718dabe119e3a2b22688' ? sanBorderPalette : borderPalette,
                    borderWidth: 1,
                },
            ],
            text:
                overallPlanned +
                overallInitiated +
                overallCompleted +
                overallOnHold +
                overallCancelled +
                overallNoStatus,
        }
        setPieChartData(pieData)
        setPieLoaded(true)
    }

    async function getStrategies(userOrganization) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let strategies = await callAPI(
            endpoints.getStrategiesByOrganizationId.url + userOrganization + '/strategies',
            endpoints.getStrategiesByOrganizationId.method,
            token,
        )

        let originalRoadmaps = Array.from(strategies.data)
        originalRoadmaps.filter((x) => x.sourceId)
        originalRoadmaps = originalRoadmaps.map((y) => y._id)
        setOriginalRoadmapIDs(originalRoadmaps)

        let map = {}
        let checkedMap = {}
        for (let strat of strategies.data) {
            map[strat._id] = strat
            checkedMap[strat._id] = true
        }
        setCheckedStrategyMap(checkedMap)
        setStrategyMap(map)
        setStrategiesForOrganization(strategies.data.sort((x, y) => x.order - y.order))
        setIsLoaded(true)
    }

    async function getSettings(userOrganization) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let settings = await callAPI(
            endpoints.getSettingsByOrganizationId.url + userOrganization,
            endpoints.getSettingsByOrganizationId.method,
            token,
        )
        if (settings.success) {
            props.setSettings(settings.data)
        }
        setIsLoaded(true)
    }

    async function getAllActivites() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingActivities = await callAPI(
            endpoints.getActivitiesForTable.url + store.getState().strategy._id,
            endpoints.getActivitiesForTable.method,
            token,
        )
        if (incomingActivities.success) {
            // categorizeActivities(incomingActivities.data)
            categorizeActivities2(incomingActivities.data)

            let activitiesCopy = Array.from(incomingActivities.data)
            setActivities(activitiesCopy)
            setAllActivities(incomingActivities.data)
            // categorizeActivities(activitiesCopy)
            const now = new Date()
            setOnTrackActivities(activitiesCopy.filter((activity) => new Date(activity.endDate) > now))
            setDelayedActivities(activitiesCopy.filter((activity) => new Date(activity.endDate) < now))
        }
    }

    // update user record redux and database
    const handleUpdateUser = async (user) => {
        const token = await getTokenSilently({ audience: 'https://atlas.aesara.com' });

        props.setUserRecord(user);

        await callAPI(
            endpoints.updateUserMetrics.url + userRecord._id,
            endpoints.updateUserMetrics.method,
            token,
            user,
        )
    }

    const updateCheckedWidgetTypes = async (updatedCheckedWidgetTypes) => {
        setCheckedWidgetTypes(updatedCheckedWidgetTypes)

        const updatedUserRecord = { ...userRecord, metricsVisibility: updatedCheckedWidgetTypes };
        await handleUpdateUser(updatedUserRecord);
    }

    const updateCheckedWidgetType = async (key, value) => {
        const updatedCheckedWidgetTypes = { ...checkedWidgetTypes, [key]: value }
        await updateCheckedWidgetTypes(updatedCheckedWidgetTypes);
    }

    const areAllWidgetCheckboxesChecked = !Object.values(checkedWidgetTypes).some(selected => !selected);
    const areAllWidgetCheckboxesUnchecked = !Object.values(checkedWidgetTypes).some(selected => !!selected);

    const checkAllWidgetCheckboxes = async () => {
        const updatedCheckedWidgetTypes = Object.keys(checkedWidgetTypes).reduce((acc, item) => ({ ...acc, [item]: !areAllWidgetCheckboxesChecked }), {});
        await updateCheckedWidgetTypes(updatedCheckedWidgetTypes);
    }

    async function categorizeActivities2(incomingActivities) {
        let map = {}
        let seriesArray = []

        let colors = ['#389BFF', '#07CC9E', '#003ABB', '#FFD748', '#99B0A7', '#FF6B70']
        let seriesMap = {}

        for (let act of incomingActivities) {
            if (map[act.category]) {
                map[act.category].push(act)
            } else {
                map[act.category] = [act]
            }

            if (act.startDate && act.endDate) {
                if (seriesMap[act.function]) {
                    seriesMap[act.function].push({
                        name: act.title,
                        start: new Date(act.startDate).getTime(),
                        end: new Date(act.endDate).getTime(),
                        cat: act.category,
                        func: act.function,
                        budget: act.budget,
                        objective: act.description,
                    })
                } else {
                    seriesMap[act.function] = [
                        {
                            name: act.title,
                            start: new Date(act.startDate).getTime(),
                            end: new Date(act.endDate).getTime(),
                            cat: act.category,
                            func: act.function,
                            budget: act.budget,
                            objective: act.description,
                        },
                    ]
                }
            }
        }

        prepareDataForPieCharts(map)

        for (let cat of Object.keys(seriesMap)) {
            let sorted = Array.from(seriesMap[cat])
            sorted.sort((a, b) => {
                if (a['func'] > b['func']) {
                    return 1
                } else if (a['func'] < b['func']) {
                    return -1
                }

                // Else go to the 2nd item
                if (a['end'] < b['end']) {
                    return -1
                } else if (a['end'] > b['end']) {
                    return 1
                } else {
                    // nothing to split them
                    return 0
                }
            })
            seriesMap[cat] = sorted
        }

        for (let [i, cat] of Object.keys(seriesMap).entries()) {
            seriesArray.push({ name: cat, data: seriesMap[cat], colorByPoint: false, color: colors[i] })
        }

        setGanttChartData({
            chart: {
                // styledMode: true,
                backgroundColor: '#FFFFFF',
                marginBottom: 15,
                width: null,
                height: null,
                reflow: true,
                // plotBackgroundColor: '#022850',
                // plotBackgroundImage:
                //     'https://images.unsplash.com/photo-1528459061998-56fd57ad86e3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=970&q=80',
            },
            // title: {
            //     text: 'Activities ',
            //     style: {
            //         color: '#000000',
            //     },
            // },
            credits: {
                enabled: false,
            },
            dateFormats: {
                q: function(timestamp) {
                    var date = new Date(timestamp),
                        quarter = Math.floor(date.getUTCMonth() / 3) + 1
                    return quarter
                },
            },
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                itemMarginTop: 5,
                itemMarginBottom: 5,
                itemStyle: { color: '#000000' },
                itemHoverStyle: { color: '#07CC9E' },
            },
            xAxis: [
                {
                    type: 'datetime',
                    units: [
                        // responsive ticks
                        ['month', [3, 6]],
                    ],
                    labels: {
                        // display quarter
                        formatter: function() {
                            if (this.target.isFirst) {
                                var quarter = Math.ceil((new Date(this.value).getMonth() + 1) / 3)
                                return Highcharts.dateFormat('Q' + quarter, this.value)
                            }
                            var quarter = Math.ceil((new Date(this.value).getMonth() + 1) / 3) + 1
                            quarter = quarter === 5 ? 1 : quarter
                            return Highcharts.dateFormat('Q' + quarter, this.value)
                        },
                        align: 'center',
                        style: {
                            color: '#000000',
                        },
                    },
                    showLastLabel: false,
                },
                {
                    type: 'datetime',
                    // units: [
                    //     // responsive ticks
                    //     ['year', [1]],
                    // ],
                    labels: {
                        // display quarter
                        // formatter: function () {
                        //     // var quarter = Math.ceil((new Date(this.value).getMonth() + 1) / 3) + 1
                        //     // quarter = quarter === 5 ? 1 : quarter
                        //     // return Highcharts.dateFormat('Q' + quarter, this.value)
                        //     let date = new Date(this.value)
                        //
                        //     return date.getFullYear()
                        // },
                        align: 'center',
                        style: {
                            color: '#000000',
                        },
                    },
                },
            ],
            yAxis: {
                labels: {
                    style: {
                        color: '#000000',
                    },
                },
            },
            // responsible for navigator tool under chart
            navigator: {
                enabled: true,
                liveRedraw: true,
                series: {
                    type: 'gantt',
                    pointPlacement: 0.5,
                    pointPadding: 0.25,
                },
                yAxis: {
                    min: 0,
                    max: 3,
                    reversed: true,
                    categories: [],
                },
            },
            // on hover over gantt items
            tooltip: {
                formatter: function(tooltip) {
                    let start = new Date(this.x)
                    let end = new Date(this.x2)
                    return (
                        `<b>${this.key}</b>` +
                        '</br>' +
                        `Function: ${this.point.func}` +
                        '</br>' +
                        'Budget: $' +
                        `${this.point.budget}` +
                        '</br>' +
                        `Category: ${this.point.objective}` +
                        '</br>' +
                        `Start: Q${quarterOfYear(start)}, ${start.getFullYear()}` +
                        '</br>' +
                        `End: Q${quarterOfYear(end)}, ${end.getFullYear()}`
                    )
                },
            },
            // Buttons for selecting different time scales
            // rangeSelector: {
            //     enabled: true,
            //     selected: 5,
            //     allButtonsEnabled: true,
            //     dropdown: 'never',
            // },

            // Array of Series objects, containing arrays of Activities grouped by function
            series: seriesArray,
            plotOptions: {
                gantt: {
                    // Labels displaying on Gantt items
                    dataLabels: {
                        enabled: true,
                        // format: '{point.cat}',
                        formatter: function() {
                            return this.point.cat === '---' ? '' : this.point.cat
                        },
                        style: {
                            // Activity name on chart color
                            color: '#000000',
                            // textOutline defaults to true
                            // textOutline: 'none',
                        },
                        background: {
                            opacity: 1,
                        },
                    },
                },
            },
        })
        // prepareDataForPieCharts(map)

        const now = new Date()
        setOnTrackActivities(incomingActivities.filter((activity) => new Date(activity.endDate) > now))
        setDelayedActivities(incomingActivities.filter((activity) => new Date(activity.endDate) < now))
    }

    async function getAllDeliverables() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingDeliverables = await callAPI(
            endpoints.getDeliverablesByOrganization.url + store.getState().userRecord.organizationId,
            endpoints.getDeliverablesByOrganization.method,
            token,
        )
        if (incomingDeliverables.success) {
            setDeliverables(incomingDeliverables.data)
        }
    }

    async function getAllValueMessages() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let vms = await callAPI(
            endpoints.getValueMessagesForTable.url + store.getState().strategy._id,
            endpoints.getValueMessagesForTable.method,
            token,
        )
        if (vms.success) {
            setValueMessages(vms.data)
            setRedraw(true)
        }
    }

    const handlePopoverOpen = (event) => {
        setPopoverAnchor(event.currentTarget)
        setIsPopoverMenuOpen(true)
        setIsMainMenuOpen(true)
    }

    const handleMenuClose = () => {
        setPopoverAnchor(null)
        setIsPopoverMenuOpen(false)
        setIsMainMenuOpen(false)
    }

    function viewAll(key) {
        setDialogTitle(key)
        setDialogActivities(widgetTypes[key].calculateValue())
        console.log(widgetTypes[key].calculateValue())
        setOpen(true)
    }

    const isActivityPieChartDataEmpty = !pieChartData?.['overall']?.text;
    const isActivityTimingChartDataEmpty = !ganttChartData?.series?.length > 0;

    return (
        <Grid container p={2} spacing={2}>
            <Grid container item spacing={2}>
                <Grid item>
                    <Typography variant='h2'>Roadmap Metrics</Typography>
                </Grid>
                <Grid item>
                    <Button
                        aria-controls='main-menu'
                        aria-haspopup='true'
                        variant='contained'
                        onClick={handlePopoverOpen}
                        sx={{
                            height: 40,
                            width: 120,
                            borderRadius: 3,
                        }}>
                        Edit
                    </Button>
                </Grid>
            </Grid>

            {/* CHECKBOXES */}
            {/* --------------------------------------------------------------------- */}
            <Popover
                id='popover-menu'
                open={isPopoverMenuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <Menu
                    id='main-menu'
                    anchorEl={popoverAnchor}
                    keepMounted
                    open={isMainMenuOpen}
                    onClose={handleMenuClose}>
                    <MenuItem
                        onClick={checkAllWidgetCheckboxes}>
                        <ListItemIcon>
                            <Checkbox
                                checked={areAllWidgetCheckboxesChecked}
                            />
                        </ListItemIcon>
                        <ListItemText>{areAllWidgetCheckboxesChecked ? 'Unselect all' : "Select all"}</ListItemText>
                    </MenuItem>
                    {Object.keys(widgetTypes).map((key, i) => (
                        <MenuItem
                            onClick={(e) => {
                                updateCheckedWidgetType(key, !checkedWidgetTypes[key])
                            }}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checkedWidgetTypes[key]}
                                    name={widgetTypes[key].displayName}
                                />
                            </ListItemIcon>
                            <ListItemText>{widgetTypes[key].displayName}</ListItemText>
                        </MenuItem>
                    ))}
                    <MenuItem
                        onClick={(e) => {
                            updateCheckedWidgetType('Activities Bar Graph', !checkedWidgetTypes['Activities Bar Graph'])
                        }}>
                        <ListItemIcon>
                            <Checkbox
                                checked={checkedWidgetTypes['Activities Bar Graph']}
                                name={'Activities Bar Graph'}
                            />
                        </ListItemIcon>
                        <ListItemText>Activities Bar Graph</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            updateCheckedWidgetType('Deliverables', !checkedWidgetTypes['Deliverables'])
                        }}>
                        <ListItemIcon>
                            <Checkbox
                                checked={checkedWidgetTypes['Deliverables']}
                                name={'Deliverables'}
                            />
                        </ListItemIcon>
                        <ListItemText>Deliverables</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            updateCheckedWidgetType('Activities Pie Charts', !checkedWidgetTypes['Activities Pie Charts'])
                        }}>
                        <ListItemIcon>
                            <Checkbox
                                checked={checkedWidgetTypes['Activities Pie Charts']}
                                name={'Activities Pie Charts'}
                            />
                        </ListItemIcon>
                        <ListItemText>Activities Pie Charts</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            updateCheckedWidgetType('Activity Timing', !checkedWidgetTypes['Activity Timing'])
                        }}>
                        <ListItemIcon>
                            <Checkbox
                                checked={checkedWidgetTypes['Activity Timing']}
                                name={'Activity Timing'}
                            />
                        </ListItemIcon>
                        <ListItemText>Activity Timing</ListItemText>
                    </MenuItem>
                </Menu>
            </Popover>

            {areAllWidgetCheckboxesUnchecked && (
                <Box pt={5} m="auto">
                    <EmptyPlaceholder
                        title='No Roadmap Metrics to display'
                        subtitle="Click 'Edit' on the left panel to select Roadmap Metrics"
                    />
                </Box>
            )}

            {/* WIDGETS */}
            {/* --------------------------------------------------------------------- */}
            <Grid container item spacing={2}>
                {Object.keys(widgetTypes)
                    .filter((key) => checkedWidgetTypes?.[key])
                    .map((key) => (
                        <Grid item xs={6} md={4} key={key}>
                            <Paper elevation={2} sx={{ borderRadius: 6, overflow: 'hidden' }}>
                                <StatisticsWidget
                                    icon={widgetTypes[key].icon}
                                    title={widgetTypes[key].displayName}
                                    content={widgetTypes?.[key].calculateValue().length}
                                    viewAll={() => viewAll(key)}
                                    canViewAll={widgetTypes?.[key].calculateValue().length > 0}
                                />
                            </Paper>
                        </Grid>
                    ))}
            </Grid>

            <Grid container item spacing={2} xs={12}>
                {checkedWidgetTypes?.['Activities Bar Graph'] && (
                    <Grid container item xs={12} md={6}>
                        <Paper elevation={2} sx={{ flex: 1, borderRadius: 6, overflow: 'hidden', height: '100%' }}>
                            <ActivityStatusWidget />
                        </Paper>
                    </Grid>
                )}
                {checkedWidgetTypes?.['Deliverables'] && (
                    <Grid container item xs={12} md={6}>
                        <Paper elevation={2} sx={{ borderRadius: 6, overflow: 'hidden', height: '100%' }}>
                            <ManagerialBarChart orgDeliverables={deliverables} refresh={deliverableRefreshes} />
                        </Paper>
                    </Grid>
                )}
            </Grid>

            {checkedWidgetTypes?.['Activities Pie Charts'] && pieLoaded && (
                <Grid container item xs={12}>
                    <Paper elevation={2} sx={{ borderRadius: 6, height: '100%', width: '100%', p: 2 }}>
                        <Grid item>
                            <Typography variant='h5'>Activities</Typography>
                        </Grid>
                        <Grid container item xs='auto'>
                            {isActivityPieChartDataEmpty && <EmptyPlaceholder text='No Activities to display' />}
                            {!isActivityPieChartDataEmpty && (
                                <>
                                    <Grid container item xs={3}>
                                        <Doughnut
                                            data={pieChartData['overall'] || {}}
                                            options={{
                                                plugins: {
                                                    title: {
                                                        display: true,
                                                        text: 'Overall',
                                                    },
                                                    legend: {
                                                        display: false,
                                                    },
                                                },
                                                maintainAspectRatio: false,
                                                redraw,
                                            }}
                                            plugins={[
                                                {
                                                    beforeDraw: function(chart) {
                                                        var width = chart.width,
                                                            height = chart.height,
                                                            ctx = chart.ctx
                                                        ctx.restore()
                                                        var fontSize = (height / 160).toFixed(2)
                                                        ctx.font = fontSize + 'em sans-serif'
                                                        ctx.textBaseline = 'top'
                                                        ctx.fillStyle = isDarkMode ? '#ffffff' : '#000000'

                                                        // Draw the number on the second line
                                                        var text2 = pieChartData['overall'].text
                                                        var textX2 = Math.round((width - ctx.measureText(text2).width) / 2)
                                                        var textY2 = height / 2
                                                        ctx.fillText(text2, textX2, textY2)

                                                        ctx.save()
                                                    },
                                                },
                                            ]}
                                        />
                                    </Grid>
                                    <Grid container item xs spacing={2}>
                                        {actCategories &&
                                            actCategories.map((cat, i) => {
                                                if (pieChartData[cat]) {
                                                    return (
                                                        <Grid container item key={cat + i} xs={3}>
                                                            <Doughnut
                                                                data={pieChartData[cat] || {}}
                                                                options={{
                                                                    plugins: {
                                                                        title: {
                                                                            display: true,
                                                                            text: cat,
                                                                        },
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    maintainAspectRatio: false,
                                                                    redraw,
                                                                }}
                                                                plugins={[
                                                                    {
                                                                        beforeDraw: function(chart) {
                                                                            var width = chart.width,
                                                                                height = chart.height,
                                                                                ctx = chart.ctx
                                                                            ctx.restore()
                                                                            var fontSize = (height / 160).toFixed(2)
                                                                            ctx.font = fontSize + 'em sans-serif'
                                                                            ctx.textBaseline = 'top'
                                                                            ctx.fillStyle = isDarkMode ? '#ffffff' : '#000000'

                                                                            // Draw the number on the second line
                                                                            var text2 = pieChartData[cat].text
                                                                            var textX2 = Math.round(
                                                                                (width - ctx.measureText(text2).width) / 2,
                                                                            )
                                                                            var textY2 = height / 1.9
                                                                            ctx.fillText(text2, textX2, textY2)

                                                                            ctx.save()
                                                                        },
                                                                    },
                                                                ]}
                                                            />
                                                        </Grid>
                                                    )
                                                } else {
                                                    return <></>
                                                }
                                            })}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Paper>
                </Grid>
            )}

            {checkedWidgetTypes?.['Activity Timing'] && (
                <Grid container item>
                    <Paper elevation={2} sx={{ borderRadius: 6, height: '100%', width: '100%', p: 2 }}>
                        <Grid item xs>
                            <Typography variant='h5'>Activity Timing</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {isActivityTimingChartDataEmpty && <EmptyPlaceholder text='No Activities to display' />}
                            {!isActivityTimingChartDataEmpty &&
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    constructorType={'ganttChart'}
                                    options={ganttChartData}
                                    style={{ width: '100%' }}
                                />
                            }
                        </Grid>
                    </Paper>
                </Grid>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                BackdropProps={{ style: { backgroundColor: 'rgba(0,0,0, .26)' } }}>
                <DialogTitle id='form-dialog-title'>
                    <Grid container item justifyContent={'space-between'} alignItems='center' wrap='nowrap'>
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item>
                                <SvgIcon component={ActivityIcon} color={'primary'} />
                            </Grid>
                            <Grid item>
                                <Typography variant='h4' color={'primary'} noWrap xs>
                                    {dialogTitle}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={5}>
                            <Typography variant='body1' noWrap xs>
                                {`Count: ${dialogActivities.length}`}
                            </Typography>
                        </Grid>
                        <a
                            // eslint-disable-next-line
                            href='javascript:void(0)'
                        >
                            <Grid item xs='1'>
                                <CloseIcon onClick={() => setOpen(false)}
                                           onKeyDown={() => setOpen(false)}
                                           aria-label='close'
                                           size='small'
                                           sx={{
                                               color: '#4D4D4D',
                                               border: '1px solid #4D4D4D',
                                               backgroundColor: 'white',
                                               width: '15px',
                                               height: '15px',
                                               borderRadius: '7px',
                                           }} />
                            </Grid>
                        </a>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        item
                        sx={{ minWidth: '400px' }}
                        color='secondary'
                        direction='column'
                        xs>
                        {dialogActivities.map((el, i) => {
                            return (
                                <Link
                                    component={RouterLink}
                                    underline='none'
                                    color='secondary'
                                    to={{
                                        pathname: el.hasOwnProperty('deliverables') ? '/activities' : '/value-narratives',
                                        autoOpenData: {
                                            isAutoOpenPanel: true,
                                            autoOpenData: el,
                                        },
                                    }}>
                                    <Grid container item sx={{
                                        padding: '5px', '&:hover': {
                                            backgroundColor: 'rgba(0,73,234, .1)', borderRadius: '5px', color: '#0049EA',
                                        },
                                    }} hover>
                                        <Typography variant='body1'>
                                            {`${i + 1}. ${el[el.hasOwnProperty('deliverables') ? 'title' : 'name']}`}
                                        </Typography>
                                    </Grid>
                                </Link>
                            )
                        })}
                    </Grid>
                </DialogContent>
            </Dialog>
        </Grid>
    )
}

const MetricsGrid = connect(null, mapDispatchToProps)(ConnectedMetricsGrid)

export default MetricsGrid
