import callAPI from '../../../API/callSecureAPI'
import endpoints from '../../../API/endpoints'
import { ValueNarrative } from './useGetValueNarrativesQuery'
import { useAuth0 } from '../../../contexts/auth0-context'
import { useMutation } from 'react-query'
import { useInvalidateGetValueNarrativesQuery } from './useInvalidateGetValueNarrativesQuery'

type UseUpdateValueNarrativeMutationProps = {
    onUpdateValueNarrative?: () => void
}

export const useUpdateValueNarrativeMutation = ({ onUpdateValueNarrative }: UseUpdateValueNarrativeMutationProps) => {
    const { getTokenSilently } = useAuth0()

    const updateValueNarrative = async (valueNarrative: ValueNarrative) => {
        try {
            const token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

            const { url, method } = endpoints.updateValueNarrative

            const postObject: ValueNarrative = {
                ...valueNarrative,
                valueMessages: valueNarrative.valueMessages.map((message) => message._id),
            }

            await callAPI(url + valueNarrative._id, method, token, postObject)
        } catch (e) {
            console.log(e)
        }
    }

    const { invalidateGetValueNarrativesQuery } = useInvalidateGetValueNarrativesQuery()

    return useMutation(updateValueNarrative, {
        onSuccess: async () => {
            await invalidateGetValueNarrativesQuery();
            onUpdateValueNarrative?.()
        },
    })
}
