import React from 'react'
import clsx from 'clsx'
import { useFloatingNotificationStyles } from './FloatingNotification.styles'
import { Card, SvgIcon, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/arrow-left.svg'
import { PulsingCircle } from '../PulsingCircle'

type Props = {
    title: string
    buttonLabel: string
    onButtonClick: () => void
    closed: boolean
    onClose: () => void
    withPulsingCircle?: boolean
}

export const FloatingNotification = ({
    title,
    closed,
    buttonLabel,
    onButtonClick,
    onClose,
    withPulsingCircle = true,
}: Props) => {
    const styles = useFloatingNotificationStyles()

    return (
        <Card
            className={clsx(styles.container, {
                [styles.closed]: closed,
            })}>
            <PulsingCircle visible={withPulsingCircle} />
            <Grid container gap={1.5} alignItems="center" flexDirection={closed ? 'row-reverse' : 'row'}>
                <Grid item>
                    <Stack gap={1}>
                        <Typography
                            // @ts-expect-error theme is not typed properly
                            variant="body4">
                            {title}
                        </Typography>
                        <Button className={styles.button} variant="contained" color="primary" onClick={onButtonClick}>
                            {buttonLabel}
                        </Button>
                    </Stack>
                </Grid>
                <Grid item>
                    <IconButton
                        className={clsx(styles.arrow, {
                            [styles.flipped]: closed,
                        })}
                        onClick={onClose}>
                        <SvgIcon component={ArrowLeftIcon} inheritViewBox fontSize="small" color="primary" />
                    </IconButton>
                </Grid>
            </Grid>
        </Card>
    )
}
