import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ReactCountryFlag from 'react-country-flag'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

export default function EditableMarketList(props) {
    const [items, setItems] = useState([])
    const [newItems, setNewItems] = useState([])

    const isDarkMode = useSelector((state) =>
        !state.userRecord ? true : state?.userRecord?.isDarkMode === undefined ? true : state?.userRecord?.isDarkMode
    )

    useEffect(() => {
        setItems(props.items)
        setNewItems(props.items)
    }, [props])

    const flattenedMarkets = items.flatMap((phase) =>
        phase.phaseKeyMarkets.map((market) => ({
            ...market,
            phaseTitle: phase.phaseTitle,
        }))
    )
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        borderRadius: 14,
        color: 'secondary',
    }))

    return (
        <Grid container item spacing={1} xs={6}>
            {flattenedMarkets.map((market) => {
                return (
                    <Grid item xs={12} md={3}>
                        <Item>
                            <Grid container item alignItems="center" spacing={1} wrap="noWrap">
                                <Grid item>
                                    <ReactCountryFlag
                                        countryCode={market.code?.toLowerCase()}
                                        svg
                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                        cdnSuffix="svg"
                                        style={{
                                            width: '1.7em',
                                            height: '1.7em',
                                            margin: '2px',
                                        }}
                                        title={market.code}
                                        className="countryFlag"
                                    />
                                </Grid>
                                <Grid item xs zeroMinWidth>
                                    <Typography variant="h3" noWrap color="secondary">
                                        {market.label}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item alignItems="center" justifyContent="space-between" wrap="noWrap">
                                <Grid item ml={5}>
                                    <Typography variant="body1" noWrap color="secondary">
                                        {market.targetLaunchDate}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Chip label={<Typography variant="body6">{market.phaseTitle}</Typography>}
                                    />
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                )
            })}
        </Grid>
    )
}
