import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { ValueNarrativeCategory } from '../../queries/useGetValueNarrativesQuery'
import { useAddValueNarrativeMutation } from '../../queries/useAddValueNarrativeMutation'

type Props = {
    isOpen: boolean
    handleClose: () => void
    selectedCategory: ValueNarrativeCategory
    categories: ValueNarrativeCategory[]
}

const AddValueNarrativeModal = ({ isOpen, selectedCategory, categories, ...props }: Props) => {
    const [categoryId, setCategoryId] = useState('')
    const [name, setName] = useState('')

    useEffect(() => {
        if (isOpen) {
            setCategoryId(selectedCategory._id === 'All' ? '' : selectedCategory._id)
        }
    }, [isOpen])

    const handleClose = () => {
        props.handleClose()
        setCategoryId('')
        setName('')
    }

    const { mutate, isLoading } = useAddValueNarrativeMutation({
        onAddValueNarrative: handleClose,
    })

    const handleSave = () => {
        mutate({ categoryId, name })
    }

    const canSave = !!categoryId && !!name && !isLoading

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title" maxWidth="md">
            <DialogTitle id="form-dialog-title">
                <Typography variant="h3">Adding a Value Narrative</Typography>
            </DialogTitle>
            <DialogContent dividers>
                <FormControl fullWidth>
                    <InputLabel htmlFor="category">Category</InputLabel>
                    <Select
                        disabled={categories.length === 0 || isLoading}
                        variant={'outlined'}
                        label={'Category'}
                        value={categoryId}
                        inputProps={{
                            id: 'category',
                        }}
                        onChange={(e) => setCategoryId(e.target.value)}>
                        {categories
                            .sort((x, y) => x.order - y.order)
                            .map((category) => (
                                <MenuItem key={category._id} value={category._id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                        disabled={isLoading}
                        margin="dense"
                        label="Value Narrative"
                        placeholder="Enter a new Value Narrative here"
                        type="text"
                        fullWidth
                        multiline
                        rows={2}
                        value={name}
                        onFocus={(event) => {
                            event.target.setAttribute('autocomplete', 'off')
                        }}
                        onChange={(e) => setName(e.target.value)}
                    />
                </FormControl>
                <Box mt={1} mr={8}>
                    <Typography
                        // @ts-expect-error theme is not typed properly
                        variant="subtitle5"
                        color="figma.grayscale.textHier2">
                        Please note that you can add a new Narrative without populating the narrative statement. Simply
                        choose a category & enter a generic statement (e.g. “New Narrative”)
                    </Typography>
                </Box>
                <Box mt={2} mr={8}>
                    <Typography
                        // @ts-expect-error theme is not typed properly
                        variant="subtitle5"
                        color="figma.grayscale.textHier2">
                        Once you populate the Value Messages, ATLAS AI will generate a statement for you!
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} disabled={!canSave} variant="contained" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddValueNarrativeModal
