import { makeStyles } from '@mui/styles'

export const useCommentListItemStyles = makeStyles((theme) => ({
    container: {},
    minimizedContainer: {
        borderBottom: '0.5px solid',
        borderBottomColor: theme.palette.figma.grayscale.stroke,
        '&:last-of-type': {
            borderBottom: 'unset',
        },
    },
    innerContainer: {
        backgroundColor: `${theme.palette.figma.grayscale.contentWrapper} !important`,
        width: '85%',
        padding: theme.spacing(2),
        borderRadius: `${theme.spacing(2)} !important`,
    },
    minimizedInnerContainer: {
        width: '100%',
        padding: theme.spacing(1),
        borderRadius: 'unset !important',
    },
    text: {
        color: theme.palette.figma.grayscale.textHier2,
    },
    minimizedText: {
        marginLeft: theme.spacing(4.5),
    },
    date: {
        color: `${theme.palette.figma.grayscale.textHier2} !important`,
    },
    author: {
        flexGrow: 1,
        color: theme.palette.figma.grayscale.textHier1,
    },
    minimizedAuthor: {
        flexGrow: 'unset',
    },
    avatar: {
        height: `${theme.spacing(3)} !important`,
        width: `${theme.spacing(3)} !important`,
        fontSize: '12px !important',
        fontWeight: '600 !important',
        color: `${theme.palette.figma.accentColors.white} !important`,
    },
}))
