export default function callFileAPI(url, requestType, token, body) {
    let requestOb = {
        method: requestType,
        headers: { Authorization: `Bearer ${token}`, 'Content-type': 'application/json' },
    }

    if (body) {
        requestOb.body = JSON.stringify(body)
    }

    return fetch(url, requestOb).then((res) => res.blob())
}
