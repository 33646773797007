import React, { useEffect, useState } from 'react'
import TablePanel from '../Support/TablePanel'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Fade from '@mui/material/Fade'
import * as _ from 'underscore'
import store from '../../redux/store'
import { useAuth0 } from '../../contexts/auth0-context'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import { connect, useSelector } from 'react-redux'
import { setActivtyTableNumberOfItems } from '../../redux/actions'
import {
    EnhancedTableHead,
    getComparator,
    quarterOfYear,
    stableSort,
    timeout,
} from '../../utilities/helpers'

import { ReactComponent as GapIcon } from '../../assets/images/Gap_Icon.svg'
import { IconButton, Tooltip, SvgIcon } from '@mui/material'
import { GlobalStyles } from '@mui/material'
import { EmptyPlaceholder } from '../../shared/components/EmptyPlaceholder'
import { useActivityStatus } from '../../shared/hooks/useActivityStatus'
import { arrayMove } from '@dnd-kit/sortable'
import { SortableTable } from '../../shared/components/SortableTable'

function createData(title, isGapActivity, func, status, date, value, category, id, order, panel) {
    return { title, isGapActivity, func, status, date, value, category, id, order, panel }
}

function mapDispatchToProps(dispatch) {
    return {
        setActivityTableNumberOfRows: (num) => dispatch(setActivtyTableNumberOfItems(num)),
    }
}

function ConnectedActivityTable(props) {
    const { isAuthenticated, getTokenSilently } = useAuth0()
    const [activityData, setActivityData] = useState({})
    const [page, setPage] = useState(0)
    const [dataRows, setDataRows] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(false)
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('')
    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const [activityForPanel, setActivityForPanel] = useState(
        props?.autoOpenState?.isAutoOpenPanel ? props.autoOpenState.autoOpenData : {}
    )
    const [updateTableFromPanel, setUpdateTableFromPanel] = useState(false)
    const [navigationStack, setNavigationStack] = useState([])

    const { handleGetActivityStatusDetails } = useActivityStatus();

    // Columns declared as const weren't loading Store in time for render
    // eslint-disable-next-line no-unused-vars
    const [columns] = useState([
        { id: 'index', label: '' },
        { id: 'title', label: 'Title' },
        { id: 'isGapActivity', label: '' },
        { id: 'status', label: 'Status' },
        { id: 'date', label: 'Completion Date' },
        { id: 'category', label: 'Category' },
        { id: 'func', label: 'Function' },
        {
            id: 'value',
            label:
                (store.getState().settings?.displayNames?.valueMessages &&
                    store.getState().settings?.displayNames?.valueMessages) ||
                'Loading',
            format: (value) => value.toLocaleString('en-US'),
        },
        { id: 'panel', label: '' },
    ])

    const itemsToDisplay = useSelector((state) => state.activitiesTableNumberOfItems || 10)
    const { newFormData } = props

    useEffect(() => {
        // This fires when component mounts and any time the VM list is modified
        if (forceRefresh) {
            setForceRefresh(false)
        }

        if (!isAuthenticated) {
            return
        }

        if (updateTableFromPanel) {
            setUpdateTableFromPanel(false)
        }

        getActivityData()
        checkForAutoPanel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceRefresh, isAuthenticated, updateTableFromPanel])

    useEffect(() => {
        // track 'newFormData' from props for changes
        // if it changes, it means the Create Button is sending data
        if (!_.isEmpty(newFormData)) {
            newActivity().then(() => {
                setForceRefresh(true)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newFormData])

    useEffect(() => {
        checkForAutoPanel()
    }, [props])

    async function getActivityData() {
        let incomingActivities = await callAPI(
            endpoints.getActivitiesForTable.url + store.getState().strategy._id,
            endpoints.getActivitiesForTable.method,
            await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        )

        if (incomingActivities.success) {
            createDataRows(incomingActivities.data.length > 0 ? incomingActivities.data.reverse() : [])
        }
        setLoaded(true)
        let acts = {}
        for (let act of incomingActivities.data) {
            acts[act._id] = act
        }
        setActivityData(acts)
        if (activityForPanel) {
            setActivityForPanel(acts[activityForPanel._id])
        }
    }

    async function newActivity() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callAPI(endpoints.createActivity.url, endpoints.createActivity.method, token, newFormData)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        props.setActivityTableNumberOfRows(+event.target.value)
        setPage(0)
    }

    function createDataRows(data) {
        let arr = []
        if (data) {
            data.forEach((activity) => {
                let dataRow = createData(
                    activity.title,
                    activity.isGapActivity,
                    activity.function,
                    activity.status,
                    activity.endDate ? new Date(activity.endDate) : null,
                    activity.linkedValueMessages.length,
                    activity.category,
                    activity._id,
                    activity.order
                )
                arr.push(dataRow)
            })
        }
        arr.sort((a, b) => a.order - b.order)
        setDataRows(arr)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleSortDataRows = async (sortingEvent) => {
        const { active, over } = sortingEvent;

        if (!over || active.id === over.id) {
            return;
        }

        const indexOfDestinationRow = sortingEvent.over.data.current.sortable.index;
        const indexOfSourceRow = sortingEvent.active.data.current.sortable.index;
        const newDataRows = arrayMove(
            dataRows,
            indexOfSourceRow,
            indexOfDestinationRow,
        );
        setDataRows(newDataRows);

        const token = await getTokenSilently({
            audience: 'https://atlas.aesara.com',
        });
        const ids = newDataRows.map((rowData) => rowData.id);
        await callAPI(endpoints.reorderActivities.url, endpoints.reorderActivities.method, token, ids)

        setOrderBy('index');
        setForceRefresh(true);
    };

    function handlePanelOpen(id) {
        setActivityForPanel(activityData[id])
        setIsPanelOpen(true)
    }

    function handlePanelClose() {
        setActivityForPanel({})
        setNavigationStack([])
        setIsPanelOpen(false)
    }

    async function checkForAutoPanel() {
        if (_.get(props, ['autoOpenState', 'isAutoOpenPanel'], false)) {
            setActivityForPanel(props.autoOpenState.autoOpenData)
            setNavigationStack(props.autoOpenState.previousDataStack ? props.autoOpenState.previousDataStack : [])
            await timeout(700)
            setIsPanelOpen(true)
        }
    }

    const globalStyles = (
        <GlobalStyles
            styles={{
                '.MuiTableCell-root': {
                    borderBottom: 'none !important',
                },
            }}
        />
    )


    const sortedDataRows = stableSort(dataRows, getComparator(order, orderBy)).slice(page * itemsToDisplay, page * itemsToDisplay + itemsToDisplay)
    const isActivityDataEmpty = !sortedDataRows?.length;

    return (
        <>
            {globalStyles}
            <Fade in={loaded} timeout={800}>
                <Grid container item xs>
                    {isActivityDataEmpty && (
                        <EmptyPlaceholder
                            title="No Activities to display"
                            subtitle="Click 'Add New' on the left panel to create a new Activity"
                        />
                    )}
                    {!isActivityDataEmpty && (
                        <>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table" size="small">
                                    <EnhancedTableHead
                                        columns={columns}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <SortableTable.Body data={sortedDataRows} onSort={handleSortDataRows}>
                                        {({rowData, index, rowProps}) => (
                                            <SortableTable.Row {...rowProps}>
                                                {columns.map((column) => {
                                                    const value = rowData[column.id]
                                                    if (column.id === 'isGapActivity') {
                                                        if (value) {
                                                            return (
                                                                <TableCell sx={{ border: 'none' }}>
                                                                    <Tooltip title={'Gap Activity'}>
                                                                        <SvgIcon
                                                                            component={GapIcon}
                                                                            alt="gap analyses icon"
                                                                            fontSize="large"
                                                                        />
                                                                    </Tooltip>
                                                                </TableCell>
                                                            )
                                                        } else {
                                                            return <TableCell sx={{ border: 'none' }} />
                                                        }
                                                    } else if (column.id === 'index') {
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                sx={{
                                                                    border: 'none',
                                                                    borderTopLeftRadius: index === 0 ? 14 : 0,
                                                                    borderBottomLeftRadius:
                                                                        index ===
                                                                        dataRows.slice(
                                                                            page * itemsToDisplay,
                                                                            (page + 1) * itemsToDisplay
                                                                        ).length -
                                                                        1
                                                                            ? 14
                                                                            : 0,
                                                                }}>
                                                                <Typography variant="body9" sx={{ color: '#808080' }}>
                                                                    {index + page * itemsToDisplay + 1}.
                                                                </Typography>
                                                            </TableCell>
                                                        )
                                                    } else if (column.id === 'panel') {
                                                        return (
                                                            <TableCell
                                                                onClick={() => handlePanelOpen(rowData.id)}
                                                                sx={{
                                                                    border: 'none',
                                                                    borderTopRightRadius: index === 0 ? 14 : 0,
                                                                    borderBottomRightRadius:
                                                                        index ===
                                                                        dataRows.slice(
                                                                            page * itemsToDisplay,
                                                                            (page + 1) * itemsToDisplay
                                                                        ).length -
                                                                        1
                                                                            ? 14
                                                                            : 0,
                                                                    cursor: 'pointer',
                                                                }}
                                                                key={column.id}>
                                                                <IconButton sx={(theme) => ({ padding: theme.spacing(1.5), marginInline: theme.spacing(-0.75) })}>
                                                                    <ArrowForwardIosIcon color="primary" />
                                                                </IconButton>
                                                            </TableCell>
                                                        )
                                                    } else if (column.id === 'status') {
                                                        const activityStatusDetails = handleGetActivityStatusDetails(rowData.status);

                                                        if (!rowData.status) {
                                                            return <TableCell></TableCell>
                                                        }

                                                        return (
                                                            <TableCell key={column.id} sx={{ border: 'none' }}>
                                                                <Tooltip title={activityStatusDetails.name}>
                                                                    <SvgIcon
                                                                        component={activityStatusDetails.icon}
                                                                        inheritViewBox
                                                                        fontSize='small'
                                                                    />
                                                                </Tooltip>
                                                            </TableCell>
                                                        )
                                                    } else if (column.id === 'date') {
                                                        return (
                                                            <TableCell key={'cell' + column.id + index}>
                                                                <Typography variant="body1">
                                                                    {value
                                                                        ? value.getMinutes() === 1
                                                                            ? value.toLocaleDateString()
                                                                            : `Q${quarterOfYear(
                                                                                value
                                                                            )}, ${value.getFullYear()}`
                                                                        : ''}
                                                                </Typography>
                                                            </TableCell>
                                                        )
                                                    } else {
                                                        return (
                                                            <TableCell key={'cell' + column.id + index}>
                                                                <Typography
                                                                    variant={
                                                                        column.format && typeof value === 'number'
                                                                            ? 'h4'
                                                                            : 'body1'
                                                                    }
                                                                    color={
                                                                        column.format && typeof value === 'number'
                                                                            ? 'primary'
                                                                            : ''
                                                                    }>
                                                                    {column.format && typeof value === 'number'
                                                                        ? column.format(value)
                                                                        : value}
                                                                </Typography>
                                                            </TableCell>
                                                        )
                                                    }
                                                })}
                                            </SortableTable.Row>
                                        )}
                                    </SortableTable.Body>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                component="div"
                                count={dataRows.length}
                                rowsPerPage={itemsToDisplay}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    )}
                    {isPanelOpen && (
                        <TablePanel
                            title={activityForPanel?.title}
                            data={activityForPanel}
                            type={'activity'}
                            closePanel={handlePanelClose}
                            openPanelAction={true}
                            updateParent={setUpdateTableFromPanel}
                            navigationStack={navigationStack}
                        />
                    )}
                </Grid>
            </Fade>
        </>
    )
}

const ActivityTable = connect(null, mapDispatchToProps)(ConnectedActivityTable)

export default ActivityTable
