import React, { useEffect, useState } from 'react'
import { Grid, Divider, Paper } from '@mui/material/'
import Typography from '@mui/material/Typography'
import { useStyles } from '../../style/views'
import _ from 'underscore'
import { ReactComponent as ActivityIcon } from '../../assets/images/Act_Icon.svg'
import SvgIcon from '@mui/material/SvgIcon'
import { useSelector } from 'react-redux'

var ProgressBar = require('progressbar.js')

export default function ManagerialBarChart(props) {
    const classes = useStyles()
    const { orgDeliverables, refresh } = props

    // chartData contains keys of categories, and each contains an array
    // with the following data: [# of aspirational, # of known, % of total known]
    const [chartData, setChartData] = useState({})
    const orgId = useSelector((state) => state.strategy.organizationId)
    // const { user, isAuthenticated, getTokenSilently } = useAuth0()

    // const [settings, setSettings] = useState({})

    function getColorForBarChart() {
        if (orgId === '603d718dabe119e3a2b22688') {
            return ['#7A00E6', '#268500']
        } else {
            return ['#07cc9e', '#389bff']
        }
    }

    let deliverableTypes = ['Journal Pub', 'Congress Pub', 'HCEI 502(a)', 'PIE', 'Field Tool', 'Label', 'Dossier']

    function getAspirationalKnownForType(type) {
        function totalType(acc, val) {
            if (val.type && val.type === type) {
                return (acc += 1)
            }
            return acc
        }

        function countCompleted(acc, val) {
            if (val.isComplete && val.isComplete) {
                return (acc += 1)
            }
            return acc
        }

        let filtered = orgDeliverables.filter((deliv) => deliv.type === type)
        let total = filtered.reduce(totalType, 0)
        let completed = filtered.reduce(countCompleted, 0)
        let ongoing = total - completed
        if (completed === 0) {
            return [ongoing, completed, 0]
        }
        return [ongoing, completed, total !== 0 ? completed / total : 0, total]
    }

    useEffect(() => {
        for (let i = 0; i < deliverableTypes.length; i++) {
            document.getElementById('deliverable' + i + 'Container').innerHTML = ''
        }

        if (orgDeliverables) {
            let aspirationalKnownData = {}
            for (let i = 0; i < deliverableTypes.length; i++) {
                var bar = new ProgressBar.Line('#deliverable' + i + 'Container', {
                    strokeWidth: 2,
                    easing: 'easeInOut',
                    duration: 2500,
                    color: getColorForBarChart()[0],
                    trailColor: getColorForBarChart()[1],
                    trailWidth: 2,
                    svgStyle: { width: '100%', borderRadius: '30px' },
                })

                aspirationalKnownData[deliverableTypes[i]] = getAspirationalKnownForType(deliverableTypes[i])
                bar.animate(aspirationalKnownData[deliverableTypes[i]][2])
            }
            setChartData(aspirationalKnownData)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgDeliverables])

    useEffect(() => {
        if (orgDeliverables) {
            let aspirationalKnownData = {}
            for (let i = 0; i < deliverableTypes.length; i++) {
                document.getElementById('deliverable' + i + 'Container').innerHTML = ''
                var bar = new ProgressBar.Line('#deliverable' + i + 'Container', {
                    strokeWidth: 2,
                    easing: 'easeInOut',
                    duration: 2500,
                    color: getColorForBarChart()[0],
                    trailColor: getColorForBarChart()[1],
                    trailWidth: 2,
                    svgStyle: { width: '100%', borderRadius: '30px' },
                })

                aspirationalKnownData[deliverableTypes[i]] = getAspirationalKnownForType(deliverableTypes[i])
                bar.animate(aspirationalKnownData[deliverableTypes[i]][2])
            }
            setChartData(aspirationalKnownData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {orgDeliverables && (
                <>
                    {/* <Chart className={classes.activityMainDonut}>
                            <Palette scheme={chartPalete} />
                            <PieSeries valueField="val" argumentField="region" innerRadius={0.6} />
                            <Animation />
                        </Chart> */}
                    <Grid container item p={2} alignItems="flex-start">
                        <Grid container alignItems="center" justifyContent="space-around" wrap="nowrap" mb={2}>
                            <Grid container item alignItems="center" spacing={2} wrap="nowrap">
                                <Grid item>
                                    <SvgIcon component={ActivityIcon} />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                                        Deliverables
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3} alignItems="center" xs="auto" wrap="nowrap">
                                <Grid container item alignItems="center" spacing={2} wrap="nowrap" xs="auto">
                                    <Grid item>
                                        <span
                                            className={
                                                orgId === '603d718dabe119e3a2b22688' ? 'sanOngoingDot' : 'ongoingDot'
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="overline">Ongoing</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="center" spacing={2} wrap="nowrap" xs="auto">
                                    <Grid item>
                                        <span
                                            className={
                                                orgId === '603d718dabe119e3a2b22688'
                                                    ? 'sanCompletedDot'
                                                    : 'completedDot'
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="overline">Completed</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" spacing={1}>
                            {deliverableTypes.map((dType, i) => {
                                return (
                                    <>
                                        <Grid container item wrap="nowrap" direction="column" key={'num' + dType + i}>
                                            <Grid item>
                                                <Typography variant="h4">{dType}</Typography>
                                            </Grid>
                                            {/* // TODO: Decide about hiding 0 deliverable groups */}
                                            <Grid container item alignItems="center" wrap="nowrap" spacing={2}>
                                                <Grid container item xs="auto" alignItems="center" spacing={1}>
                                                    <Grid item>
                                                        <span
                                                            className={
                                                                orgId === '603d718dabe119e3a2b22688'
                                                                    ? 'sanOngoingDot'
                                                                    : 'ongoingDot'
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="h5">
                                                            {_.get(chartData, [dType, 0], 0)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs>
                                                    <div
                                                        id={'deliverable' + i + 'Container'}
                                                        key={'bar' + dType + i}
                                                        className={classes.statusBar}
                                                    />
                                                </Grid>
                                                <Grid container item xs="auto" alignItems="center" spacing={1}>
                                                    <Grid item>
                                                        <span
                                                            className={
                                                                orgId === '603d718dabe119e3a2b22688'
                                                                    ? 'sanCompletedDot'
                                                                    : 'completedDot'
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="h5">
                                                            {_.get(chartData, [dType, 1], 0)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            })}
                        </Grid>
                    </Grid>

                    {/*<Dialog*/}
                    {/*    open={openActivitiesModal}*/}
                    {/*    TransitionComponent={Transition}*/}
                    {/*    keepMounted*/}
                    {/*    onClose={handleActivitiesModalClose}*/}
                    {/*    aria-labelledby="activities-modal"*/}
                    {/*    aria-describedby="activities-modal-description">*/}
                    {/*    <DialogTitle id="activities-modal-title">*/}
                    {/*        <Typography variant="h4">Activities</Typography>*/}
                    {/*    </DialogTitle>*/}
                    {/*    <DialogActions>*/}
                    {/*        <Button onClick={handleActivitiesModalClose} variant="outlined">*/}
                    {/*            Close*/}
                    {/*        </Button>*/}
                    {/*    </DialogActions>*/}
                    {/*</Dialog>*/}
                </>
            )}
        </>
    )
}
