import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { usePulsingCircleStyles } from './PulsingCircle.styles'
import { COLORS } from '../../../style/colors'
import { Zoom } from '@mui/material'

export type PulsingCircleProps = {
    color?: (typeof COLORS)[keyof typeof COLORS]
    size?: number
    absolute?: boolean
    duration?: number
    visible?: boolean
}

export const PulsingCircle = React.memo(
    ({ color, size = 10, duration, visible = true, absolute = true }: PulsingCircleProps) => {
        const styles = usePulsingCircleStyles({ color, size, absolute })
        const [isVisible, setIsVisible] = useState(visible)

        useEffect(() => {
            setIsVisible(visible)
        }, [visible])

        useEffect(() => {
            if (visible && duration) {
                const timer = setTimeout(() => setIsVisible(false), duration)
                return () => clearTimeout(timer)
            }
        }, [visible, duration])

        return (
            <Zoom in={isVisible} appear unmountOnExit timeout={1000}>
                <Box className={styles.container} />
            </Zoom>
        )
    }
)
