import { useState, useCallback, ComponentProps } from 'react'

type HoverHandlers = {
    onMouseEnter: ComponentProps<'div'>['onMouseEnter']
    onMouseLeave: ComponentProps<'div'>['onMouseLeave']
}

export const useIsHovered = () => {
    const [isHovered, setIsHovered] = useState<boolean>(false)

    const onMouseEnter = useCallback(() => setIsHovered(true), [])
    const onMouseLeave = useCallback(() => setIsHovered(false), [])

    const handlers: HoverHandlers = { onMouseEnter, onMouseLeave }

    return { isHovered, handlers }
}

export default useIsHovered
