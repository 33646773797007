import React, { useEffect, useState } from 'react'
import ActivityTable from './ActivityTable'
import '../../style/Views.scss'
import LeftPanel from './../Support/PageLeftPanel'
import { ReactComponent as ActivityIcon } from '../../assets/images/Act_Icon.svg'
import Grid from '@mui/material/Grid'
import { useHistory } from 'react-router-dom'
import { isStrategySelected } from '../../utilities/helpers'
import store from '../../redux/store'
import _ from 'underscore'

export default function Activities(props) {
    const history = useHistory()
    const [formData, setFormData] = useState({})

    function createNew(incomingData) {
        setFormData(incomingData)
    }

    // if no strategy selected, navigate to strategy selection page
    useEffect(() => {
        if (!isStrategySelected()) {
            history.push('/roadmaps')
        }
    })

    return (
        <Grid container item spacing={2} direction="column" sx={{ p: 2 }}>
            <LeftPanel
                imgsrc={ActivityIcon}
                text={(store.getState() && store.getState().settings?.displayNames?.activities) || 'Loading'}
                id="activity"
                createNew={createNew}
            />
            <ActivityTable newFormData={formData} autoOpenState={props.location.autoOpenData}/>
        </Grid>
    )
}
