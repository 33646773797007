import React, { useState } from 'react'
import '../../style/Views.scss'
import StrategicIcon from '../../assets/images/Strategic_Icon.svg'
import LeftPanel from './../Support/PageLeftPanel'
import TracelinkTable from './TracelinkTable'

export default function Tracelinks() {
    //const history = useHistory()
    const [formData, setFormData] = useState({})

    function createNew(incomingData) {
        setFormData(incomingData)
    }

    return (
        <div className="tableViews">
            <LeftPanel
                imgsrc={StrategicIcon} // don't have an icon, so use SI
                text={'TRACELINKS'}
                id={'tracelink'}
                createNew={createNew}
            />
            <TracelinkTable newFormData={formData} />
        </div>
    )
}
