import { useQueryClient } from 'react-query'

export const useInvalidateGetValueNarrativesQuery = () => {
    const queryClient = useQueryClient()

    const invalidateGetValueNarrativesQuery = async () => {
        await queryClient.invalidateQueries('valueNarratives')
    }

    return { invalidateGetValueNarrativesQuery }
}
