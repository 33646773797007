import React, { useEffect } from 'react'
import { useAuth0 } from '../../contexts/auth0-context'
import { connect } from 'react-redux'
import { logoutRedux, reduxLogout } from '../../redux/actions'
import { useHistory } from 'react-router-dom'

function mapDispatchToProps(dispatch) {
    return {
        logoutRedux: () => dispatch(reduxLogout()),
    }
}

function ConnectedLogout(props) {
    const { logout } = useAuth0()
    const history = useHistory()

    useEffect(() => {
        props.logoutRedux()
        history.push('/')
        // logout({ returnTo: window.location.origin })
    }, [])

    return <></>
}

const Logout = connect(null, mapDispatchToProps)(ConnectedLogout)

export default Logout
