import React, { useEffect, useState } from 'react'
import { useStyles } from '../../style/views.js'
import './gap.scss'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import { StyledTableRow } from '../Support/CustomElements.js'
import { TableRow } from '@mui/material/'
import Typography from '@mui/material/Typography'
import store from '../../redux/store'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import ReactCountryFlag from 'react-country-flag'
import Tooltip from '@mui/material/Tooltip'
import { useAuth0 } from '../../contexts/auth0-context'
import { Fade } from '@mui/material/'
import Grid from '@mui/material/Grid'
import _ from 'underscore'
import { useSelector } from 'react-redux'
import { GlobalStyles } from '@mui/material'

let colorMapping = {
    0: 'greyDot',
    10: 'blueDot',
    20: 'redDot',
    30: 'orangeDot',
    40: 'yellowDot',
    50: 'greenDot',
}

function createData(reimbursableElements) {
    return {
        reimbursableElements,
    }
}

export default function ReimbursableFile() {
    const classes = useStyles()
    const { isAuthenticated, getTokenSilently } = useAuth0()
    const snapshotMode = useSelector((state) => (state.strategy ? !!state.strategy.sourceId : false))
    const userReadonly = useSelector((state) => (state.userRecord ? !!state.userRecord.readonly : false))

    const [dataColumns, setDataColumns] = useState([])
    const [dataRows, setDataRows] = useState([])
    const [cellValues, setCellValues] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)
    const [gapAnalysis, setGapAnalysis] = useState({})

    useEffect(() => {
        if (!isAuthenticated) {
            return
        }
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    async function getData() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        let incomingReimbursableFiles = await callAPI(
            endpoints.getGapAnalysis.url + store.getState().strategy._id,
            endpoints.getGapAnalysis.method,
            token
        )

        let incomingGap = await callAPI(
            endpoints.getSettingsByOrganizationId.url + store.getState().strategy.organizationId,
            endpoints.getSettingsByOrganizationId.method,
            token
        )

        if (incomingGap.success) {
            createDataRows(incomingGap.data.gapReimbursableFiles)
        }

        let strategy = await callAPI(
            endpoints.getStrategy.url + store.getState().strategy._id,
            endpoints.getStrategy.method,
            token
        )
        if (!strategy.success) {
            return
        }

        let keyMarkets = []
        for (let phase of strategy.data.keyMarkets) {
            keyMarkets = keyMarkets.concat(phase.phaseKeyMarkets)
        }

        let newColumns = []
        newColumns.push({ id: 'reimbursableElements', label: 'Reimbursement Elements', align: 'left' })
        for (let country of keyMarkets) {
            newColumns.push({
                id: country.label,
                label: country.code,
                align: 'center',
            })
        }

        setDataColumns(newColumns)
        if (incomingReimbursableFiles.success) {
            setGapAnalysis(incomingReimbursableFiles.data)
            if (incomingReimbursableFiles.data.reimbursableFile) {
                setCellValues(JSON.parse(incomingReimbursableFiles.data.reimbursableFile))
            }
        }

        setIsLoaded(true)
    }

    function createDataRows(data) {
        let arr = []
        if (data) {
            data.forEach((element) => {
                let dataRow = createData(element)
                arr.push(dataRow)
            })
        }
        setDataRows(arr)
    }

    async function updateColor(key) {
        if (!userReadonly && !snapshotMode) {
            let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
            let newValue = cellValues[key] === 50 ? 0 : cellValues[key] + 10
            let newReimbursableFile = await callAPI(
                endpoints.updateReimbursableFileValue.url + gapAnalysis._id,
                endpoints.updateReimbursableFileValue.method,
                token,
                { key: key, value: newValue }
            )
            if (newReimbursableFile.success) {
                setCellValues(JSON.parse(newReimbursableFile.data))
            }
        }
    }

    return (
        <Fade in={isLoaded && dataColumns.length > 0} timeout={800} className={'gapAssessment'}>
            <Grid container spacing={1}>
                {isLoaded && (
                    <TableContainer>
                        {!gapAnalysis.reimbursableFile || _.isEmpty(JSON.parse(gapAnalysis.reimbursableFile)) ? (
                            <Grid container item>
                                <Grid item>
                                    <Typography variant="caption">No Reimbursable File to display.</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        Add Key Markets on the Dashboard to continue.
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <StyledTableRow>
                                        {dataColumns.map((column, i) => {
                                            if (column.id === 'reimbursableElements') {
                                                return (
                                                    <TableCell key={'reimbursable' + column.id} align={column.align} sx={{ border: 'none' }}>
                                                        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{column.label}</Typography>
                                                    </TableCell>
                                                )
                                            } else {
                                                return (
                                                    <Tooltip
                                                        title={column.id}
                                                        placement="bottom"
                                                        key={'reimbursable' + column.id}>
                                                        <TableCell
                                                            key={'reimburable' + column.id + i}
                                                            sx={{ border: 'none' }}
                                                            align={column.align}>
                                                            <ReactCountryFlag
                                                                countryCode={column.label?.toLowerCase()}
                                                                svg
                                                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                                cdnSuffix="svg"
                                                                style={{
                                                                    width: '1.7em',
                                                                    height: '1.7em',
                                                                    margin: '2px',
                                                                }}
                                                                className="countryFlag"
                                                            />
                                                        </TableCell>
                                                    </Tooltip>
                                                )
                                            }
                                        })}
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {dataRows.map((row, i) => {
                                        return (
                                            <StyledTableRow key={'rElements' + i} sx={{ backgroundColor: '#FAFAFA', border: 'none' }}>
                                                {dataColumns.map((column, i) => {
                                                    const value = row[column.id]

                                                    if (
                                                        column.id === 'reimbursableElements' &&
                                                        value &&
                                                        value.length > 0
                                                    ) {
                                                        return (
                                                            <TableCell className="topCell" key={'element' + column.id} sx={{ border: 'none' }}>
                                                                <TableCell sx={{ border: 'none' }}>
                                                                    <Typography variant="body1">
                                                                        {row.reimbursableElements}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableCell>
                                                        )
                                                    } else {
                                                        return (
                                                            <TableCell className="topCell" key={'paramRow' + i} sx={{ border: 'none' }}>
                                                                <TableRow align="center" className={'paramRow'}>
                                                                    {/*eslint-disable-next-line*/}
                                                                    <a
                                                                        // eslint-disable-next-line
                                                                        href="javascript:void(0)"
                                                                        onClick={() =>
                                                                            updateColor(
                                                                                row.reimbursableElements +
                                                                                    '|' +
                                                                                    column.label
                                                                            )
                                                                        }>
                                                                        <TableCell
                                                                            align={column.align}
                                                                            className={'coloredCircleParent'}
                                                                            sx={{ borderBottom: 'none' }}>
                                                                            <span
                                                                                className={
                                                                                    colorMapping[
                                                                                        cellValues[
                                                                                            row.reimbursableElements +
                                                                                                '|' +
                                                                                                column.label
                                                                                        ]
                                                                                    ]
                                                                                }
                                                                                style={{
                                                                                    textAlign: 'center',
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                    </a>
                                                                </TableRow>
                                                            </TableCell>
                                                        )
                                                    }
                                                })}
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                )}
            </Grid>
        </Fade>
    )
}
