import { useTheme } from '@mui/styles'
import { FigmaAccentColor } from '../../../style/colors'

export const useValueNarrativeCategoryColor = () => {
    const theme = useTheme()

    const figmaAccentColors: FigmaAccentColor[] = [
        theme.palette.figma.accentColors.orange,
        theme.palette.figma.accentColors.neon,
        theme.palette.figma.accentColors.lavender,
        theme.palette.figma.accentColors.magenta,
        theme.palette.figma.accentColors.salmon,
        theme.palette.figma.accentColors.purple,
        theme.palette.figma.accentColors.pink,
    ]

    const getCategoryColorByIndex = (categoryIndex: number): FigmaAccentColor => {
        return figmaAccentColors[categoryIndex % figmaAccentColors.length]
    }

    return { getCategoryColorByIndex }
}
