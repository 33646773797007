import { COLORS } from '../../../style/colors'
import { makeStyles } from '@mui/styles'
import { alpha } from '@mui/material'

export const useInfoBannerStyles = makeStyles((theme) => ({
    container: (props: { isDarkMode: boolean }) => ({
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        background: props.isDarkMode ? COLORS.darkPrimary : COLORS.primary,
        paddingBlock: theme.spacing(0.5),
        paddingInline: theme.spacing(3),
    }),
    title: (props: { isDarkMode: boolean }) => ({
        fontFamily: 'acumin-pro !important',
        fontSize: '14px !important',
        fontWeight: '600 !important',
        textTransform: 'uppercase',
        color: !props.isDarkMode ? theme.palette.background.default : theme.palette.text.primary,
    }),
}))
