import React from 'react'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import SvgIcon from '@mui/material/SvgIcon'
import Button from '@mui/material/Button'
import { ConditionalTooltipWrapper } from '../../../shared/components/ConditionalTooltipWrapper'


export const StatisticsWidget = ({ title, icon, content, canViewAll, viewAll }) => {
    const handleViewAll = () => {
        if (!canViewAll) {
            return;
        }
        viewAll();
    }

    const canNotViewAllTooltipTitle = `No ${title}`;

    return (
        <Grid component={Paper} container item sx={{ px: 2, pt: 1, pb: .5 }} color='secondary' >
            <Grid container item alignItems='center' spacing={2} wrap='nowrap'>
                <Grid item>
                    <SvgIcon component={icon} />
                </Grid>
                <Grid item>
                    <Typography variant='h5' noWrap>
                        {title}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item justifyContent={'space-between'} alignItems={'center'} direction={'row'} wrap='nowrap'>
                <Grid item container xs={9}>
                    <Typography variant="h1" color="primary">
                         <span style={{ fontSize: 46 }}>
                            {content}
                         </span>
                    </Typography>
                </Grid>
                <Grid item xs={3} minWidth={100}>
                    <ConditionalTooltipWrapper condition={!canViewAll} title={canNotViewAllTooltipTitle} arrow sx={{ cursor: 'not-allowed' }}>
                        <Button disabled={!canViewAll} sx={{ width: '100%' }} size="small" onClick={handleViewAll}>
                            View All
                        </Button>
                    </ConditionalTooltipWrapper>
                </Grid>
            </Grid>

        </Grid>
    )
}
