import { useSelector } from 'react-redux'

export const useIsMarketMode = () => {
    // @ts-expect-error redux store is not typed properly
    const selectedMarket = useSelector((state) => state.selectedMarket)

    return {
        isMarketMode: selectedMarket !== 'Global',
        selectedMarket,
    }
}
