import callAPI from '../../../API/callSecureAPI'
import endpoints from '../../../API/endpoints'
import { useAuth0 } from '../../../contexts/auth0-context'
import { useMutation } from 'react-query'
import { useInvalidateGetValueNarrativesQuery } from './useInvalidateGetValueNarrativesQuery'

export const useAddValueMessageMutation = () => {
    const { getTokenSilently } = useAuth0()

    const addValueMessage = async (valueMessage: object) => {
        try {
            const token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
            const { url, method } = endpoints.createValueMessage

            await callAPI(url, method, token, valueMessage)
        } catch (e) {
            console.log(e)
        }
    }

    const { invalidateGetValueNarrativesQuery } = useInvalidateGetValueNarrativesQuery()

    return useMutation(addValueMessage, {
        onSuccess: async () => {
            await invalidateGetValueNarrativesQuery();
        },
    })
}
