import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import Button from '@mui/material/Button'
import { useStyles } from '../../style/views'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'
import * as _ from 'underscore'
import store from '../../redux/store'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import QuarterDatePicker from '../../utilities/quarterDatePicker'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'
import Typography from '@mui/material/Typography'
import { GreenRadio, RedRadio, YellowRadio, WhiteOutlineCheckbox } from '../Support/CustomElements'
import { useAuth0 } from '../../contexts/auth0-context'
import endpoints from '../../API/endpoints'
import callAPI from '../../API/callSecureAPI'
import InputAdornment from '@mui/material/InputAdornment'
import { uploadFile } from '../../API/PostRequests'
import ReactCountryFlag from 'react-country-flag'
import { setVMFilter } from '../../redux/actions'
import { ToggleButton, ToggleButtonGroup } from '@mui/lab'
import EventIcon from '@mui/icons-material/Event'
import GridViewIcon from '@mui/icons-material/GridView'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import axios from 'axios'
import Dropzone from './Dropzone'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Box from '@mui/material/Box'
import { getISOStringFromCalendarDate, timeout } from '../../utilities/helpers'
import { Icon, Radio, Skeleton, stepLabelClasses } from '@mui/material'
import { styled } from '@mui/system'
import { AtlasAIButton } from '../../shared/components/AtlasAIButton'
import FileIcon from '../../assets/images/file-icon.svg'
import Chip from '@mui/material/Chip'
import { useActivityStatus } from '../../shared/hooks/useActivityStatus'

export const Logo = () => (
    <Icon>
        <img src={FileIcon} height={18} width={18} style={{ marginLeft: '10px', marginBottom: '3px' }} />
    </Icon>
)

const steps = ['Extracting Text', 'Processing Data', 'Waiting For Server']

const ColorlibStepLabel = styled(StepLabel)(() => ({
    [`& .${stepLabelClasses.label}`]: {
        [`&.${stepLabelClasses.completed}`]: {
            color: 'rgba(0,44,228,0.35)',
        },
        [`&.${stepLabelClasses.active}`]: {
            color: 'rgba(0,44,228,0.83)',
        },
        color: 'rgba(0, 0, 0, 1)',
    },
}))

const SKELETON_COLOR = 'grey.220'

let categories = [
    '---',
    'Journal Pub',
    'Congress Pub',
    'HCEI502(a)',
    'PIE',
    'Field Tool',
    'Label',
    'Dossier',
    'Abstract',
    'Poster',
    'Manuscript',
]

let newLabelMapping = {
    Journal: 'Abstract',
    Congress: 'Poster',
    Manuscript: 'Manuscript',
}

const CreateButton = forwardRef((props, ref) => {
    const classes = useStyles()
    const [settings, setSettings] = useState({})
    const { isAuthenticated, getTokenSilently } = useAuth0()

    const { createNew } = props

    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState('Supported')
    const [name, setName] = useState('')
    const [priority, setPriority] = useState('Medium')
    const [vmDescription, setVMDescription] = useState('')
    const [newAudiences, setNewAudiences] = useState({})
    const [audiences, setAudiences] = useState({})
    const [newLocalMarkets, setNewLocalMarkets] = useState([])
    const [openNewLocalVMDialog, setOpenNewLocalVMDialog] = useState(false)

    const [quarterOrMonth, setQuarterOrMonth] = useState('month')
    const [studyNumber, setStudyNumber] = useState('')
    const [activityTitle, setActivityTitle] = useState('')
    const [activityDescription, setActivityDescription] = useState('')
    const [budget, setBudget] = useState(0)
    const [dataSource, setDataSource] = useState('')
    const [vendorDetails, setVendorDetails] = useState('')

    // for dropdowns don't need old and new version of stateful obj
    const [activityCategory, setActivityCategory] = useState('---')
    const [activityCategories, setActivityCategories] = useState([])
    const [isFunction, setIsFunction] = useState('---')
    const [isFunctions, setIsFunctions] = useState([])

    // for checkboxes need obj with old and new state
    const [newIntendedUse, setNewIntendedUse] = useState({})
    const [intendedUse, setIntendedUse] = useState({})
    const [publication, setPublication] = useState({})
    const [newPublication, setNewPublication] = useState({})
    const [isPublication, setIsPublication] = useState(false)
    const [isComplete, setIsComplete] = useState(false)
    const [isInitiated, setIsInitiated] = useState(false)
    const [isPlanned, setIsPlanned] = useState(false)
    const [isOnHold, setIsOnHold] = useState(false)
    const [isCancelled, setIsCancelled] = useState(false)
    const [newStatusValue, setNewStatusValue] = useState('')
    const [funded, setFunded] = useState(false)
    const [isVendor, setIsVendor] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [startDateCalendar, setStartDateCalendar] = useState(null)
    const [endDateCalendar, setEndDateCalendar] = useState(null)
    const [startError, setStartError] = React.useState(null)
    const [endError, setEndError] = React.useState(null)

    const [isAIEnabled, setIsAIEnabled] = React.useState(true)
    const [isAIAutoImportEnabled, setIsAIAutoImportEnabled] = React.useState(true)
    const [didGenerate, setDidGenerate] = React.useState(false)
    const [stepper, setStepper] = React.useState(0)

    const startErrorMessage = React.useMemo(() => {
        switch (startError) {
            case 'invalidDate': {
                return 'Invalid date'
            }
            default: {
                return ''
            }
        }
    }, [startError])

    const endErrorMessage = React.useMemo(() => {
        switch (endError) {
            case 'minDate': {
                return 'End is before Start'
            }
            case 'invalidDate': {
                return 'Invalid date'
            }
            default: {
                return ''
            }
        }
    }, [endError])
    const [actLink, setActLink] = useState('')

    const [siName, setSIName] = useState('')

    const [evidenceName, setEvidenceName] = useState('')
    const [evidenceAbstract, setEvidenceAbstract] = useState('')
    const [evidenceYear, setEvidenceYear] = useState(2020)
    const [evidenceIsInternal, setEvidenceIsInternal] = useState(false)
    const [evidenceCategory, setEvidenceCategory] = useState('---')
    const [evidenceCategories, setEvidenceCategories] = useState([])
    const [file, setFile] = useState({})
    const [link, setLink] = useState('')
    const [author, setAuthor] = useState('')

    const [gapTitle, setGapTitle] = useState('')
    const [gapPriority, setGapPriority] = useState('medium')
    const [gapNotes, setGapNotes] = useState('')
    const [gapSummary, setGapSummary] = useState('')

    const [leverValue, setLeverValue] = useState([])
    const [newLeverValue, setNewLeverValue] = useState([])

    const [deliverableTitle, setDeliverableTitle] = useState('')
    const [deliverableType, setDeliverableType] = useState('None')
    const [deliverableDate, setDeliverableDate] = useState(null)
    const [deliverableIsComplete, setDeliverableIsComplete] = useState(false)

    const [tracelinkSlug, setTracelinkSlug] = useState('')
    const [tracelinkOwner, setTracelinkOwner] = useState('')

    const vmDisplayName = _.isEmpty(store.getState().settings)
        ? ''
        : store.getState().settings.displayNames.valueMessage
    const activityDisplayName = _.isEmpty(store.getState().settings)
        ? ''
        : store.getState().settings.displayNames.activity
    const evidenceDisplayName = _.isEmpty(store.getState().settings)
        ? ''
        : store.getState().settings.displayNames.evidence
    const activitiesDisplayName = _.isEmpty(store.getState().settings)
        ? ''
        : store.getState().settings.displayNames.activities
    const strategicDisplayName = _.isEmpty(store.getState().settings)
        ? ''
        : store.getState().settings.displayNames.strategicImperatives

    useEffect(() => {
        if (!isAuthenticated) {
            return
        }
        getSettingsData()
        authenticateAI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    async function authenticateAI() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingOrg = await callAPI(
            endpoints.getOrganizationById.url + store.getState().strategy.organizationId,
            endpoints.getOrganizationById.method,
            token
        )
        if (incomingOrg.success && incomingOrg?.data?.isAIEnabled) {
            setIsAIEnabled(true)
        }
    }

    async function getSettingsData() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        let incomingSettings = await callAPI(
            endpoints.getSettingsByOrganizationId.url + store.getState().strategy.organizationId,
            endpoints.getSettingsByOrganizationId.method,
            token
        )
        if (incomingSettings.success) {
            setSettings(incomingSettings.data)
        } else {
            return
        }

        setNewLocalMarkets(flattenMarkets().map((market) => market.code))

        updateState(incomingSettings.data)

        // Grabbing VM Audiences data and setting to obj
        let incomingAudiences = incomingSettings.data.vmAudiences
        let audiencesObj = {}
        for (let i = 0; i < incomingAudiences.length; i++) {
            audiencesObj[incomingAudiences[i]] = false
        }
        setNewAudiences(audiencesObj)
        setAudiences(audiencesObj)

        // Grabbing Activity's intended uses and setting to obj
        let incomingIntendedUses = incomingSettings.data.activityIntendedUses
        let intendedUseObj = {}
        for (let i = 0; i < incomingIntendedUses.length; i++) {
            intendedUseObj[incomingIntendedUses[i]] = false
        }
        setNewIntendedUse(intendedUseObj)
        setIntendedUse(intendedUseObj)

        setPublication({
            isPublication: false,
            Journal: false,
            Congress: false,
            Manuscript: false,
        })
        setNewPublication({
            isPublication: false,
            Journal: false,
            Congress: false,
            Manuscript: false,
        })
        // setIsLoaded(true)
    }

    function updateState(data) {
        setActivityCategories(data.activityCategories)
        setIsFunctions(data.activityFunctions)
        setEvidenceCategories(data.evidenceCategories)
    }

    const handleVMCheckboxes = (event) => {
        setNewAudiences({
            ...newAudiences,
            [event.target.name]: event.target.checked,
        })
    }

    function flattenMarkets() {
        let keyMarkets = []
        for (let phase of store.getState().strategy.keyMarkets) {
            keyMarkets = keyMarkets.concat(phase.phaseKeyMarkets)
        }
        return keyMarkets
    }

    const handleActivityCheckboxes = (event) => {
        setNewIntendedUse({
            ...newIntendedUse,
            [event.target.name]: event.target.checked,
        })
    }

    const handlePublicationCheckboxes = (event) => {
        setNewPublication({
            ...newPublication,
            [event.target.name]: event.target.checked,
        })
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    useImperativeHandle(ref, () => ({
        handleClickOpen: handleClickOpen,
    }))

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (event) => {
        setGapPriority(event.target.value)
    }

    async function autoGenerateAISummary(newFile) {
        if (!_.isEmpty(newFile)) {
            setDidGenerate(true)
            setFile(newFile)
            setIsAILoading(true)
            const formData = new FormData()
            formData.append('pdfFile', newFile.link)
            await axios
                .post('/api/summary/ai/' + store.getState().strategy.organizationId, formData)
                .then((response) => {
                    setAuthor(response.data.message.authors.join(', '))
                    setEvidenceName(response.data.message.title)
                    setEvidenceAbstract(response.data.message.summary)
                    setEvidenceYear(response.data.message.year.toString())
                    setEvidenceCategory(response.data.message.category)
                    setIsAILoading(false)
                    setIsAIGeneratedSummary(true)
                })

            function automateStepper() {
                timeout(4000).then(() => {
                    setStepper(1)
                    timeout(2000).then(() => setStepper(2))
                })
            }

            setStepper(0)
            automateStepper()
        }
    }

    const [isAIGeneratedSummary, setIsAIGeneratedSummary] = useState(false)
    const [isAILoading, setIsAILoading] = useState(false)

    const handleCancel = () => {
        setOpen(false)

        timeout(500).then((r) => {
            setIsAILoading(false)
            setDidGenerate(false)
            setStatus('Supported')
            setName('')
            setVMDescription('')
            setNewAudiences(audiences)
            setPriority('')
            setNewLocalMarkets(flattenMarkets().map((market) => market.code))
            setOpenNewLocalVMDialog(false)

            setActivityTitle('')
            setActivityDescription('')
            setBudget(0)
            setActivityCategory('---')
            setIsFunction('---')
            setFunded(false)
            setNewIntendedUse(intendedUse)
            setStartDate('')
            setEndDate('')
            setNewPublication(publication)
            setIsPublication(false)
            setStudyNumber('')
            setDataSource('')
            setIsVendor(false)
            setVendorDetails('')
            setIsComplete(false)
            setIsInitiated(false)
            setIsPlanned(false)
            setIsOnHold(false)
            setIsCancelled(false)
            setActLink('')
            setStartDateCalendar(null)
            setEndDateCalendar(null)
            setQuarterOrMonth('month')
            setNewStatusValue('')

            setEvidenceName('')
            setEvidenceAbstract('')
            setEvidenceYear(2020)
            setEvidenceIsInternal(false)
            setEvidenceCategory('---')
            setFile({})
            setLink('')
            setAuthor('')
            setIsAILoading(false)
            setDidGenerate(false)
            setIsAIGeneratedSummary(false)
            setStepper(0)
        })

        setGapNotes('')
        setGapPriority('medium')
        setGapSummary('')
        setGapTitle('')
        setNewLeverValue(leverValue)

        setDeliverableDate(null)
        setDeliverableTitle('')
        setDeliverableIsComplete(false)
        setDeliverableType('')
    }

    const handleLocalMarkets = (event) => {
        if (event.target.checked) {
            if (!newLocalMarkets.includes(event.target.name)) {
                let temp = Array.from(newLocalMarkets)
                temp.push(event.target.name)
                setNewLocalMarkets(temp)
            }
        } else {
            if (newLocalMarkets.includes(event.target.name)) {
                let temp = Array.from(newLocalMarkets)
                let filtered = temp.filter((e) => e !== event.target.name)
                setNewLocalMarkets(filtered)
            }
        }
    }

    function getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value)
    }

    const handleSave = async () => {
        switch (props.id) {
            case 'vm':
                if (store.getState().selectedMarket && store.getState().selectedMarket !== 'Global') {
                    setOpen(false)
                    setOpenNewLocalVMDialog(true)
                    break
                }
                createNew({
                    name: name,
                    priority: priority,
                    order: 0,
                    status,
                    strategyId: store.getState().strategy._id,
                    organizationId: store.getState().strategy.organizationId,
                    linkedEvidence: [],
                    linkedActivities: [],
                    description: vmDescription,
                    audiences: newAudiences,
                    primaryEvidence: [],
                    localMarkets:
                        store.getState().selectedMarket && store.getState().selectedMarket !== 'Global'
                            ? [store.getState().selectedMarket]
                            : newLocalMarkets,
                    isCountrySpecific: store.getState().selectedMarket && store.getState().selectedMarket !== 'Global',
                })
                setName('')
                setPriority('')
                setStatus('Supported')
                setNewAudiences(audiences)
                setVMDescription('')
                setNewLocalMarkets(flattenMarkets().map((market) => market.code))
                break
            case 'activity':
                createNew({
                    studyNumber: studyNumber,
                    title: activityTitle,
                    description: activityDescription,
                    dataSource: dataSource,
                    budget: budget ? budget : 0,
                    category: activityCategory,
                    intendedUse: newIntendedUse,
                    function: isFunction,
                    funded: funded,
                    startDate:
                        quarterOrMonth === 'quarter' ? startDate : getISOStringFromCalendarDate(startDateCalendar),
                    endDate: quarterOrMonth === 'quarter' ? endDate : getISOStringFromCalendarDate(endDateCalendar),
                    isComplete: isComplete,
                    isInitiated: isInitiated,
                    isPlanned: isPlanned,
                    isOnHold: isOnHold,
                    isCancelled: isCancelled,
                    strategyId: store.getState().strategy._id,
                    organizationId: store.getState().strategy.organizationId,
                    linkedEvidence: [],
                    linkedValueMessages: [],
                    primaryEvidence: [],
                    publication: {
                        isPublication: isPublication,
                        Journal: newPublication['Journal'],
                        Congress: newPublication['Congress'],
                        Manuscript: newPublication['Manuscript'],
                    },
                    vendor: isVendor,
                    vendorDetails: vendorDetails,
                    activityDependencies: [],
                    link: actLink,
                    order: 0,
                    status: newStatusValue,
                })
                setActivityTitle('')
                setActivityDescription('')
                setBudget(0)
                setActivityCategory('---')
                setIsFunction('---')
                setFunded(false)
                setNewIntendedUse(intendedUse)
                setStartDate('')
                setEndDate('')
                setStartDateCalendar(null)
                setEndDateCalendar(null)
                setEndDate('')
                setNewPublication(publication)
                setIsPublication(false)
                setStudyNumber('')
                setDataSource('')
                setIsVendor(false)
                setVendorDetails('')
                setIsComplete(false)
                setIsInitiated(false)
                setIsPlanned(false)
                setIsOnHold(false)
                setIsCancelled(false)
                setActLink('')
                setNewStatusValue('')
                break
            case 'strategicimperative':
                createNew({
                    name: siName,
                    strategyId: store.getState().strategy._id,
                    organizationId: store.getState().strategy.organizationId,
                    order: 0,
                })
                break
            case 'evidence':
                let timestamp = _.isEmpty(file) ? '' : Date.now().toString()
                createNew({
                    name: evidenceName.replace(/\n+/gm, ' '),
                    s3Name: timestamp,
                    link: link,
                    mimeType: !_.isEmpty(file) ? file.link.type : '',
                    originalFileName: !_.isEmpty(file) ? file.link.name : '',
                    abstract: evidenceAbstract,
                    category: evidenceCategory,
                    year: evidenceYear,
                    internal: evidenceIsInternal,
                    author: author,
                    order: 0,
                    sharepointFileLink: '',
                    strategyId: store.getState().strategy._id,
                    organizationId: store.getState().strategy.organizationId,
                    linkedActivities: [],
                    linkedValueMessages: [],
                    primaryValueMessages: [],
                })

                // Handles file upload if there's an attached file
                let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
                if (!_.isEmpty(file)) {
                    uploadFile(file, timestamp, 'evidence', token)
                }
                setEvidenceName('')
                setEvidenceAbstract('')
                setEvidenceYear(2020)
                setEvidenceCategory('---')
                setEvidenceIsInternal(false)
                setFile({})
                setLink('')
                setAuthor('')
                break
            case 'gap':
                createNew({
                    title: gapTitle,
                    priority: gapPriority,
                    notes: gapNotes,
                    summary: gapSummary,
                    leverValue: newLeverValue,
                })
                setGapNotes('')
                setGapPriority('')
                setGapSummary('')
                setGapTitle('')
                setLeverValue(newLeverValue)
                break
            case 'deliverables':
                createNew({
                    title: deliverableTitle,
                    type: deliverableType,
                    isComplete: deliverableIsComplete,
                    dueDate: deliverableDate,
                    organizationId: store.getState().strategy.organizationId,
                    strategyId: store.getState().strategy._id,
                })
                setDeliverableDate('')
                setDeliverableTitle('')
                setDeliverableIsComplete(false)
                setDeliverableType('')
                break
            case 'tracelink':
                createNew({
                    slug: tracelinkSlug,
                    owner: tracelinkOwner,
                })
                break
            default:
                break
        }
        setOpen(false)
    }

    const dialogueHeaderMapping = {
        vm: {
            header: vmDisplayName,
            description: 'Enter your new Message below',
            placeholder: 'New Message',
        },
        activity: {
            header: activitiesDisplayName,
            description: `Enter your new ${activityDisplayName} below`,
            placeholder: `New ${activityDisplayName}`,
        },
        strategicimperative: {
            header: strategicDisplayName,
            description: `Enter your new ${strategicDisplayName} below`,
            placeholder: `New ${strategicDisplayName}`,
        },
        evidence: {
            header: evidenceDisplayName,
            description: `Enter your new ${evidenceDisplayName} below`,
            placeholder: `New ${evidenceDisplayName}`,
        },
        gap: {
            header: 'Market Access Goals',
            description: 'Enter a new Market Access Goal',
            placeholder: 'New Gap Analysis',
        },
        deliverables: {
            header: 'Deliverables',
            description: 'Add a new Deliverable',
            placeholder: 'New Deliverable',
        },
        tracelink: {
            header: 'Tracelinks',
            description: 'Add a new Tracelink',
            placeholder: 'New Tracelink',
        },
    }

    const { handleGetActivityStatusDetails } = useActivityStatus()

    function regenerateAI() {
        console.log(file)
        console.log('regen')
        autoGenerateAISummary(file)
    }

    function handleDeleteFile() {
        setFile({})
        setEvidenceName('')
        setEvidenceAbstract('')
        setEvidenceYear(2020)
        setEvidenceIsInternal(false)
        setEvidenceCategory('---')
        setFile({})
        setLink('')
        setAuthor('')
        setIsAILoading(false)
        setDidGenerate(false)
        setIsAIGeneratedSummary(false)
        setStepper(0)
    }

    return (
        <Grid container item xs>
            {props.id !== 'evidenceGaps' && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                    style={{ width: 140, height: 52, borderRadius: '14px' }}>
                    Add New
                </Button>
            )}

            <Dialog open={open} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title" maxWidth="lg">
                {props.id !== 'evidenceGaps' && (
                    <>
                        <DialogTitle id="form-dialog-title">
                            <Typography variant="h3">{dialogueHeaderMapping[props.id].header}</Typography>
                        </DialogTitle>
                    </>
                )}
                <DialogContent dividers>
                    {(() => {
                        switch (props.id) {
                            case 'vm':
                                return (
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item xs>
                                            <TextField
                                                margin="dense"
                                                id="name"
                                                label={dialogueHeaderMapping[props.id].placeholder}
                                                type="text"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                value={name}
                                                onFocus={(event) => {
                                                    event.target.setAttribute('autocomplete', 'off')
                                                }}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                margin="dense"
                                                id="vmDescription"
                                                label="Description"
                                                type="text"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                value={vmDescription}
                                                onFocus={(event) => {
                                                    event.target.setAttribute('autocomplete', 'off')
                                                }}
                                                onChange={(e) => setVMDescription(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid container item fullWidth spacing={2} sx={{ mt: 0.5 }}>
                                            <Grid item xs>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="outlined-age-native-simple-priority">
                                                        Priority
                                                    </InputLabel>
                                                    <Select
                                                        value={priority}
                                                        label="Priority"
                                                        inputProps={{
                                                            name: 'age',
                                                            id: 'outlined-age-native-simple',
                                                        }}
                                                        onChange={(e) => setPriority(e.target.value)}>
                                                        <MenuItem value={'Low'}>Low</MenuItem>
                                                        <MenuItem value={'Medium'}>Medium</MenuItem>
                                                        <MenuItem value={'High'}>High</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container item fullWidth spacing={2} mt={0.5} mb={2}>
                                            <Grid item xs>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="status">
                                                        Status
                                                    </InputLabel>
                                                    <Select
                                                        value={status}
                                                        label="Status"
                                                        inputProps={{
                                                            name: 'status',
                                                            id: 'status',
                                                        }}
                                                        onChange={(e) => setStatus(e.target.value)}>
                                                        <MenuItem value={'Supported'}>Supported</MenuItem>
                                                        <MenuItem value={'Supported-suboptimal'}>Supported-suboptimal</MenuItem>
                                                        <MenuItem value={'Aspirational'}>Aspirational</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={2} justifyItems="space-evenly">
                                            <Grid item xs>
                                                <Typography variant="subtitle2">Audience:</Typography>
                                                <FormGroup>
                                                    {Object.keys(newAudiences).map((audience, i) => (
                                                        <FormControlLabel
                                                            key={audience + i}
                                                            control={
                                                                <Checkbox
                                                                    checked={newAudiences[audience]}
                                                                    onChange={handleVMCheckboxes}
                                                                    name={audience}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={audience}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </Grid>
                                            {settings &&
                                                settings.enableLocalizedMarkets &&
                                                store.getState().selectedMarket === 'Global' && (
                                                    <Grid item xs>
                                                        <Typography variant="subtitle2">Markets:</Typography>
                                                        <FormGroup>
                                                            {flattenMarkets().map((market, i) => (
                                                                <Grid
                                                                    container
                                                                    spacing={1}
                                                                    key={market + i}
                                                                    alignItems="center">
                                                                    <Grid item>
                                                                        <ReactCountryFlag
                                                                            countryCode={market.code?.toLowerCase()}
                                                                            svg
                                                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                                            cdnSuffix="svg"
                                                                            style={{
                                                                                width: '1.7em',
                                                                                height: '1.7em',
                                                                                margin: '2px',
                                                                            }}
                                                                            title={market.label}
                                                                            className="countryFlag"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <WhiteOutlineCheckbox
                                                                                    checked={newLocalMarkets.includes(
                                                                                        market.code
                                                                                    )}
                                                                                    onChange={handleLocalMarkets}
                                                                                    name={market.code}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={market.label}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </FormGroup>
                                                    </Grid>
                                                )}
                                        </Grid>
                                    </Grid>
                                )
                            case 'activity':
                                return (
                                    <Grid container fullWidth direction="column" spacing={1}>
                                        <Grid container item direction="row" nowrap spacing={1} alignItems="center">
                                            <Grid item xs={3}>
                                                <TextField
                                                    label="Study Number"
                                                    id="study-number"
                                                    type="text"
                                                    onFocus={(event) => {
                                                        event.target.setAttribute('autocomplete', 'off')
                                                    }}
                                                    value={studyNumber}
                                                    size="small"
                                                    onChange={(e) => setStudyNumber(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid container item>
                                                <FormControl>
                                                    <RadioGroup
                                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="controlled-radio-buttons-group"
                                                        value={newStatusValue}
                                                        row>
                                                        <FormControlLabel
                                                            value="Planned"
                                                            control={
                                                                <Radio
                                                                    onClick={(e) => {
                                                                        if (e.target.value === newStatusValue) {
                                                                            setNewStatusValue('')
                                                                        } else {
                                                                            setNewStatusValue(e.target.value)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label={handleGetActivityStatusDetails('Planned').name}
                                                        />
                                                        <FormControlLabel
                                                            value="Initiated"
                                                            control={
                                                                <Radio
                                                                    onClick={(e) => {
                                                                        if (e.target.value === newStatusValue) {
                                                                            setNewStatusValue('')
                                                                        } else {
                                                                            setNewStatusValue(e.target.value)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label={handleGetActivityStatusDetails('Initiated').name}
                                                        />
                                                        <FormControlLabel
                                                            value="On Hold"
                                                            control={
                                                                <Radio
                                                                    onClick={(e) => {
                                                                        if (e.target.value === newStatusValue) {
                                                                            setNewStatusValue('')
                                                                        } else {
                                                                            setNewStatusValue(e.target.value)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label={handleGetActivityStatusDetails('On Hold').name}
                                                        />

                                                        <FormControlLabel
                                                            value="Cancelled"
                                                            control={
                                                                <Radio
                                                                    onClick={(e) => {
                                                                        if (e.target.value === newStatusValue) {
                                                                            setNewStatusValue('')
                                                                        } else {
                                                                            setNewStatusValue(e.target.value)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label={handleGetActivityStatusDetails('Cancelled').name}
                                                        />

                                                        <FormControlLabel
                                                            value="Completed"
                                                            control={
                                                                <Radio
                                                                    onClick={(e) => {
                                                                        if (e.target.value === newStatusValue) {
                                                                            setNewStatusValue('')
                                                                        } else {
                                                                            setNewStatusValue(e.target.value)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label={handleGetActivityStatusDetails('Completed').name}
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            {/*<Grid container item xs alignItems="center">*/}
                                            {/*    <FormControlLabel*/}
                                            {/*        control={*/}
                                            {/*            <WhiteOutlineCheckbox*/}
                                            {/*                checked={isPlanned}*/}
                                            {/*                onChange={(e) => setIsPlanned(e.target.checked)}*/}
                                            {/*                name="isPlanned"*/}
                                            {/*                color="primary"*/}
                                            {/*            />*/}
                                            {/*        }*/}
                                            {/*        label="Planned"*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            {/*<Grid container item xs alignItems="center">*/}
                                            {/*    <FormControlLabel*/}
                                            {/*        control={*/}
                                            {/*            <WhiteOutlineCheckbox*/}
                                            {/*                checked={isInitiated}*/}
                                            {/*                onChange={(e) => setIsInitiated(e.target.checked)}*/}
                                            {/*                name="isInitiated"*/}
                                            {/*                color="primary"*/}
                                            {/*            />*/}
                                            {/*        }*/}
                                            {/*        label="Initiated"*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            {/*<Grid container item xs alignItems="center">*/}
                                            {/*    <FormControlLabel*/}
                                            {/*        control={*/}
                                            {/*            <WhiteOutlineCheckbox*/}
                                            {/*                checked={isOnHold}*/}
                                            {/*                onChange={(e) => setIsOnHold(e.target.checked)}*/}
                                            {/*                name="isOnHold"*/}
                                            {/*                color="primary"*/}
                                            {/*            />*/}
                                            {/*        }*/}
                                            {/*        label="On Hold"*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            {/*<Grid container item xs alignItems="center">*/}
                                            {/*    <FormControlLabel*/}
                                            {/*        control={*/}
                                            {/*            <WhiteOutlineCheckbox*/}
                                            {/*                checked={isCancelled}*/}
                                            {/*                onChange={(e) => setIsCancelled(e.target.checked)}*/}
                                            {/*                name="isCancelled"*/}
                                            {/*                color="primary"*/}
                                            {/*            />*/}
                                            {/*        }*/}
                                            {/*        label="Cancelled"*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            {/*<Grid container item xs alignItems="center">*/}
                                            {/*    <FormControlLabel*/}
                                            {/*        control={*/}
                                            {/*            <WhiteOutlineCheckbox*/}
                                            {/*                checked={isComplete}*/}
                                            {/*                onChange={(e) => setIsComplete(e.target.checked)}*/}
                                            {/*                name="isCompleted"*/}
                                            {/*                color="primary"*/}
                                            {/*            />*/}
                                            {/*        }*/}
                                            {/*        label="Complete"*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                        </Grid>
                                        <Grid container item>
                                            <Grid item>
                                                <h5>Timing</h5>
                                            </Grid>
                                            <Grid container>
                                                <ToggleButtonGroup
                                                    value={quarterOrMonth}
                                                    exclusive
                                                    onChange={() =>
                                                        setQuarterOrMonth(
                                                            quarterOrMonth === 'month' ? 'quarter' : 'month'
                                                        )
                                                    }
                                                    aria-label="Quarters or Months"
                                                    color="primary">
                                                    <ToggleButton value="month" aria-label="month">
                                                        <Tooltip title={'Specific Date'}>
                                                            <EventIcon />
                                                        </Tooltip>
                                                    </ToggleButton>
                                                    <ToggleButton value="quarter" aria-label="quarter">
                                                        <Tooltip title={'Quarterly Estimate'}>
                                                            <GridViewIcon />
                                                        </Tooltip>
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        {quarterOrMonth === 'quarter' ? (
                                            <Grid container item>
                                                <QuarterDatePicker
                                                    stacked={false}
                                                    oldStart={null}
                                                    oldEnd={null}
                                                    start={(date) => setStartDate(date)}
                                                    end={(date) => setEndDate(date)}
                                                />
                                            </Grid>
                                        ) : (
                                            <Grid container item>
                                                <Grid item sx={{ marginRight: '20px' }}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <MUIDatePicker
                                                            value={startDateCalendar}
                                                            onChange={(newValue) => setStartDateCalendar(newValue)}
                                                            label="Start Date"
                                                            onError={(newError) => setStartError(newError)}
                                                            slotProps={{
                                                                textField: {
                                                                    helperText: startErrorMessage,
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <MUIDatePicker
                                                            value={endDateCalendar}
                                                            onChange={(newValue) => setEndDateCalendar(newValue)}
                                                            minDate={startDateCalendar ? startDateCalendar : null}
                                                            label="End Date"
                                                            onError={(newError) => setEndError(newError)}
                                                            slotProps={{
                                                                textField: {
                                                                    helperText: endErrorMessage,
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <TextField
                                                margin="dense"
                                                id="activity-name"
                                                label="Title"
                                                type="text"
                                                fullWidth
                                                multiline
                                                rows={2}
                                                value={activityTitle}
                                                onFocus={(event) => {
                                                    event.target.setAttribute('autocomplete', 'off')
                                                }}
                                                onChange={(e) => setActivityTitle(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                margin="dense"
                                                id="activity-desc"
                                                label="Objective"
                                                type="text"
                                                fullWidth
                                                multiline
                                                rows={2}
                                                value={activityDescription}
                                                onFocus={(event) => {
                                                    event.target.setAttribute('autocomplete', 'off')
                                                }}
                                                onChange={(e) => setActivityDescription(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                margin="dense"
                                                label="Link"
                                                type="text"
                                                fullWidth
                                                value={actLink}
                                                onFocus={(event) => {
                                                    event.target.setAttribute('autocomplete', 'off')
                                                }}
                                                onChange={(e) => setActLink(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid container item fullWidth spacing={1} alignItems="center">
                                            <Grid item xs>
                                                <TextField
                                                    id="activity-budget"
                                                    margin="dense"
                                                    label="Budget"
                                                    fullWidth
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        ),
                                                    }}
                                                    value={budget}
                                                    onFocus={(event) => {
                                                        event.target.setAttribute('autocomplete', 'off')
                                                    }}
                                                    onChange={(e) => setBudget(Number.parseInt(e.target.value))}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <FormControl fullWidth margin="dense">
                                                    <InputLabel htmlFor="outlined-age-native-simple-activity-category">
                                                        Category
                                                    </InputLabel>
                                                    <Select
                                                        label="CATEGORY"
                                                        inputProps={{
                                                            id: `outlined-age-native-simple-${activityCategory}`,
                                                        }}
                                                        value={activityCategory}
                                                        onChange={(e) => setActivityCategory(e.target.value)}>
                                                        <MenuItem value={'---'}>---</MenuItem>
                                                        {activityCategories.sort().map((category, i) => (
                                                            <MenuItem value={category} key={category + i}>
                                                                {category}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container item fullWidth spacing={1} alignItems="center">
                                            <Grid item xs>
                                                <TextField
                                                    label="Data Source"
                                                    id="data-source"
                                                    margin="dense"
                                                    multiline
                                                    fullWidth
                                                    onFocus={(event) => {
                                                        event.target.setAttribute('autocomplete', 'off')
                                                    }}
                                                    value={dataSource}
                                                    onChange={(e) => setDataSource(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <FormControl margin="dense" fullWidth>
                                                    <InputLabel htmlFor="outlined-age-native-simple-function">
                                                        Function
                                                    </InputLabel>
                                                    <Select
                                                        label="FUNCTION"
                                                        inputProps={{
                                                            id: `outlined-age-native-simple-${isFunction}`,
                                                        }}
                                                        value={isFunction}
                                                        onChange={(e) => setIsFunction(e.target.value)}>
                                                        <MenuItem value={'---'}>---</MenuItem>
                                                        {isFunctions.map((oneFunction, i) => (
                                                            <MenuItem value={oneFunction} key={oneFunction + i}>
                                                                {oneFunction}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={1} nowrap alignContent="flex-start">
                                            <Grid container item direction="column" xs>
                                                <Grid item>
                                                    <FormControl>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={isPublication}
                                                                    onChange={(e) => setIsPublication(e.target.checked)}
                                                                    name="checkedB"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Publication"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                {isPublication && (
                                                    <Grid item>
                                                        <FormGroup row>
                                                            {Object.keys(newPublication).map((pub, i) => {
                                                                if (pub !== 'isPublication') {
                                                                    return (
                                                                        <FormControlLabel
                                                                            key={pub + i}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={newPublication[pub]}
                                                                                    onChange={
                                                                                        handlePublicationCheckboxes
                                                                                    }
                                                                                    name={pub}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={newLabelMapping[pub]}
                                                                        />
                                                                    )
                                                                } else {
                                                                    return <></>
                                                                }
                                                            })}
                                                        </FormGroup>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Grid item xs>
                                                <FormControl>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={funded}
                                                                onChange={(e) => setFunded(e.target.checked)}
                                                                name="checkedB"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Funded"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid container item direction="column" xs>
                                                <Grid container item component="label" alignItems="center">
                                                    <Grid item>Internal</Grid>
                                                    <Grid item>
                                                        <Switch
                                                            checked={isVendor}
                                                            onChange={(e) => setIsVendor(e.target.checked)}
                                                            name="Vendor Study"
                                                        />
                                                    </Grid>
                                                    <Grid item>Vendor Study</Grid>
                                                </Grid>
                                                <Grid item>
                                                    {isVendor && (
                                                        <FormGroup row>
                                                            <TextField
                                                                id="vendor-details"
                                                                label="Vendor Details"
                                                                multiline
                                                                value={vendorDetails}
                                                                onFocus={(event) => {
                                                                    event.target.setAttribute('autocomplete', 'off')
                                                                }}
                                                                onChange={(e) => setVendorDetails(e.target.value)}
                                                                fullWidth
                                                            />
                                                        </FormGroup>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container item direction="column">
                                            <Grid item>
                                                <Typography variant="subtitle2">Intended Use:</Typography>
                                            </Grid>
                                            <Grid item>
                                                <FormGroup fullWidth>
                                                    {Object.keys(newIntendedUse).map((use, i) => (
                                                        <FormControlLabel
                                                            key={use + i}
                                                            control={
                                                                <Checkbox
                                                                    checked={newIntendedUse[use]}
                                                                    onChange={handleActivityCheckboxes}
                                                                    name={use}
                                                                />
                                                            }
                                                            label={use}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            case 'strategicimperative':
                                return (
                                    <Grid container fullWidth direction="column" spacing={1}>
                                        <Grid item>
                                            <TextField
                                                margin="dense"
                                                id="strategic-imperative-name"
                                                label={strategicDisplayName}
                                                type="text"
                                                fullWidth
                                                multiline
                                                value={siName}
                                                onFocus={(event) => {
                                                    event.target.setAttribute('autocomplete', 'off')
                                                }}
                                                onChange={(e) => setSIName(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            case 'evidence':
                                return (
                                    <Grid container fullWidth direction="column" spacing={1}>
                                        {/*-----------------AI AUTO-IMPORT ENABLED/DISABLED TOGGLE ---------------------------*/}
                                        {isAIEnabled && !isAILoading && !isAIGeneratedSummary && (
                                            <Grid>
                                                {/*<FormControl>*/}
                                                {/*    <FormControlLabel*/}
                                                {/*        control={*/}
                                                {/*            <Switch*/}
                                                {/*                checked={isAIAutoImportEnabled}*/}
                                                {/*                onChange={(e) => {*/}
                                                {/*                    setIsAIAutoImportEnabled(!isAIAutoImportEnabled)*/}
                                                {/*                }}*/}
                                                {/*                name='checkedAI'*/}
                                                {/*            />*/}
                                                {/*        }*/}
                                                {/*        label={`Auto-Extract Data With ATLAS AI ${isAIAutoImportEnabled ? 'Enabled' : 'Disabled'}`}*/}
                                                {/*    />*/}
                                                {/*</FormControl>*/}
                                                <h4 color="#0049EA">{`Import PDF Data with ATLAS AI`}</h4>
                                            </Grid>
                                        )}
                                        {/*-----------------REGENERATE  ---------------------------*/}
                                        {didGenerate && !isAILoading && (
                                            <Box p={1} display="flex" alignItems="center" gap={3}>
                                                <AtlasAIButton
                                                    onClick={regenerateAI}
                                                    isLoading={isAILoading}
                                                    isGenerated
                                                />
                                                <Chip
                                                    icon={<Logo />}
                                                    label={!_.isEmpty(file) ? file.link.name : 'File'}
                                                    variant="outlined"
                                                    onDelete={handleDeleteFile}
                                                />
                                            </Box>
                                        )}
                                        <Grid>
                                            {/*/!*----------------- FIRST TIME - NO FILE ---------------------------*!/*/}
                                            {isAIEnabled && !isAILoading && !didGenerate && (
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Dropzone setFile={setFile} generate={autoGenerateAISummary} />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {/*----------------- GENERATING  ---------------------------*/}
                                            {isAILoading && (
                                                <>
                                                    <Grid container item xs={12}>
                                                        <Box sx={{ width: '100%', marginTop: '20px' }}>
                                                            <Stepper activeStep={stepper} alternativeLabel>
                                                                {steps.map((label) => (
                                                                    <Step key={label}>
                                                                        <ColorlibStepLabel>{label}</ColorlibStepLabel>
                                                                    </Step>
                                                                ))}
                                                            </Stepper>
                                                        </Box>
                                                    </Grid>
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            left: '50%',
                                                            top: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            borderRadius: '100%',
                                                            zIndex: 1,
                                                            backgroundColor: 'background.default',
                                                        }}>
                                                        <AtlasAIButton size={100} isLoading />
                                                    </Box>
                                                </>
                                            )}
                                        </Grid>
                                        {/*----------------- DATA FIELDS PRE/POST GENERATE  ---------------------------*/}
                                        {/*{!isAILoading && (*/}
                                        <>
                                            {!isAILoading ? (
                                                <Grid item>
                                                    <FormControl>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={evidenceIsInternal}
                                                                    onChange={(e) =>
                                                                        setEvidenceIsInternal(e.target.checked)
                                                                    }
                                                                    name="Internal/External"
                                                                />
                                                            }
                                                            label="External"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            ) : (
                                                <Grid item>
                                                    <Skeleton
                                                        sx={{ bgcolor: SKELETON_COLOR }}
                                                        variant={'rectangular'}
                                                        width={50}
                                                        animation={'wave'}
                                                    />
                                                </Grid>
                                            )}
                                            {!isAILoading ? (
                                                <Grid item>
                                                    <TextField
                                                        margin="dense"
                                                        id="evidence-name"
                                                        label={`${evidenceDisplayName} Title`}
                                                        type="text"
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                        value={evidenceName}
                                                        onFocus={(event) => {
                                                            event.target.setAttribute('autocomplete', 'off')
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={(e) => setEvidenceName(e.target.value)}
                                                    />
                                                </Grid>
                                            ) : (
                                                <Grid item>
                                                    <Skeleton
                                                        variant={'rectangular'}
                                                        sx={{ bgcolor: SKELETON_COLOR }}
                                                        width={'100%'}
                                                        height={118}
                                                        animation={'wave'}
                                                    />
                                                </Grid>
                                            )}
                                            {!isAILoading ? (
                                                <Grid item>
                                                    <TextField
                                                        margin="dense"
                                                        id="evidence-author"
                                                        label="Author(s)"
                                                        type="text"
                                                        fullWidth
                                                        value={author}
                                                        onFocus={(event) => {
                                                            event.target.setAttribute('autocomplete', 'off')
                                                        }}
                                                        onChange={(e) => setAuthor(e.target.value)}
                                                    />
                                                </Grid>
                                            ) : (
                                                <Grid item>
                                                    <Skeleton
                                                        variant={'rectangular'}
                                                        animation={'wave'}
                                                        sx={{ bgcolor: SKELETON_COLOR }}
                                                        width={'100%'}
                                                        height={45}
                                                    />
                                                </Grid>
                                            )}
                                            {!isAILoading ? (
                                                <Grid item>
                                                    <TextField
                                                        margin="dense"
                                                        label="Key Points"
                                                        helperText={
                                                            isAIGeneratedSummary
                                                                ? '*Generated with ATLAS AI - please verify accuracy.'
                                                                : ''
                                                        }
                                                        type="text"
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                        value={evidenceAbstract}
                                                        onFocus={(event) => {
                                                            event.target.setAttribute('autocomplete', 'off')
                                                        }}
                                                        onChange={(e) => setEvidenceAbstract(e.target.value)}
                                                    />
                                                </Grid>
                                            ) : (
                                                <Grid item>
                                                    <Skeleton
                                                        variant={'rectangular'}
                                                        animation={'wave'}
                                                        sx={{ bgcolor: SKELETON_COLOR }}
                                                        width={'100%'}
                                                        height={118}
                                                    />
                                                </Grid>
                                            )}
                                            <Grid container item spacing={1}>
                                                <Grid item xs>
                                                    {!isAILoading ? (
                                                        <TextField
                                                            id="year"
                                                            margin="dense"
                                                            label="Year"
                                                            type="number"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            value={evidenceYear}
                                                            onFocus={(event) => {
                                                                event.target.setAttribute('autocomplete', 'off')
                                                            }}
                                                            onChange={(e) =>
                                                                setEvidenceYear(Number.parseInt(e.target.value))
                                                            }
                                                        />
                                                    ) : (
                                                        <Skeleton
                                                            variant={'rectangular'}
                                                            sx={{ bgcolor: SKELETON_COLOR }}
                                                            animation={'wave'}
                                                            width={'100%'}
                                                            height={45}
                                                        />
                                                    )}
                                                </Grid>
                                                <Grid item xs>
                                                    {!isAILoading ? (
                                                        <FormControl fullWidth margin="dense">
                                                            <InputLabel htmlFor="outlined-age-native-evidence-category">
                                                                Category
                                                            </InputLabel>
                                                            <Select
                                                                label="CATEGORY"
                                                                inputProps={{
                                                                    id: `outlined-age-native-simple-${evidenceCategory}`,
                                                                }}
                                                                value={evidenceCategory}
                                                                onChange={(e) => setEvidenceCategory(e.target.value)}>
                                                                <MenuItem value={'---'}>---</MenuItem>
                                                                {evidenceCategories.map((category, i) => (
                                                                    <MenuItem value={category} key={category + i}>
                                                                        {category}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    ) : (
                                                        <Skeleton
                                                            variant={'rectangular'}
                                                            sx={{ bgcolor: SKELETON_COLOR }}
                                                            animation={'wave'}
                                                            width={'100%'}
                                                            height={45}
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            {!isAILoading ? (
                                                <Grid item>
                                                    <TextField
                                                        margin="dense"
                                                        label="URL"
                                                        type="text"
                                                        fullWidth
                                                        value={link}
                                                        placeholder={'http://'}
                                                        onFocus={(event) => {
                                                            event.target.setAttribute('autocomplete', 'off')
                                                        }}
                                                        onChange={(e) => setLink(e.target.value)}
                                                    />
                                                </Grid>
                                            ) : (
                                                <Grid item>
                                                    <Skeleton
                                                        variant={'rectangular'}
                                                        sx={{ bgcolor: SKELETON_COLOR }}
                                                        animation={'wave'}
                                                        width={'100%'}
                                                        height={45}
                                                    />
                                                </Grid>
                                            )}
                                        </>
                                    </Grid>
                                )
                            case 'gap':
                                return (
                                    <Grid container>
                                        <DialogContentText>
                                            {dialogueHeaderMapping[props.id].description}
                                        </DialogContentText>
                                        <TextField
                                            margin="dense"
                                            id="gapTitle"
                                            label="Title"
                                            type="text"
                                            fullWidth
                                            multiline
                                            rows={2}
                                            value={gapTitle}
                                            onFocus={(event) => {
                                                event.target.setAttribute('autocomplete', 'off')
                                            }}
                                            onChange={(e) => setGapTitle(e.target.value)}
                                        />
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">QUALITY RATING</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-label="quality"
                                                name="quality1"
                                                value={gapPriority}
                                                onChange={handleChange}>
                                                <FormControlLabel value="low" control={<RedRadio />} label="Low" />
                                                <FormControlLabel
                                                    value="medium"
                                                    control={<YellowRadio />}
                                                    label="Medium"
                                                />
                                                <FormControlLabel value="high" control={<GreenRadio />} label="High" />
                                            </RadioGroup>
                                        </FormControl>
                                        <TextField
                                            margin="dense"
                                            id="gapSummary"
                                            label="Summary"
                                            type="text"
                                            fullWidth
                                            multiline
                                            rows={2}
                                            value={gapSummary}
                                            onFocus={(event) => {
                                                event.target.setAttribute('autocomplete', 'off')
                                            }}
                                            onChange={(e) => setGapSummary(e.target.value)}
                                        />
                                        {/* <TextField
                                            margin="dense"
                                            id="gapNotes"
                                            label="Notes"
                                            type="text"
                                            fullWidth
                                            multiline
                                            rows={2}

                                            value={gapNotes}
                                            onFocus={(event) => {
                                                event.target.setAttribute('autocomplete', 'off')
                                            }}
                                            onChange={(e) => setGapNotes(e.target.value)}
                                            style={{ marginTop: '20px' }}
                                        />
                                        <Autocomplete
                                            fullWidth
                                            multiple
                                            value={newLeverText}
                                            onChange={(event, newLeverValue) => {
                                                if (typeof newLeverValue === 'string') {
                                                    setLeverValue({
                                                        leverValue: newLeverValue,
                                                    })
                                                } else if (newLeverValue && newLeverValue.inputValue) {
                                                    // Create a new value from the user input
                                                    setLeverValue({
                                                        leverValue: newLeverValue.inputValue,
                                                    })
                                                } else {
                                                    setLeverValue(newLeverValue)
                                                }
                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params)

                                                // Suggest the creation of a new value
                                                if (params.inputValue !== '') {
                                                    filtered.push({
                                                        inputValue: params.inputValue,
                                                        leverValue: `Add "${params.inputValue}"`,
                                                    })
                                                }

                                                return filtered
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            id="free-solo-with-text-demo"
                                            options={leverValue}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue
                                                }
                                                // Regular option
                                                return option.leverValue
                                            }}
                                            renderOption={(option) => option.leverValue}
                                            // style={{ width: 500 }}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label="Enter Lever"

                                                />
                                            )}
                                        /> */}
                                    </Grid>
                                )
                            case 'deliverables':
                                return (
                                    <Grid container direction="column" spacing={1}>
                                        <TextField
                                            id="deliverableTitle"
                                            value={deliverableTitle}
                                            fullWidth
                                            margin="normal"
                                            label="Title"
                                            onChange={(e) => setDeliverableTitle(e.target.value)}
                                        />
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel id="pub-type-label">Type</InputLabel>
                                            <Select
                                                labelId="pub-type"
                                                id="pub-type"
                                                value={deliverableType}
                                                inputProps={{
                                                    className: classes.inputLabel,
                                                }}
                                                onChange={(e) => setDeliverableType(e.target.value)}
                                                label="Type">
                                                <MenuItem value="None">
                                                    <em>None</em>
                                                </MenuItem>
                                                {categories.map((x, i) => (
                                                    <MenuItem value={x} key={x + i}>
                                                        {x}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Grid container item justify="space-between" wrap="nowrap">
                                            {/*<Grid container item direction="column">*/}
                                            {/*    <Grid item>*/}
                                            {/*        <Typography variant="subtitle2">DUE DATE:</Typography>*/}
                                            {/*    </Grid>*/}
                                            {/*    <Grid>*/}
                                            {/*        <DatePicker*/}
                                            {/*            selected={deliverableDate}*/}
                                            {/*            onChange={(e) => setDeliverableDate(e)}*/}
                                            {/*            dateFormat="yyyy, QQQ"*/}
                                            {/*            showQuarterYearPicker*/}
                                            {/*        />*/}
                                            {/*    </Grid>*/}
                                            {/*</Grid>*/}
                                            <Grid container item wrap="nowrap" direction="column">
                                                <Grid item>
                                                    <Typography variant="subtitle2">STATUS: </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <WhiteOutlineCheckbox
                                                                checked={deliverableIsComplete}
                                                                onChange={(e) =>
                                                                    setDeliverableIsComplete(e.target.checked)
                                                                }
                                                                name="Completed"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Completed"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            case 'tracelink':
                                return (
                                    <Grid container direction="column">
                                        <DialogContentText>
                                            {dialogueHeaderMapping[props.id].description}
                                        </DialogContentText>
                                        <Grid item xs>
                                            <TextField
                                                margin="dense"
                                                id="tracelink-slug"
                                                label="Slug"
                                                type="text"
                                                fullWidth
                                                value={tracelinkSlug}
                                                onFocus={(event) => {
                                                    event.target.setAttribute('autocomplete', 'off')
                                                }}
                                                onChange={(e) => setTracelinkSlug(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                margin="dense"
                                                id="tracelink-owner"
                                                label="Owner"
                                                type="text"
                                                fullWidth
                                                value={tracelinkOwner}
                                                onFocus={(event) => {
                                                    event.target.setAttribute('autocomplete', 'off')
                                                }}
                                                onChange={(e) => setTracelinkOwner(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            default:
                                return <p></p>
                        }
                    })()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} disabled={!!props.isLoading} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openNewLocalVMDialog}
                onClose={() => setOpenNewLocalVMDialog(false)}
                aria-labelledby="form-dialog-title1">
                <DialogTitle id="form-dialog-title">Creating new Local Message</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="subtitle1">
                            This message will not appear in the Global view, and changes made will remain specific to
                            this. Do you wish to continue?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button
                        disabled={!!props.isLoading}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            createNew({
                                name: name,
                                priority: priority,
                                order: 1,
                                status,
                                strategyId: store.getState().strategy._id,
                                organizationId: store.getState().strategy.organizationId,
                                linkedEvidence: [],
                                linkedActivities: [],
                                description: vmDescription,
                                audiences: newAudiences,
                                primaryEvidence: [],
                                localMarkets: [store.getState().selectedMarket],
                                isCountrySpecific: true,
                            })
                            setOpenNewLocalVMDialog(false)
                            setName('')
                            setPriority('')
                            setStatus('Supported')
                            setNewAudiences(audiences)
                            setVMDescription('')
                            setNewLocalMarkets(flattenMarkets().map((market) => market.code))
                        }}>
                        Create Local Message
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
})

export default CreateButton
