import React from 'react'
import { makeStyles } from '@mui/styles'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import FormControlLabel from '@mui/material/FormControlLabel'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Tooltip from '@mui/material/Tooltip'
import { capitalize } from '../../utilities/helpers'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import { useSelector } from 'react-redux'

export default function ControlledExpansionPanels(props) {
    const { goal, index } = props
    const snapshotMode = useSelector((state) => (state.strategy ? !!state.strategy.sourceId : false))
    const userReadonly = useSelector((state) => (state.userRecord ? !!state.userRecord.readonly : false))

    const useStyles = makeStyles((theme) => ({
        root: {
            marginTop: '20px',
            marginRight: '10px',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        panel: {
            width: '280px',
            minHeight: '125px',
            maxHeight: '400px',
            borderTop: '7px solid ' + getColor(),
        },
    }))

    function getColor() {
        if (goal.priority === 'high') {
            return 'red'
        } else if (goal.priority === 'medium') {
            return 'yellow'
        } else {
            return 'green'
        }
    }

    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)
    const [open, setOpen] = React.useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Tooltip title={'Gap Level: ' + capitalize(goal.priority)} placement="top-start" arrow>
                <Accordion
                    className={classes.panel}
                    elevation={3}
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        {!snapshotMode && !userReadonly && (
                            <FormControlLabel
                                aria-label="delete"
                                onClick={(event) => {
                                    event.stopPropagation()
                                    handleClickOpen()
                                }}
                                onFocus={(event) => {
                                    event.stopPropagation()
                                }}
                                control={<CloseOutlinedIcon />}
                                style={{ width: '.8em', height: '.8em', color: '#BCBCBC' }}
                                // label={goal.title}
                            />
                        )}
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">
                                <Typography variant="h4">{`Are you sure you want to delete: "${goal.title}"?`}</Typography>
                            </DialogTitle>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        handleClose()
                                    }}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        props.deleteGoal(index)
                                        handleClose()
                                    }}
                                    autoFocus>
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <div
                            style={{
                                display: 'flex',
                                flexFlow: 'column',
                                marginLeft: '20px',
                            }}>
                            <h4>{goal.title}</h4>
                            <p>{goal.summary}</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex', flexFlow: 'column' }}>
                        <h6>Notes:</h6>
                        <Typography>{goal.notes}</Typography>
                    </AccordionDetails>
                </Accordion>
            </Tooltip>
        </div>
    )
}
