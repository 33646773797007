import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as PanelIcon } from '../../assets/images/responsive-design-expand-1.svg'
import { ReactComponent as EditIcon } from '../../assets/images/Edit_Icon.svg'
import { ReactComponent as CloseIcon } from '../../assets/images/Close.svg'
import { ReactComponent as CommentsIcon } from '../../assets/images/messages-bubble-add.svg'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import '../../style/detailed-view.scss'
import ActivityDetailedView from '../Activities/ActivityDetailedView'
import VMDetailedView from '../ValueMessages/VMDetailedView'
import EvidenceDetailedView from '../Evidence/EvidenceDetailedView'
import StrategicImperativeDetailedView from '../StrategicImperatives/StrategicImperativeDetailedView'
import { useSelector } from 'react-redux'
import * as _ from 'underscore'
import { Button, SvgIcon } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import { ArrowBackIos } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import { Comments } from '../../shared/components/Comments'
import { useIsDarkMode } from '../../shared/hooks/useIsDarkMode'

export default function TablePanel({
    data,
    type,
    closePanel,
    openPanelAction,
    updateParent,
    navigationStack,
}) {
    const [openPanel, setOpenPanel] = useState(false)
    const [editing, setEditing] = useState(false)
    const [isCommentsOpen, setIsCommentsOpen] = useState(false)
    const snapshotMode = useSelector((state) => (state.strategy ? !!state.strategy.sourceId : false))
    const userReadonly = useSelector((state) => (state.userRecord ? !!state.userRecord.readonly : false))
    const [triggerSave, setTriggerSave] = useState(0)
    const [triggerDelete, setTriggerDelete] = useState(0)
    const [triggerCancel, setTriggerCancel] = useState(0)
    const isDarkMode = useIsDarkMode()

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        setOpenPanel(open)
    }

    function handlePanelClose() {
        toggleDrawer(false)
        closePanel()
    }

    useEffect(() => {
        if (openPanelAction) {
            setOpenPanel(true)
        }
    }, [])

    const dialogTitleRef = useRef(null);
    const [dialogTitleHeight, setDialogTitleHeight] = useState(0);
    const dialogContentHeight = `calc(100% - ${dialogTitleHeight}px)`;

    useEffect(() => {
        if (dialogTitleRef.current) {
            setDialogTitleHeight(dialogTitleRef.current.offsetHeight);
        }
    }, [openPanel]);

    return (
        <>
            <IconButton onClick={toggleDrawer(true)}>
                <PanelIcon />
            </IconButton>
            <SwipeableDrawer
                transitionDuration={400}
                anchor="right"
                open={openPanel}
                onClose={handlePanelClose}
                onOpen={toggleDrawer(true)}
                PaperProps={{ sx: { width: isCommentsOpen ? '95%' : '80%', backgroundColor: isDarkMode ? '#363943' : 'white' } }}>
                <Box sx={{ backgroundColor: isDarkMode ? '#363943' : 'white', ...(isCommentsOpen ? {overflow: 'hidden', height: '100%'} : {}) }} >
                    <DialogTitle sx={{ p: 2 }} id="customized-dialog-title" ref={dialogTitleRef}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid container item xs="auto">
                                {!_.isEmpty(navigationStack) ? (
                                    <Link
                                        component={RouterLink}
                                        underline="none"
                                        color="secondary"
                                        to={{
                                            pathname: navigationStack[navigationStack.length - 1].path,
                                            autoOpenData: {
                                                isAutoOpenPanel: true,
                                                autoOpenData: navigationStack[navigationStack.length - 1].data,
                                                previousDataStack: navigationStack.slice(0, navigationStack.length - 1),
                                            },
                                        }}>
                                        <Tooltip
                                            enterDelay={600}
                                            enterNextDelay={600}
                                            title="Back to linked data"
                                            arrow>
                                            <IconButton>
                                                <ArrowBackIos color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                            <Grid container alignItems="center" justifyContent="flex-end" xs="auto" spacing={1}>
                                <Grid item xs="auto">
                                    {editing ? (
                                        <Grid container item spacing={1}>
                                            <Grid item>
                                                <Button
                                                    onClick={() => setTriggerDelete((prev) => prev + 1)}
                                                    aria-label="delete"
                                                    variant="no-outline-sm-button">
                                                    Delete
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    onClick={() => setTriggerCancel((prev) => prev + 1)}
                                                    aria-label="cancel"
                                                    variant="outlined-sm-button">
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    onClick={() => setTriggerSave((prev) => prev + 1)}
                                                    aria-label="save"
                                                    variant="contained-sm-button">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        !snapshotMode &&
                                        !userReadonly && (
                                            <Grid container item xs="auto" spacing={1}>
                                                <Grid item>
                                                    <IconButton
                                                        aria-label="comments"
                                                        onClick={() => setIsCommentsOpen(!isCommentsOpen)}
                                                        size="large">
                                                        <SvgIcon
                                                            component={CommentsIcon}
                                                            inheritViewBox
                                                            color="primary"
                                                            fontSize="inherit"
                                                        />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton
                                                        aria-label="edit"
                                                        onClick={() => setEditing(true)}
                                                        size="large">
                                                        <SvgIcon
                                                            component={EditIcon}
                                                            inheritViewBox
                                                            color="primary"
                                                            fontSize="inherit"
                                                        />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                                <Grid item xs="auto"mb={-0.5} >
                                    <IconButton
                                        onClick={handlePanelClose}
                                        onKeyDown={handlePanelClose}
                                        aria-label="close"
                                        size="large"
                                    >
                                        <SvgIcon
                                            component={CloseIcon}
                                            inheritViewBox
                                            color="primary"
                                            fontSize="inherit"
                                        />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent
                        dividers
                        sx={{ backgroundColor: isDarkMode ? '#363943' : 'white', borderBottom: 'none', p: 0, ...(isCommentsOpen ? { height: dialogContentHeight, overflow: 'hidden' } : {})}}>
                        <Grid container sx={{ ...(isCommentsOpen ? { height: '100%', overflow: 'auto' } : {}) }}>
                            <Grid container item xs={12} md={isCommentsOpen ? 6 : 12} lg={isCommentsOpen ? 8 : 12} xl={isCommentsOpen ? 8.5 : 12} sx={{ ...(isCommentsOpen ? { height: '100%', overflow: 'auto', paddingRight: 2 } : {}) }}>
                                {type === 'activity' && (
                                    <ActivityDetailedView
                                        activity={data}
                                        forceParentRefresh={updateParent}
                                        closePanel={handlePanelClose}
                                        setEditing={setEditing}
                                        editing={editing}
                                        triggerSave={triggerSave}
                                        triggerDelete={triggerDelete}
                                        triggerCancel={triggerCancel}
                                        navigationStack={navigationStack}
                                    />
                                )}
                                {type === 'valueMessage' && (
                                    <VMDetailedView
                                        valueMessage={data}
                                        forceParentRefresh={updateParent}
                                        closePanel={handlePanelClose}
                                        setEditing={setEditing}
                                        editing={editing}
                                        triggerSave={triggerSave}
                                        triggerDelete={triggerDelete}
                                        triggerCancel={triggerCancel}
                                        navigationStack={navigationStack}
                                        isCommentsOpen={isCommentsOpen}
                                        dialogTitleHeight={dialogTitleHeight}
                                    />
                                )}
                                {type === 'evidence' && (
                                    <EvidenceDetailedView
                                        evidence={data}
                                        forceParentRefresh={updateParent}
                                        closePanel={handlePanelClose}
                                        setEditing={setEditing}
                                        editing={editing}
                                        triggerSave={triggerSave}
                                        triggerDelete={triggerDelete}
                                        triggerCancel={triggerCancel}
                                        navigationStack={navigationStack}
                                    />
                                )}
                                {type === 'strategicImperative' && (
                                    <StrategicImperativeDetailedView
                                        strategicImperative={data}
                                        forceParentRefresh={updateParent}
                                        closePanel={handlePanelClose}
                                        setEditing={setEditing}
                                        editing={editing}
                                        triggerSave={triggerSave}
                                        triggerDelete={triggerDelete}
                                        triggerCancel={triggerCancel}
                                        navigationStack={navigationStack}
                                    />
                                )}
                            </Grid>
                            {isCommentsOpen && (
                                <Grid container item xs={12} md={isCommentsOpen ? 6 : 12} lg={isCommentsOpen ? 4 : 12} xl={isCommentsOpen ? 3.5 : 12} sx={{ ...(isCommentsOpen ? { height: '100%', overflow: 'auto' } : {}) }} order={{ xs: -1, md: 0 }}>
                                    <Comments
                                        dataId={data._id}
                                        dataType={type}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <Divider style={{ border: 'none' }} />
                </Box>
            </SwipeableDrawer>
        </>
    )
}
