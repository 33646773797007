import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material/'
import Typography from '@mui/material/Typography'
import callAPI from '../../../API/callSecureAPI'
import endpoints from '../../../API/endpoints'
import store from '../../../redux/store'
import { useAuth0 } from '../../../contexts/auth0-context'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { ReactComponent as ActivityIcon } from '../../../assets/images/Act_Icon.svg'
import SvgIcon from '@mui/material/SvgIcon'
import { EmptyPlaceholder } from '../../../shared/components/EmptyPlaceholder'
import { useActivityStatus, ACTIVITY_STATUSES } from '../../../shared/hooks/useActivityStatus'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
    indexAxis: 'y',
    aspectRatio: 35,
    scales: {
        y: {
            beginAtZero: true,
            stacked: true,
            display: false,
        },
        x: {
            display: false,
        },
    },
    elements: {
        bar: {},
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'right',
            display: false,
        },
        tooltip: {
            enabled: false,
        },
    },
}

const BORDER_RADIUS = 15

export default function ActivityStatusWidget() {
    const { getTokenSilently, isAuthenticated } = useAuth0();

    const { handleGetActivityStatusDetails } = useActivityStatus();

    // chartData contains keys of categories, and each contains an array with data and counts
    const [allData, setAllData] = useState({})

    function getAspirationalKnownForType(category, activities) {
        let filtered = activities.filter((activities) => activities.category === category)
        let total = filtered.length
        let completed = filtered.filter((act) => act.status === 'Completed').length
        let initiated = filtered.filter((act) => act.status === 'Initiated').length
        let onHold = filtered.filter((act) => act.status === 'On Hold').length
        let planned = filtered.filter((act) => act.status === 'Planned').length
        let cancelled = filtered.filter((act) => act.status === 'Cancelled').length

        let borderLeft = { topLeft: BORDER_RADIUS, bottomLeft: BORDER_RADIUS }
        let borderMiddle = {}
        let borderRight = { topRight: BORDER_RADIUS, bottomRight: BORDER_RADIUS }
        let borderBoth = {
            topLeft: BORDER_RADIUS,
            bottomLeft: BORDER_RADIUS,
            topRight: BORDER_RADIUS,
            bottomRight: BORDER_RADIUS,
        }

        let left = 'Planned'
        let right = 'Planned'
        let statuses = ['Planned', 'Initiated', 'On Hold', 'Cancelled', 'Completed']
        let vars = [
            planned,
            planned + initiated,
            planned + initiated + onHold,
            planned + initiated + onHold + cancelled,
            planned + initiated + onHold + cancelled + completed,
        ]

        let max = 0
        let minFound = false
        for (let i = 0; i < statuses.length; i++) {
            if (vars[i] > 0 && !minFound) {
                left = statuses[i]
                minFound = true
            }
            if (i === statuses.length - 1 && vars[i - 1] === 0) {
                left = statuses[i]
            }
            if (vars[i] > max) {
                right = statuses[i]
                max = vars[i]
            }
        }

        function getBorderRadius(label) {
            if (left === label && right === label) {
                return borderBoth
            } else if (left === label) {
                return borderLeft
            } else if (right === label) {
                return borderRight
            } else {
                return borderMiddle
            }
        }

        function getColorByStatus(activityStatus) {
            return handleGetActivityStatusDetails(activityStatus).color
        }

        let chartData = {
            labels: ['Status'],
            datasets: [
                {
                    label: 'Planned',
                    data: [
                        {
                            x: [0, planned],
                            y: 'Status',
                        },
                    ],
                    backgroundColor: getColorByStatus('Planned'),
                    borderSkipped: false,
                    borderRadius: getBorderRadius('Planned'),
                },
                {
                    label: 'Initiated',
                    data: [
                        {
                            x: [planned, planned + initiated],
                            y: 'Status',
                        },
                    ],
                    backgroundColor: getColorByStatus('Initiated'),
                    borderSkipped: false,
                    borderRadius: getBorderRadius('Initiated'),
                },
                {
                    label: 'On Hold',
                    data: [
                        {
                            x: [planned + initiated, planned + initiated + onHold],
                            y: 'Status',
                        },
                    ],
                    backgroundColor: getColorByStatus('On Hold'),
                    borderSkipped: false,
                    borderRadius: getBorderRadius('On Hold'),
                },
                {
                    label: 'Cancelled',
                    data: [
                        {
                            x: [planned + initiated + onHold, planned + initiated + onHold + cancelled],
                            y: 'Status',
                        },
                    ],
                    backgroundColor: getColorByStatus('Cancelled'),
                    borderSkipped: false,
                    borderRadius: getBorderRadius('Cancelled'),
                },
                {
                    label: 'Completed',
                    data: [
                        {
                            x: [
                                planned + initiated + onHold + cancelled,
                                planned + initiated + onHold + cancelled + completed,
                            ],
                            y: 'Status',
                        },
                    ],
                    backgroundColor: getColorByStatus('Completed'),
                    borderSkipped: false,
                    borderRadius: getBorderRadius('Completed'),
                },
            ],
        }

        return {
            total: total,
            Completed: completed,
            Initiated: initiated,
            'On Hold': onHold,
            Planned: planned,
            Cancelled: cancelled,
            chartDataSettings: chartData,
        }
    }

    async function getActivityData() {
        try {
            const token = await getTokenSilently()
            const response = await callAPI(
                `${endpoints.getActivitiesForTable.url}${store.getState().strategy._id}`,
                'GET',
                token
            )

            if (response.success) {
                let categoryData = {}
                const categories = store.getState().settings.activityCategories

                categories.forEach((category) => {
                    categoryData[category] = getAspirationalKnownForType(category, response.data)
                })

                setAllData(categoryData)
            }
        } catch (error) {
            console.error('Error fetching activity data:', error)
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            getActivityData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    const isActivityByCategoryDataEmpty = !Object.values(allData).some(data => !!data.total);

    return (
        <Grid container item spacing={2} p={2} boxShadow="none" flexDirection={isActivityByCategoryDataEmpty ? 'column' : 'row'} height={isActivityByCategoryDataEmpty ? '100%' : 'auto'}>
            <Grid container item mb={2} spacing={2} direction="column">
                {/* HEADER*/}
                <Grid container item alignItems="center" spacing={2} xs="auto" wrap="nowrap">
                    <Grid item>
                        <SvgIcon component={ActivityIcon} />
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                            Activities by Category
                        </Typography>
                    </Grid>
                </Grid>

                {!isActivityByCategoryDataEmpty && (
                    <>
                        {/* LEGEND*/}
                        <Grid container item xs="auto" spacing={1}>
                            {ACTIVITY_STATUSES.map((activityStatus) => {
                                const activityStatusDetails = handleGetActivityStatusDetails(activityStatus);
                                return (
                                    <Grid container item alignItems="center" spacing={1} xs="auto" wrap="nowrap" key={activityStatus}>
                                        <Grid item xs>
                                            <SvgIcon
                                                component={activityStatusDetails.icon}
                                                inheritViewBox
                                                fontSize="small"
                                            />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Typography variant="overline">
                                                {activityStatusDetails.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )})}
                        </Grid>
                    </>
                )}
            </Grid>
            {isActivityByCategoryDataEmpty && <EmptyPlaceholder text='No Activities to display' />}
            {!isActivityByCategoryDataEmpty && (
                <>
                    {/* CATEGORY NAME, ICONS AND COUNTS SEGMENTED LINE BAR*/}
                    {Object.keys(allData).length > 0 && Object.keys(allData).map((category) => {
                        const categoryData = allData[category]
                        let hasNoStatuses =
                            categoryData['Cancelled'] +
                            categoryData['Completed'] +
                            categoryData['Initiated'] +
                            categoryData['On Hold'] +
                            categoryData['Planned'] ===
                            0
                        if (categoryData.total === 0 || hasNoStatuses) {
                            return <></> // Skip rendering if total is 0
                        }
                        return (
                            <Grid container item xs={12} key={category}>
                                <Grid container item spacing={2} xs={12} justifyContent="space-between">
                                    {/* CATEGORY*/}
                                    <Grid item>
                                        <Typography variant="h4">{category}</Typography>
                                    </Grid>
                                    {/* ICON AND COUNT*/}
                                    <Grid container item wrap="nowrap" xs="auto" spacing={2} alignItems="center">
                                        {ACTIVITY_STATUSES.map((activityStatus) => {
                                            const activityStatusDetails = handleGetActivityStatusDetails(activityStatus);

                                            return (
                                                categoryData[activityStatus] > 0 && (
                                                    <Grid
                                                        container
                                                        item
                                                        xs="auto"
                                                        spacing={2}
                                                        wrap="nowrap"
                                                        key={activityStatus}>
                                                        <Grid item>
                                                            <SvgIcon
                                                                component={activityStatusDetails.icon}
                                                                inheritViewBox
                                                                fontSize="small"
                                                            />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Typography variant="title1" noWrap>
                                                                {categoryData[activityStatus]}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                                {/* SEGMENTED LINE BAR */}
                                <Grid container item xs={12} direction="column" sx={{ maxHeight: '14px' }}>
                                    <Bar
                                        options={options}
                                        data={categoryData.chartDataSettings}
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                            </Grid>
                        )
                    })}
                </>
            )}
        </Grid>
    )
}
