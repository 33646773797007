import { makeStyles, withStyles } from '@mui/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

export const useStyles = makeStyles((theme) => ({
    // settingsContainer: {
    //     justifyContent: 'center',
    //     alignContent: 'center',
    //     display: "flex",
    //     flexFlow: "column"
    // },
    // accordianSquare: {
    //     display: 'flex',
    //     flexFlow: 'column',
    // },
    icons: {
        maxHeight: '50px',
        minWidth: '2vw',
        maxWidth: '40px',
        objectFit: 'contain',
        marginRight: '5px',
    },
    // tagText: {
    //     borderColor: 'rgba(255, 255, 255, 0.07)',
    //     '&:hover': {
    //         borderColor: 'rgba(64, 170, 255, 0.54)',
    //     },
    // },
}))

export const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, 0.69)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        // '&$expanded': {
        //     margin: 'auto',
        // },
        width: '100%',
    },
    expanded: {},
})(MuiAccordion)

export const AccordionSummary = withStyles({
    root: {
        // borderBottom: '1px solid rgba(0, 0, 0, .125)',
        // marginBottom: -1,
        // minHeight: 56,
        // '&$expanded': {
        //     minHeight: 56,
        // },
    },
    content: {
        // '&$expanded': {
        //     margin: '12px 0',
        // },
        // alignItems: 'center',
    },
    expanded: {},
})(MuiAccordionSummary)

export const AccordionDetails = withStyles((theme) => ({
    root: {
        // padding: theme.spacing(2),
    },
}))(MuiAccordionDetails)
