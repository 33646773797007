import React from 'react'
import { COLORS } from '../../../style/colors'
import { Box } from '@mui/material'
import { DeterminateCircularProgress, DeterminateCircularProgressProps } from './components/DeterminateCircularProgress'

export type MultiDeterminateCircularProgressProps = DeterminateCircularProgressProps & {
    backgroundColor: (typeof COLORS)[keyof typeof COLORS]
}

export const MultiDeterminateCircularProgress = ({
    value,
    color,
    backgroundColor,
}: MultiDeterminateCircularProgressProps) => {
    return (
        <Box position="relative" height="100%" width="100%">
            <Box position="absolute" top={0} left={0} height="100%" width="100%">
                <DeterminateCircularProgress color={backgroundColor} value={100} />
            </Box>
            <Box position="absolute" top={0} left={0} height="100%" width="100%">
                <DeterminateCircularProgress color={color} value={value} />
            </Box>
        </Box>
    )
}
