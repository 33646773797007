import axios from 'axios'

export const uploadFile = (file, timestamp, atlasFileType, token) => {
    const formData = new FormData()
    formData.append('userfile', file.link)
    axios.post('/api/aws/upload', formData, {
        headers: {
            timestamp: timestamp,
            Authorization: `Bearer ${token}`,
            atlasfiletype: atlasFileType,
        },
    })
}
