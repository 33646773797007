export default {
    getActivity: { url: '/api/activity/', method: 'get' },
    getAllActivitiesByOrg: { url: '/api/activity/allOrgActivities/', method: 'get' },
    updateActivity: { url: '/api/activity/', method: 'put' },
    deleteActivity: { url: '/api/activity/', method: 'delete' },
    linkActivityToVM: { url: '/api/value-message/link/activity/', method: 'get' },
    deleteLinkActivityToVM: { url: '/api/value-message/link/activity/', method: 'delete' },
    linkActivityToEvidence: { url: '/api/evidence/link/activity/', method: 'get' },
    deleteLinkActivityToEvidence: { url: '/api/evidence/link/activity/', method: 'delete' },
    getActivitiesForTable: { url: '/api/activities/', method: 'get' },
    getLinkedActivities: { url: '/api/activities/list/', method: 'post' },
    createActivity: { url: '/api/activity/', method: 'post' },
    addPrimaryEvidenceToActivity: { url: '/api/activity/addPrimaryEvidence/', method: 'put' },
    removePrimaryEvidenceToActivity: { url: '/api/activity/removePrimaryEvidence/', method: 'put' },
    reorderActivities: { url: '/api/activity/reorder', method: 'post' },

    getStrategy: { url: '/api/strategy/', method: 'get' },
    createStrategy: { url: '/api/strategy/', method: 'post' },
    updateStrategy: { url: '/api/strategy/', method: 'put' },
    updateStrategyV2: { url: '/api/strategyV2/', method: 'put' },

    // :id/removeFile/:s3Name/:fileType
    removeStrategyFile: { url: '/api/strategy/', method: 'delete' },

    getValueMessage: { url: '/api/value-message/', method: 'get' },
    getValueMessagesForTable: { url: '/api/value-messages/', method: 'get' },
    getValueMessagesByOrganizationId: { url: '/api/value-messages/org/', method: 'get' },
    updateValueMessage: { url: '/api/value-message/', method: 'put' },
    deleteValueMessage: { url: '/api/value-message/', method: 'delete' },
    getLinkedVMs: { url: '/api/value-messages/list/', method: 'post' },
    linkVMToEvidence: { url: '/api/evidence/link/value-message/', method: 'get' },
    deleteLinkVMToEvidence: { url: '/api/evidence/link/value-message/', method: 'delete' },
    linkVMToActivity: { url: '/api/activity/link/value-message/', method: 'get' },
    deleteLinkVMToActivity: { url: '/api/activity/link/value-message/', method: 'delete' },
    createValueMessage: { url: '/api/value-message/', method: 'post' },
    reorderVMs: { url: '/api/value-message/reorder/', method: 'post' },
    addPrimaryEvidence: { url: '/api/value-message/addPrimaryEvidence/', method: 'put' },
    removePrimaryEvidence: { url: '/api/value-message/removePrimaryEvidence/', method: 'put' },
    removeCountryFromVM: { url: '/api/value-message/removeCountry/', method: 'put' },

    deleteEvidence: { url: '/api/evidence/', method: 'delete' },
    updateEvidence: { url: '/api/evidence/', method: 'put' },
    linkEvidenceToVM: { url: '/api/value-message/link/evidence/', method: 'get' },
    deleteLinkEvidenceToVM: { url: '/api/value-message/link/evidence/', method: 'delete' },
    linkEvidenceToActivity: { url: '/api/activity/link/evidence/', method: 'get' },
    deleteLinkEvidenceToActivity: { url: '/api/activity/link/evidence/', method: 'delete' },
    getEvidence: { url: '/api/evidence/', method: 'get' },
    getEvidenceForTable: { url: '/api/evidences/', method: 'get' },
    getLinkedEvidence: { url: '/api/evidence/list/', method: 'post' },
    createEvidence: { url: '/api/evidence/', method: 'post' },
    downloadEvidenceFile: { url: '/api/file/', method: 'get' },
    reorderEvidences: { url: '/api/evidence/reorder', method: 'post' },

    getNumberOfPillarsViaLinkedVMs: { url: '/api/evidence/pillars/', method: 'post' },

    deleteGapAnalysis: { url: '/api/gap-analysis/', method: 'delete' },
    updateGapAnalysis: { url: '/api/gap-analysis/', method: 'put' },
    getGapAnalysis: { url: '/api/gap-analysis/strategy/', method: 'get' },
    addMarketAccessGoal: { url: '/api/gap-analysis/goal/', method: 'post' },
    deleteMarketAccessGoal: { url: '/api/gap-analysis/goal/', method: 'delete' },
    updateGapAssessmentValue: { url: '/api/gap-analysis/assessment/', method: 'put' },
    updateReimbursableFileValue: { url: '/api/gap-analysis/reimbursable-file/', method: 'put' },

    getSIForTable: { url: '/api/strategic-imperatives/', method: 'get' },
    createStrategicImperative: { url: '/api/strategic-imperative', method: 'post' },
    getStrategicImperative: { url: '/api/strategic-imperative/', method: 'get' },
    linkActivityToSI: { url: '/api/strategic-imperative/link/activity/', method: 'post' },
    deleteLinkActivityToSI: { url: '/api/strategic-imperative/link/activity/', method: 'delete' },
    deleteStrategicImperative: { url: '/api/strategic-imperative/', method: 'delete' },
    updateStrategicImperative: { url: '/api/strategic-imperative/', method: 'put' },
    reorderStrategicImperatives: { url: '/api/strategic-imperatives/reorder', method: 'post' },

    createDeliverable: { url: '/api/deliverable/', method: 'post' },
    deleteDeliverableById: { url: '/api/deliverable/', method: 'delete' },
    updateDeliverableById: { url: '/api/deliverable/', method: 'put' },
    getDeliverableById: { url: '/api/deliverable/', method: 'get' },
    getDeliverableList: { url: '/api/deliverables/list/', method: 'post' },
    updateDeliverablesList: { url: '/api/deliverables/updateManyList/', method: 'post' },
    getDeliverablesByOrganization: { url: '/api/deliverables/', method: 'get' },

    // need to add /strategies to the end
    getStrategiesByOrganizationId: { url: '/api/organization/', method: 'get' },
    getSettingsByOrganizationId: { url: '/api/settings/organization/', method: 'get' },
    saveSettings: { url: '/api/settings/', method: 'put' },

    getOrganizationById: { url: '/api/organization/', method: 'get' },
    getFreeTrialStatus: { url: '/api/organization/trial/', method: 'get' },

    getUserAuth0: { url: '/api/users/', method: 'get' },
    getUsersByOrgId: { url: '/api/users/org/', method: 'get' },
    updateUserMetrics: { url: '/api/user/metrics/', method: 'put' },
    // after userID, put colorMode
    changeUserColorMode: { url: '/api/user/', method: 'put' },

    downloadTPP: { url: '/api/file/strat/', method: 'get' },

    // Append orgId to this call
    downloadAnyFile: { url: '/api/file/any/', method: 'get' },

    getTracelinksForTable: { url: '/api/tracelink', method: 'get' },
    createTracelink: { url: '/api/tracelink', method: 'post' },

    createEvidenceGap: { url: '/api/evidence-gap', method: 'post' },
    deleteEvidenceGap: { url: '/api/evidence-gap/', method: 'delete' },
    updateEvidenceGap: { url: '/api/evidence-gap/', method: 'put' },
    getEvidenceGapsByStrategyId: { url: '/api/evidence-gaps/', method: 'get' },
    reorderEvidenceGaps: { url: '/api/evidence-gaps', method: 'put' },
    linkActivitiesToGap: { url: '/api/evidence-gap/link/', method: 'put' },
    unlinkActivitiesFromGap: { url: '/api/evidence-gap/unlink/', method: 'put' },

    submitFeedback: { url: '/api/feedback', method: 'post' },

    getTableComments: { url: '/api/comment/table/', method: 'get' },
    getDataComments: { url: '/api/comment/data/', method: 'get' },
    postDataComment: { url: '/api/comment/', method: 'post' },
    deleteDataComment: { url: '/api/comment/', method: 'delete' },

    getValueNarratives: { url: '/api/narrative/roadmap/', method: 'get' },
    createValueNarrative: { url: '/api/narrative/', method: 'post' },
    updateValueNarrative: { url: '/api/narrative/', method: 'put' },

    getNarrativeCategories: { url: '/api/narrative-category/org/', method: 'get' },
}
