import React from 'react'
import { useSelector } from 'react-redux'
import { FloatingNotification } from '../../../../../shared/components/FloatingNotification'
import { useExitSnapshotMode } from '../../../../../shared/hooks/useExitSnapshotMode'
import { useIsSnapshotMode } from '../../../../../shared/hooks/useIsSnapshotMode'
import { useSnapshotModeFloatingNotification } from './useSnapshotModeFloatingNotification'

export const SnapshotModeFloatingNotification = () => {
    const isSnapshotMode = useIsSnapshotMode();
    const { isAcknowledged, isMinimized, handleToggleIsMinimized } = useSnapshotModeFloatingNotification()
    const { handleExitSnapshotMode } = useExitSnapshotMode()

    const snapshotCreationDate = useSelector(
        // @ts-expect-error redux store is not typed properly
        (state) => state?.strategy?.createdAt
    )

    const formattedSnapshotCreationDate = new Date(snapshotCreationDate).toLocaleDateString()

    if (!isSnapshotMode) {
        return null
    }

    return (
        <FloatingNotification
            title={`Snapshot from ${formattedSnapshotCreationDate}`}
            buttonLabel="Back to live version"
            onButtonClick={handleExitSnapshotMode}
            closed={isMinimized}
            onClose={handleToggleIsMinimized}
            withPulsingCircle={!isAcknowledged}
        />
    )
}
