import React from 'react';
import { ReactComponent as AtlasIconSvg } from '../../assets/images/atlas-logo.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { useAtlasIconStyles } from './AtlasIcon.styles'
import { useIsDarkMode } from '../hooks/useIsDarkMode'
import clsx from 'clsx'

const AtlasIcon = () => {
    const isDarkMode = useIsDarkMode();

    const styles = useAtlasIconStyles();

    return (
        <SvgIcon
            component={AtlasIconSvg}
            className={clsx(styles.container, isDarkMode && styles.darkMode)}
            inheritViewBox
            fontSize="large"
        />
    );
};

export default AtlasIcon;
