import React from 'react'
import { useStyles } from '../../style/views'
import { ReactComponent as AddCircle } from '../../assets/images/add-circle.svg'
import SvgIcon from '@mui/material/SvgIcon'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export default function NewStrategy(props) {
    const [open, setOpen] = React.useState(false)
    const [product, setProduct] = React.useState('')
    const [indication, setIndication] = React.useState('')
    const { createNewStrategy } = props
    const classes = useStyles()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleCancel = () => {
        setOpen(false)
        setProduct('')
        setIndication('')
    }

    const handleSave = () => {
        createNewStrategy(product, indication)
        setOpen(false)
    }
    
    return (
        <Grid item>
            <Button variant="outlined" sx={{border: "1px solid 'primary'", borderRadius: "14px", height: 52, width: 249}} onClick={handleClickOpen}>
                CREATE A NEW ROADMAP
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">
                    <Typography variant="h3">New Roadmap</Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2} alignItems="center" sx={{ p: 1 }}>
                        <Grid item xs>
                            <TextField
                                id="name"
                                label="Product name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={product}
                                onFocus={(event) => {
                                    event.target.setAttribute('autocomplete', 'off')
                                }}
                                onChange={(e) => setProduct(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            for
                        </Grid>
                        <Grid item xs>
                            <TextField
                                id="name"
                                label="Indication"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={indication}
                                onFocus={(event) => {
                                    event.target.setAttribute('autocomplete', 'off')
                                }}
                                onChange={(e) => setIndication(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}
