import { makeStyles } from '@mui/styles'

export const useAtlasIconStyles = makeStyles((theme) => {
    return {
        container: {
            height: '1.15em !important',
            width: '1.15em !important',
            fill: 'none !important',
        },
        darkMode: {
            '& path': {
                stroke: 'white'
            }
        }
    }
})
