import { alpha } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PulsingCircleProps } from './PulsingCircle'
import { useIsDarkMode } from '../../hooks/useIsDarkMode'
import { COLORS } from '../../../style/colors'

export const usePulsingCircleStyles = ({ size, absolute, ...props }: PulsingCircleProps) => {
    const isDarkMode = useIsDarkMode()
    const color = props.color ?? (isDarkMode ? COLORS.darkPrimary : COLORS.primary)

    const styles = makeStyles(() => ({
        container: {
            position: absolute ? 'absolute' : 'relative',
            left: absolute ? -size / 5 : 0,
            top: absolute ? -size / 5 : 0,
            width: size,
            height: size,
            borderRadius: '50%',
            backgroundColor: color,
            animation: `$pulsing 1.5s steps(1,end) infinite`,
        },
        '@keyframes pulsing': {
            '0%': {
                boxShadow: `0 0 0 0 ${alpha(color, 0)}`,
                opacity: 1,
            },
            '25%': {
                boxShadow: `0 0 0 2px ${alpha(color, 0.4)}`,
                opacity: 0.9,
            },
            '50%': {
                boxShadow: `0 0 0 2px ${alpha(color, 0.4)}, 0 0 0 4px ${alpha(color, 0.2)}, 0px 0px 25px 2px ${alpha(color, 0.6)}`,
                opacity: 0.8,
            },
            '75%': {
                boxShadow: `0 0 0 4px ${alpha(color, 0.15)}, 0px 0px 20px 2px ${alpha(color, 0.6)}`,
                opacity: 0.9,
            },
        },
    }))

    return styles()
}
