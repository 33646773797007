import {
    DndContext,
    PointerSensor,
    pointerWithin,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { useIsDarkMode } from '../../../hooks/useIsDarkMode';
import { useIsReadonlyMode } from '../../../hooks/useIsReadonlyMode';
import { useIsSnapshotMode } from '../../../hooks/useIsSnapshotMode';
import TableBody from '@mui/material/TableBody';
import {
    restrictToParentElement,
    restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import * as React from 'react';
import { ComponentProps, Fragment, ReactNode } from 'react'
import { SortableTableRowProps } from '../SortableTableRow';

const MIN_DRAG_DISTANCE_TO_ALLOW_ROW_CLICK = 1;

type SortableTableRowData<T> = { id: string & T };

type SortableTableBodyChildren<T> = {
    index: number;
    rowData: SortableTableRowData<T>;
    rowProps: SortableTableRowProps;
};

type SortableTableBodyProps<T> = ComponentProps<typeof TableBody> & {
    data: SortableTableRowData<T>[];
    onSort: ComponentProps<typeof DndContext>['onDragEnd'];
    disabled?: boolean;
    children: (props: SortableTableBodyChildren<T>) => ReactNode;
};

export const SortableTableBody = <T,>({
    onSort,
    disabled,
    data,
    children,
    ...tableBodyProps
}: SortableTableBodyProps<T>) => {
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { distance: MIN_DRAG_DISTANCE_TO_ALLOW_ROW_CLICK },
        }),
    );

    const isDarkMode = useIsDarkMode();
    const isReadonlyMode = useIsReadonlyMode();
    const isSnapshotMode = useIsSnapshotMode();

    const isSortingDisabled =
        disabled || isReadonlyMode || isSnapshotMode || data.length < 2;

    const sx = { backgroundColor: isDarkMode ? '#363943' : '#F5F5F6' };

    return (
        <TableBody sx={sx} {...tableBodyProps}>
            <DndContext
                sensors={sensors}
                collisionDetection={pointerWithin}
                onDragEnd={onSort}
                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            >
                <SortableContext
                    items={data}
                    strategy={verticalListSortingStrategy}
                    disabled={isSortingDisabled}
                >
                    {data.map((rowData, index) =>
                        <Fragment key={rowData.id}>
                            {children({
                                rowData,
                                index,
                                rowProps: {
                                    id: rowData.id,
                                    disabled: isSortingDisabled,
                                },
                            })}
                        </Fragment>,
                    )}
                </SortableContext>
            </DndContext>
        </TableBody>
    );
};
