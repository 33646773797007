import { COLORS } from '../../../../../style/colors'
import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

export type DeterminateCircularProgressProps = {
    color: (typeof COLORS)[keyof typeof COLORS]
    value: number
}

export const DeterminateCircularProgress = ({ color, value }: DeterminateCircularProgressProps) => {
    return (
        <CircularProgress
            size="100%"
            variant="determinate"
            value={value}
            sx={{
                '& .MuiCircularProgress-circle': {
                    strokeLinecap: 'round',
                    strokeWidth: '1.5px',
                    color,
                },
            }}
        />
    )
}
