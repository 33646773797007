import React, { useState } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import { SvgIcon } from '@mui/material'
import { ReactComponent as PencilIcon } from '../../assets/images/pencil-write-2.svg'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { ButtonGroup } from '@mui/material'
import { styled, keyframes } from '@mui/system'

const progress = keyframes`
  0% { --percentage: 0; }
  100% { --percentage: var(--value); }
`

const ProgressBarContainer = styled('div')(({ value, isDarkMode }) => ({
    '--value': value,
    '--primary': isDarkMode ? '#ffffff' : '#000000',
    '--secondary': '#adf',
    '--size': '250px',
    animation: `${progress} 2s .5s forwards`,
    '--percentage': `${value}%`,
    width: 'var(--size)',
    aspectRatio: '2 / 1',
    borderRadius: '50% / 100% 100% 0 0',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',

    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: `conic-gradient(from 0.75turn at 50% 100%, var(--primary) calc(var(--value) * 1% / 2), var(--secondary) calc(var(--value) * 1% / 2 + 0.1%))`,
        mask: 'radial-gradient(at 50% 100%, white 55%, transparent 55.5%)',
        maskMode: 'alpha',
        WebkitMask: 'radial-gradient(at 50% 100%, #0000 55%, #000 55.5%)',
        WebkitMaskMode: 'alpha',
    },

    '&::after': {
        counterReset: `percentage ${value}`,
        content: 'counter(percentage) "%"',
        fontSize: 'calc(var(--size) / 5)',
        color: 'var(--primary)',
    },
}))

export const GapProgressBar = ({ rating, updateRating }) => {
    const snapshotMode = useSelector((state) => (state.strategy ? !!state.strategy.sourceId : false))
    const userReadonly = useSelector((state) => (state.userRecord ? !!state.userRecord.readonly : false))
    const isDarkMode = useSelector((state) =>
        !state.userRecord ? true : state?.userRecord?.isDarkMode === undefined ? true : state?.userRecord?.isDarkMode
    )
    const [newRating, setNewRating] = useState(rating || 0)
    const [edit, setEdit] = useState(false)

    const handleOnChange = (newRating) => {
        setNewRating(newRating)
    }

    const handleOnCancel = () => {
        setEdit(false)
        setNewRating(rating || 0)
    }

    const handleSave = () => {
        updateRating(newRating)
        setEdit(false)
    }

    return (
        <Grid container item>
            {/* <Typography variant="h5">
                Gap Progress: <p>{newRating}% Complete</p>
            </Typography> */}
            <Grid item>
                <ProgressBarContainer value={newRating} isDarkMode={isDarkMode} />
            </Grid>

            {!edit && !snapshotMode && !userReadonly && (
                <Grid item>
                    <Tooltip title="Select to Edit Progress Bar">
                        <IconButton onClick={() => setEdit(true)}>
                            <SvgIcon component={PencilIcon} color="primary" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}

            {edit && (
                <Grid container item spacing={2} sx={{ mt: 2 }}>
                    <Grid item>
                        <ButtonGroup variant="outlined">
                            {[0, 20, 40, 60, 80, 100].map((value) => (
                                <Button onClick={() => handleOnChange(value)}>{value}</Button>
                            ))}
                        </ButtonGroup>
                    </Grid>
                    <Grid item>
                        <ButtonGroup variant="outlined">
                            <Button onClick={handleSave}>Save</Button>
                            <Button onClick={handleOnCancel}>Cancel</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}
