import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'

export const useIsMobileDevice = () => {
    const theme = useTheme()

    const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'))

    return isMobileDevice
}
