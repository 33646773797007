import React, { useEffect, useState } from 'react'
import TablePanel from '../Support/TablePanel'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Fade from '@mui/material/Fade'
import * as _ from 'underscore'
import store from '../../redux/store'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import { useAuth0 } from '../../contexts/auth0-context'
import {
    EnhancedTableHead,
    getComparator,
    stableSort,
} from '../../utilities/helpers'
import { setSITableNumberOfItems } from '../../redux/actions'
import { connect, useSelector } from 'react-redux'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton from '@mui/material/IconButton'
import { GlobalStyles } from '@mui/material'
import { EmptyPlaceholder } from '../../shared/components/EmptyPlaceholder'
import { arrayMove } from '@dnd-kit/sortable'
import { SortableTable } from '../../shared/components/SortableTable'

function mapDispatchToProps(dispatch) {
    return {
        setSITableNumberOfRows: (num) => dispatch(setSITableNumberOfItems(num)),
    }
}

function ConnectedStrategicImperativeTable(props) {
    const { isAuthenticated, getTokenSilently } = useAuth0()
    const [page, setPage] = useState(0)
    const [dataRows, setDataRows] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(false)
    const strategicDisplayName = _.isEmpty(store.getState().settings)
        ? ''
        : store.getState().settings.displayNames.strategicImperatives

    const columns = [
        { id: 'index', label: '' },
        { id: 'name', label: 'Title' },
        { id: 'arrow', label: '', align: 'center' },
        { id: 'panel', label: '' },
    ]
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('')
    const [strategicImperativeData, setStrategicImperativeData] = useState({})
    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const [strategicImperativeForPanel, setStrategicImperativeForPanel] = useState(
        props?.autoOpenState?.isAutoOpenPanel ? props.autoOpenState.autoOpenData : {}
    )
    const [updateTableFromPanel, setUpdateTableFromPanel] = useState(false)
    const [navigationStack, setNavigationStack] = useState([])
    const { newFormData } = props
    const itemsToDisplay = useSelector((state) => state.siTableNumberOfItems || 10)

    useEffect(() => {
        // This fires when component mounts and any time the VM list is modified
        if (forceRefresh) {
            setForceRefresh(false)
        }

        if (!isAuthenticated) {
            return
        }

        if (updateTableFromPanel) {
            setUpdateTableFromPanel(false)
        }

        getStrategicImperativeData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceRefresh, isAuthenticated, updateTableFromPanel])

    useEffect(() => {
        // track 'newFormData' from props for changes
        // if it changes, it means the Create Button is sending data
        if (!_.isEmpty(newFormData)) {
            newStrategicImperative(newFormData).then(() => {
                setForceRefresh(true)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newFormData])

    async function getStrategicImperativeData() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingSI = await callAPI(
            endpoints.getSIForTable.url + store.getState().strategy._id,
            endpoints.getSIForTable.method,
            token
        )

        if (incomingSI.success) {
            createDataRows(
                incomingSI.data?.length > 0
                    ? incomingSI.data.sort((a, b) => (a.createdAt > b.createdAt) - (a.createdAt < b.createdAt))
                    : []
            )
        } else {
            setLoaded(true)
        }
        let siData = {}
        for (let si of (incomingSI.data || [])) {
            siData[si._id] = si
        }
        setStrategicImperativeData(siData)
        if (strategicImperativeForPanel && siData[strategicImperativeForPanel._id]) {
            setStrategicImperativeForPanel(siData[strategicImperativeForPanel._id])
        }
    }

    async function newStrategicImperative() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callAPI(
            endpoints.createStrategicImperative.url,
            endpoints.createStrategicImperative.method,
            token,
            newFormData
        )
    }

    async function createDataRows(data) {
        let arr = []
        if (data && data.length) {
            for (const si of data) {
                let dataRow = { name: si.name, id: si._id, order: si.order }
                arr.push(dataRow)
            }
        }
        arr.sort((a, b) => a.order - b.order)
        setDataRows(arr)
        setLoaded(true)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        props.setSITableNumberOfRows(+event.target.value)
        setPage(0)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }


    const handleSortDataRows = async (sortingEvent) => {
        const { active, over } = sortingEvent;

        if (!over || active.id === over.id) {
            return;
        }

        const indexOfDestinationRow = sortingEvent.over.data.current.sortable.index;
        const indexOfSourceRow = sortingEvent.active.data.current.sortable.index;
        const newDataRows = arrayMove(
            dataRows,
            indexOfSourceRow,
            indexOfDestinationRow,
        );
        setDataRows(newDataRows);

        const token = await getTokenSilently({
            audience: 'https://atlas.aesara.com',
        });
        const ids = newDataRows.map((rowData) => rowData.id);
        await callAPI(
            endpoints.reorderStrategicImperatives.url,
            endpoints.reorderStrategicImperatives.method,
            token,
            ids
        )
        setOrderBy('index');
        setForceRefresh(true);
    };


    function handlePanelOpen(id) {
        setStrategicImperativeForPanel(strategicImperativeData[id])
        setIsPanelOpen(true)
    }

    function handlePanelClose() {
        setStrategicImperativeForPanel({})
        setNavigationStack([])
        setIsPanelOpen(false)
    }

    const globalStyles = (
        <GlobalStyles
            styles={{
                '.MuiTableCell-root': {
                    borderBottom: 'none !important',
                },
            }}
        />
    )

    const sortedDataRows = stableSort(dataRows, getComparator(order, orderBy)).slice(page * itemsToDisplay, page * itemsToDisplay + itemsToDisplay)
    const isStrategicImperativeDataEmpty = !sortedDataRows?.length;

    return (
        <>
            {globalStyles}
            <Fade in={loaded} timeout={800}>
                <Grid container item xs>
                    {isStrategicImperativeDataEmpty && (
                        <EmptyPlaceholder
                            title={`No ${strategicDisplayName} to display`}
                            subtitle={`Click 'Add New' on the left panel to create a new ${strategicDisplayName}`}
                        />
                    )}
                    {!isStrategicImperativeDataEmpty && (
                        <>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <EnhancedTableHead
                                        columns={columns}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <SortableTable.Body data={sortedDataRows} onSort={handleSortDataRows}>
                                        {({rowData, index, rowProps}) => (
                                            <SortableTable.Row {...rowProps}>
                                                {columns.map((column) => {
                                                    const value = rowData[column.id]
                                                    if (column.id === 'index') {
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                sx={{
                                                                    border: 'none',
                                                                    borderTopLeftRadius: index === 0 ? 14 : 0, // Top left radius for the first rowData
                                                                    borderBottomLeftRadius:
                                                                        index ===
                                                                        dataRows.slice(
                                                                            page * itemsToDisplay,
                                                                            (page + 1) * itemsToDisplay
                                                                        ).length -
                                                                        1
                                                                            ? 14
                                                                            : 0, // Bottom left radius for the last rowData on the current page
                                                                }}>
                                                                <Typography
                                                                    variant="body9"
                                                                    style={{ color: '#808080' }}>
                                                                    {index + page * itemsToDisplay + 1}.
                                                                </Typography>
                                                            </TableCell>
                                                        )
                                                    } else if (column.id === 'panel') {
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                sx={{
                                                                    border: 'none',
                                                                    borderTopRightRadius: index === 0 ? 14 : 0,
                                                                    borderBottomRightRadius:
                                                                        index ===
                                                                        dataRows.slice(
                                                                            page * itemsToDisplay,
                                                                            (page + 1) * itemsToDisplay
                                                                        ).length -
                                                                        1
                                                                            ? 14
                                                                            : 0, // Bottom left radius for the last rowData on the current page
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => handlePanelOpen(rowData.id)}>
                                                                <IconButton sx={(theme) => ({ padding: theme.spacing(1.5), marginInline: theme.spacing(-0.75) })}>
                                                                    <ArrowForwardIosIcon color="primary" />
                                                                </IconButton>
                                                            </TableCell>
                                                        )
                                                    } else {
                                                        return (
                                                            <TableCell
                                                                hover
                                                                key={column.id}
                                                                align={column.align}
                                                                sx={{
                                                                    whiteSpace: 'break-spaces',
                                                                    borderBottomColor: 'red',
                                                                }}>
                                                                <Typography variant="body1">
                                                                    {column.format && typeof value === 'number'
                                                                        ? column.format(value)
                                                                        : value}
                                                                </Typography>
                                                            </TableCell>
                                                        )
                                                    }
                                                })}

                                            </SortableTable.Row>
                                        )}
                                    </SortableTable.Body>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={dataRows.length}
                                rowsPerPage={itemsToDisplay}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    )}
                    {isPanelOpen && (
                        <TablePanel
                            title={strategicImperativeForPanel?.name}
                            data={strategicImperativeForPanel}
                            type={'strategicImperative'}
                            closePanel={handlePanelClose}
                            openPanelAction={true}
                            updateParent={setUpdateTableFromPanel}
                            navigationStack={navigationStack}
                        />
                    )}
                </Grid>
            </Fade>
        </>
    )
}

const StrategicImperativeTable = connect(null, mapDispatchToProps)(ConnectedStrategicImperativeTable)

export default StrategicImperativeTable
