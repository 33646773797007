import React, { useEffect, useState } from 'react'
import '../../style/detailed-view.scss'
import { useTheme } from '@mui/styles'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import CreateLink from '../Buttons/CreateLink'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import Link from '@mui/material/Link'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Fade from '@mui/material/Fade'
import { useAuth0 } from '../../contexts/auth0-context'
import store from '../../redux/store'
import * as _ from 'underscore'
import endpoints from '../../API/endpoints'
import callAPI from '../../API/callSecureAPI'
import { useSelector } from 'react-redux'
import Alert from '@mui/material/Alert'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import Chip from '@mui/material/Chip'
import SvgIcon from '@mui/material/SvgIcon'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const strategicDisplayName = _.isEmpty(store.getState().settings)
    ? ''
    : store.getState().settings.displayNames.strategicImperatives

export default function StrategicImperativeDetailedView({
    strategicImperative,
    forceParentRefresh,
    closePanel,
    editing,
    setEditing,
    triggerSave,
    triggerCancel,
    triggerDelete,
    navigationStack,
}) {
    const snapshotMode = useSelector((state) => (state.strategy ? !!state.strategy.sourceId : false))
    const userReadonly = useSelector((state) => (state.userRecord ? !!state.userRecord.readonly : false))
    const isDarkMode = useSelector((state) => (state?.userRecord ? state?.userRecord?.isDarkMode : true))

    const theme = useTheme()
    const history = useHistory()
    const [isLoaded, setIsLoaded] = React.useState(false)
    const { isAuthenticated, getTokenSilently } = useAuth0()

    const [value, setValue] = useState(0)
    const [name, setName] = useState('')
    const [newName, setNewName] = useState('')

    const [forceRefresh, setForceRefresh] = useState(false)

    const [linkedActivities, setLinkedActivities] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    useEffect(() => {
        if (forceRefresh) {
            setForceRefresh(false)
        }
        if (!isAuthenticated || !strategicImperative) {
            return
        }
        getSIData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [strategicImperative, forceRefresh, isAuthenticated])

    function updateState(si) {
        setName(si.name)
        setNewName(si.name)
    }

    async function addLinkedActivities(checkedActivities) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        // Find the differences between the checked values and current values
        let toDelete = strategicImperative.linkedActivities.filter((x) => !checkedActivities.includes(x))
        let toAdd = checkedActivities.filter((x) => !strategicImperative.linkedActivities.includes(x))

        // Trigger appropriate linking function for each to enable two-way binding
        // for...of loops execute in sequence. Using 'await' prevents DB versioning  conflicts
        for (let activityId of toAdd) {
            await callAPI(
                endpoints.linkActivityToSI.url + strategicImperative._id + '/' + activityId,
                endpoints.linkActivityToSI.method,
                token
            )
        }
        for (let activityId of toDelete) {
            await callAPI(
                endpoints.deleteLinkActivityToSI.url + strategicImperative._id + '/' + activityId,
                endpoints.deleteLinkActivityToSI.method,
                token
            )
        }
        setForceRefresh(true)
        forceParentRefresh(true)
    }

    async function saveSI() {
        setEditing(!editing)

        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        let newSI = {
            name: newName.replace(/\n+/gm, ' '),
            strategyId: strategicImperative.strategyId,
            order: strategicImperative.order,
            //organizationId: evidence.organizationId,
            linkedActivities: strategicImperative.linkedActivities,
        }
        callAPI(
            endpoints.updateStrategicImperative.url + strategicImperative._id,
            endpoints.updateStrategicImperative.method,
            token,
            newSI
        )
        setName(newName)
        forceParentRefresh(true)
    }

    useEffect(() => {
        if (triggerSave === 0) {
            return
        }
        saveSI()
    }, [triggerSave])

    useEffect(() => {
        if (triggerCancel === 0) {
            return
        }
        handleCancel()
    }, [triggerCancel])

    useEffect(() => {
        if (triggerDelete === 0) {
            return
        }
        handleDeleteModalOpen()
    }, [triggerDelete])

    async function deleteThisSI() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        setOpenDeleteModal(false)
        await callAPI(
            endpoints.deleteStrategicImperative.url + strategicImperative._id,
            endpoints.deleteStrategicImperative.method,
            token
        )
        forceParentRefresh(true)
        closePanel()
    }

    async function getSIData() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        updateState(strategicImperative)
        setIsLoaded(true)

        let linkedActivityData = await callAPI(
            endpoints.getLinkedActivities.url,
            endpoints.getLinkedActivities.method,
            token,
            { listOfActivities: strategicImperative.linkedActivities }
        )
        setLinkedActivities(linkedActivityData.data)
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
    }

    const linkButtonTabIndexMapping = {
        0: 'activity',
    }

    function handleCancel() {
        setNewName(name)
        setEditing(false)
    }

    function handleDeleteModalClose() {
        setOpenDeleteModal(false)
    }

    function handleDeleteModalOpen() {
        setOpenDeleteModal(true)
    }

    function addToStack() {
        let arr = Array.from(navigationStack)
        arr.push({
            path: '/strategic-imperatives',
            data: strategicImperative,
        })
        return arr
    }

    return (
        <Fade in={isLoaded} timeout={800}>
            <Grid container item direction="column" sx={{ p: 0, backgroundColor: isDarkMode ? '#363943' : 'white' }}>
                <Dialog
                    open={openDeleteModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleDeleteModalClose}
                    aria-labelledby="alert-dialog-slide-title6"
                    aria-describedby="alert-dialog-slide-description6">
                    <DialogTitle id="alert-dialog-slide-title6">
                        <Typography variant="h4" sx={{ pt: 1 }}>
                            {`Are you sure you want to delete this ${strategicDisplayName}?`}
                        </Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button variant="outlined-sm-button" mb={1} onClick={handleDeleteModalClose}>
                            CANCEL
                        </Button>
                        <Button variant="contained-sm-button" sx={{ mb: 1, mr: 1 }} onClick={deleteThisSI}>
                            DELETE
                        </Button>
                    </DialogActions>
                </Dialog>

                {editing ? (
                    <Grid container direction='column'>
                        <Grid item sx={{ ml: 2, mb: -2, mt: 2 }}>
                            <Typography variant="title2"><strong>Title</strong></Typography>
                        </Grid> 
                        <Grid item variant="rounded-top-grid" sx={{ m: 2 }}>
                            <TextField
                                id="edit-strategicimperative"
                                multiline
                                rows={2}
                                variant="filled"
                                placeholder={name}
                                value={newName}
                                onFocus={(event) => {
                                    event.target.setAttribute('autocomplete', 'off')
                                }}
                                onChange={(e) => setNewName(e.target.value)}
                                fullWidth
                                sx={{
                                    '& .MuiInputBase-root': {
                                        borderTopLeftRadius: '16px',
                                        borderTopRightRadius: '16px',
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container item>
                        <Grid
                            container
                            item
                            direction="column"
                            sx={{
                                p: 3,
                                borderBottomLeftRadius: '24px',
                                borderBottomRightRadius: '24px',
                                backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6',
                                boxShadow: 0,
                            }}>
                            <Grid item>
                                <Typography variant="h5" sx={{ mb: 1 }}>Title:</Typography>
                            </Grid>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item xs={10}>
                                    <Typography variant="h2">{name}</Typography>
                                </Grid>
                                <Grid // Adding an invisible grid with chip to maintain similar appearance as the VMs and Activities pages
                                    item> 
                                    <Chip sx={{ backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6' }}
                                        icon={
                                            <SvgIcon
                                                inheritViewBox
                                                fontSize="inherit"
                                            />
                                        }
                                        label={<Typography variant="chip"></Typography>}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider variant="middle" />
                        <Grid container item direction="column" sx={{ p: 4, ml: -1 }}>
                            <Grid item sx={{ mb: 1.9, pl: 3, pt: 1 }}>
                                <Typography variant="h2">Linked data</Typography>
                            </Grid>
                            <Grid container item direction="center" sx={{ justifyContent: 'space-between' }}>
                                <Grid item sx={{ pl: 3 }}>
                                    <Tabs
                                        value={value}
                                        onChange={handleTabChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="full width tabs example">
                                        <Tab
                                            label={store.getState().settings?.displayNames?.activities || 'Loading'}
                                            {...a11yProps(1)}
                                        />
                                    </Tabs>
                                </Grid>
                                <Grid item sx={{ pt: 2.2, pr: 2 }}>
                                    {!snapshotMode && !userReadonly && (
                                        <CreateLink
                                            srcPage={'strategicimperative'}
                                            id={linkButtonTabIndexMapping[value]}
                                            listItems={linkedActivities}
                                            update={addLinkedActivities}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container item xs justifyContent="space-between" pt={3} sx={{ pl: 2 }}>
                                <TabPanel value={value} index={0} dir={theme.direction} style={{ width: '100%' }}>
                                    {linkedActivities && linkedActivities.length ? (
                                        linkedActivities.map((act, i) => (
                                            <Link
                                                component={RouterLink}
                                                underline="none"
                                                key={i}
                                                color="secondary"
                                                to={{
                                                    pathname: '/activities',
                                                    autoOpenData: {
                                                        isAutoOpenPanel: true,
                                                        autoOpenData: act,
                                                        previousDataStack: addToStack(),
                                                    },
                                                }}>
                                                <Grid
                                                    container
                                                    item
                                                    wrap="nowrap"
                                                    spacing={1}
                                                    justifyContent="space-between"
                                                    sx={{
                                                        backgroundColor: isDarkMode ? 'rgba(0, 0, 0, .3)' : '#F6F6F7',
                                                        mb: 3,
                                                        p: 2,
                                                        pb: 2, 
                                                        borderRadius: '16px',
                                                    }}>
                                                    <Grid container item spacing={1}>
                                                        <Grid item xs={0}>
                                                            <Typography variant="body1">{i + 1}.</Typography>
                                                        </Grid>
                                                        <Grid item xs zeroMinWidth sx={{ mr: 1, pb: 1 }}>
                                                            <Typography variant="body1">{act.title}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={0} sx={{ pr: 1 }}>
                                                        <ArrowForwardIosIcon fontSize="small" color="primary" />
                                                    </Grid>
                                                </Grid>
                                            </Link>
                                        ))
                                    ) : (
                                        <Alert
                                            icon={<LinkOffIcon fontSize="inherit" />}
                                            severity="info"
                                            sx={{ borderRadius: '12px', mt: -2, ml: -1 }}>
                                            {`No Linked ${
                                                store.getState().settings?.displayNames?.activities || 'Loading'
                                            }`}
                                        </Alert>
                                    )}
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Fade>
    )
}
