import React, { useEffect, useState } from 'react'
import StrategicImperativeTable from './StrategicImperativeTable'
import '../../style/Views.scss'
import Grid from '@mui/material/Grid'
import { ReactComponent as StrategicIcon } from '../../assets/images/Strategic_Icon.svg'
import LeftPanel from './../Support/PageLeftPanel'
import { useHistory } from 'react-router-dom'
import { isStrategySelected } from '../../utilities/helpers'
import * as _ from 'underscore'
import store from '../../redux/store'

export default function StrategicImperatives() {
    const history = useHistory()
    const [formData, setFormData] = useState({})

    function createNew(incomingData) {
        setFormData(incomingData)
    }

    // if no strategy selected, navigate to strategy selection page
    useEffect(() => {
        if (!isStrategySelected()) {
            history.push('/roadmaps')
        }
    })

    return (
        <Grid container item spacing={2} direction="column" sx={{ p: 2 }}>
            <LeftPanel
                imgsrc={StrategicIcon}
                text={
                    _.isEmpty(store.getState().settings)
                        ? ''
                        : store.getState().settings.displayNames.strategicImperatives
                }
                id={'strategicimperative'}
                createNew={createNew}
            />
            <StrategicImperativeTable newFormData={formData} />
        </Grid>
    )
}
