import React, { useEffect, useState, useRef } from 'react'
import { setStrategy, setSettings, setUserRecord } from '../../redux/actions'
import { connect, useSelector } from 'react-redux'
import store from '../../redux/store'
import { useAuth0 } from '../../contexts/auth0-context'
import _ from 'underscore'
import NewStrategy from './NewStrategy'
import { FreeTrialWidget } from '../FreeTrialWidget'
import './strategy-style.scss'
import { useHistory, Prompt } from 'react-router-dom'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import WelcomeImg from '../../assets/images/roadmap-selection.png'
import { BootstrapDialog } from '../Support/CustomElements'
import { ReactComponent as ArrowDownIcon } from '../../assets/images/arrow-down-1.svg'
import {
    Button,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Card,
    CardActions,
    CardContent,
    MenuItem,
    Paper,
    Typography,
    SvgIcon,
    IconButton,
} from '@mui/material/'
import { Fade } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import moment from 'moment'
import Box from '@mui/material/Box'

function mapDispatchToProps(dispatch) {
    return {
        setStrategy: (strat) => dispatch(setStrategy(strat)),
        setSettings: (settings) => dispatch(setSettings(settings)),
        setUserRecord: (userRecord) => dispatch(setUserRecord(userRecord)),
    }
}

function ConnectedStrategySelection(props) {
    const history = useHistory()
    const initialPathRef = useRef(window.location.pathname)
    const [navigate, setNavigate] = useState(false)

    const isDarkMode = useSelector((state) => (state?.userRecord ? state?.userRecord?.isDarkMode : true))

    const { user, isAuthenticated, getTokenSilently } = useAuth0()
    const [isLoaded, setIsLoaded] = useState(false)
    const [areInactiveRoadmapsVisible, setAreInactiveRoadmapsVisible] = useState(false)

    const [userName, setUserName] = useState('')
    const [forceRefresh, setForceRefresh] = useState(false)
    const [strategiesForOrganization, setStrategiesForOrganization] = useState([])
    const [organizationId, setOrganizationId] = useState('')
    const [openRoadmapAlert, setOpenRoadmapAlert] = useState(false)
    const [monthsRemainingFreeTrial, setMonthsRemainingFreeTrial] = useState(null)
    const [daysRemainingFreeTrial, setDaysRemainingFreeTrial] = useState(null)
    const [freeTrialPercentage, setFreeTrialPercentage] = useState(0)
    const [freeTrialEndDate, setFreeTrialEndDate] = useState(null)
    const isFreeTrial = useSelector((state) => state?.freeTrialStatus?.isFreeTrial)
    const freeTrialStartDate = useSelector((state) => state?.freeTrialStatus?.freeTrialStartDate)
    const selectedRoadmapId = useSelector((state) => state.strategy._id)

    async function newStrategy(product, indication) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        await callAPI(endpoints.createStrategy.url, endpoints.createStrategy.method, token, {
            keyMarkets: [
                {
                    phaseTitle: 'Phase 1',
                    phaseKeyMarkets: [{ code: 'US', label: 'United States', targetLaunchDate: '' }],
                },
            ],
            competitiveLandscape: [
                {
                    displayText: 'Market Timing',
                    value: '',
                    placeholder: 'e.g. first to market or fourth to market',
                },
                {
                    displayText: 'Class Crowdedness',
                    value: '',
                    placeholder: 'e.g. first to class or crowded class',
                },
                {
                    displayText: 'Competitor(s) in Development',
                    value: '',
                    placeholder: '[launch date if known and developer]',
                },
                {
                    value: '',
                    placeholder: '[brand & manufacturer; date launched]',
                    displayText: 'Approved Competitor(s)',
                },
                {
                    value: '',
                    placeholder: '[brand & manufacturer; loss of patent date if known]',
                    displayText: 'Branded SoC',
                },
                {
                    value: '',
                    placeholder: '[generic name]',
                    displayText: 'Generic SoC',
                },
                {
                    value: '',
                    placeholder: '[brand & manufacturer]',
                    displayText: 'Approved Biosimilar(s) to SoC',
                },
                {
                    value: '',
                    placeholder: '[launch date if known and developer]',
                    displayText: 'Biosimilar(s) to SoC in Development',
                },
                {
                    value: '',
                    placeholder: 'clinical practice guidelines',
                    displayText: 'Clinical Practice Guideline(s)',
                },
            ],
            strategicImperatives: [],
            positioningStatement: '',
            product: product,
            indication: indication,
            TPPMimeType: '',
            TPPOriginalFileName: '',
            targetLaunchDate: '',
            TPPS3Name: '',
            gapLevers: [
                {
                    leverName: 'Comparators',
                    parameters: ['Generic'],
                },
                {
                    leverName: 'Endpoints',
                    parameters: ['Patient'],
                },
                {
                    leverName: 'Study design',
                    parameters: ['Planning'],
                },
            ],
            TPP: [
                {
                    displayText: 'Description',
                    value: '',
                },
                {
                    displayText: 'Indications & Usage',
                    value: '',
                },
                {
                    displayText: 'Clinical Pharmacology',
                    value: '',
                },
                {
                    displayText: 'Efficacy',
                    value: '',
                },
                {
                    displayText: 'Safety & Tolerability',
                    value: '',
                },
                {
                    displayText: 'Dosing & Administration',
                    value: '',
                },
                {
                    displayText: 'Dosing Forms & Strengths',
                    value: '',
                },
                {
                    displayText: 'Contraindications',
                    value: '',
                },
                {
                    displayText: 'Warnings & Precautions',
                    value: '',
                },
                {
                    displayText: 'Drug Interactions',
                    value: '',
                },
                {
                    displayText: 'Special Populations',
                    value: '',
                },
                {
                    displayText: 'Product Storage',
                    value: '',
                },
                {
                    displayText: 'Packaging',
                    value: '',
                },
                {
                    displayText: 'Commercial',
                    value: '',
                },
                {
                    displayText: 'Other',
                    value: '',
                },
            ],
            criticalSuccessFactors: [],
            valueStatements: {
                'Unmet Need': '',
                Clinical: '',
                Economic: '',
                Humanistic: '',
            },
            organizationId: organizationId,
            optionalFifthBoxDashboard: [],
            TPPFiles: [],
            competitiveLandscapeFiles: [],
            strategicImperativesFiles: [],
            optionalFifthBoxFiles: [],
            criticalSuccessFactorsFiles: [],
        })
        setForceRefresh(true)
    }

    const calculateFreeTrialWidgetData = () => {
            if (isFreeTrial) {
                let start = moment(freeTrialStartDate)
                let sixMonthsFromStart = moment(freeTrialStartDate)
                sixMonthsFromStart = sixMonthsFromStart.months(3 + sixMonthsFromStart.months())
                let now = moment()

                let diffNowToSixMonths = moment.duration(sixMonthsFromStart.diff(now))
                setMonthsRemainingFreeTrial(diffNowToSixMonths._data.months)
                diffNowToSixMonths._data.months === 3
                    ? setDaysRemainingFreeTrial(0)
                    : setDaysRemainingFreeTrial(diffNowToSixMonths._data.days)

                var totalMillisInRange = sixMonthsFromStart.valueOf() - start.valueOf()
                var elapsedMillis = now.valueOf() - start.valueOf()
                let percentOfTrialElapsed = Math.max(0, Math.min(100, 100 * (elapsedMillis / totalMillisInRange)))

                setFreeTrialPercentage(percentOfTrialElapsed)
                setFreeTrialEndDate(sixMonthsFromStart.toISOString())
            }
            else {
                setFreeTrialPercentage(0);
                setFreeTrialEndDate(null);
            }
    }

    useEffect(() => {
        if (!store.getState().strategy) {
            props.setStrategy({
                keyMarkets: [],
                competitiveLandscape: [],
                strategicImperatives: [],
                _id: '',
                positioningStatement: '',
                product: '',
                indication: '',
                gapLevers: [],
                TPPMimeType: '',
                TPPOriginalFileName: '',
                targetLaunchDate: '',
                TPPS3Name: '',
                organizationId: '',
                TPP: [],
                optionalFifthBoxDashboard: [],
                criticalSuccessFactors: [],
                valueStatements: {
                    'Unmet Need': '',
                    Clinical: '',
                    Economic: '',
                    Humanistic: '',
                },
                TPPFiles: [],
                competitiveLandscapeFiles: [],
                strategicImperativesFiles: [],
                optionalFifthBoxFiles: [],
                criticalSuccessFactorsFiles: [],
            })
        }

        if (!_.isEmpty(user)) {
            getUser()
        } else {
            console.log('no user yet')
        }

        setUserName(store.getState().userRecord.name ? store.getState().userRecord.name.split(' ')[0] : 'User')

        async function getUser() {
            let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
            let parsedUserId = user.sub.split('|')
            if (parsedUserId.length >= 2) {
                let userId = parsedUserId.slice(1).join('|')
                let userData = await callAPI(
                    endpoints.getUserAuth0.url + userId + '/auth0',
                    endpoints.getUserAuth0.method,
                    token
                )
                if (userData?.data?.organizationId) {
                    setOrganizationId(userData?.data?.organizationId)
                    getStrategies(userData?.data?.organizationId)
                    getSettings(userData?.data?.organizationId)
                    props.setUserRecord(userData?.data)
                }
            }
        }
    }, [props, user, isAuthenticated, forceRefresh, freeTrialEndDate])

    useEffect(() => {
        if (navigate) {
            history.push('/')
            setNavigate(false)
        }
    }, [navigate, history])

    useEffect(() => {
        calculateFreeTrialWidgetData();
    }, [isFreeTrial, freeTrialStartDate])

    async function getStrategies(userOrganization) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let strategies = await callAPI(
            endpoints.getStrategiesByOrganizationId.url + userOrganization + '/strategies',
            endpoints.getStrategiesByOrganizationId.method,
            token
        )

        setStrategiesForOrganization(strategies.data.sort((x, y) => x.order - y.order))
        setIsLoaded(true)
    }

    async function getSettings(userOrganization) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let settings = await callAPI(
            endpoints.getSettingsByOrganizationId.url + userOrganization,
            endpoints.getSettingsByOrganizationId.method,
            token
        )
        if (settings.success) {
            props.setSettings(settings.data)
        }
        setIsLoaded(true)
    }

    const roadmapSelection = (strat) => {
        props.setStrategy(strat)
        setNavigate(true)
        history.push('/')
    }

    const handleNavigation = (location) => {
        if (navigate || location.pathname === '/help' || selectedRoadmapId) {
            return true
        }
        if (location.pathname !== initialPathRef.current) {
            setOpenRoadmapAlert(true)
            return false
        }
        return true
    }

    const activeRoadmaps = strategiesForOrganization.filter(strategy => strategy.isActive !== false);
    const inactiveRoadmaps = strategiesForOrganization.filter(strategy => strategy.isActive === false);

    return (
        <Fade in={isLoaded} timeout={801}>
            <Grid container direction="column">
                <Grid container item gap={3} justifyContent='space-between'>
                    <Grid item xs>
                        <Paper
                            elevation={0}
                            sx={{
                                backgroundImage: `url(${WelcomeImg})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center 35%',
                                minHeight: '300px',
                                display: 'flex',
                                borderRadius: '24px',
                                borderBottomLeftRadius: isFreeTrial ? '24px' : 0,
                                borderBottomRightRadius: isFreeTrial ? '24px' : 0,
                            }}>
                            <Typography variant="h1" color="figma.accentColors.white" pl={5} pt={5}>
                                Welcome to your roadmap, {userName}
                            </Typography>
                        </Paper>
                    </Grid>
                    {isFreeTrial && (
                        <FreeTrialWidget
                            months={monthsRemainingFreeTrial}
                            days={daysRemainingFreeTrial}
                            percentage={freeTrialPercentage}
                            endDate={freeTrialEndDate}
                        />
                    )}
                </Grid>
                <Grid container item sx={{ p: 2 }} spacing={2}>
                    <Grid container item direction="column" spacing={1}>
                        <Grid item>
                            <Typography variant="h2">Select a roadmap, or create a new one:</Typography>
                        </Grid>
                        <NewStrategy createNewStrategy={newStrategy} />
                    </Grid>
                    <Grid container item alignItems="stretch" spacing={2}>
                        {activeRoadmaps?.map((strat, i) => {
                                return (
                                    <Grid item key={strat._id + i}>
                                        <Card
                                            sx={{
                                                height: '100%',
                                                width: 350,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                borderRadius: 3,
                                                boxShadow: 4,
                                                backgroundColor:
                                                    selectedRoadmapId === strat._id ? 'rgba(56, 155, 255, .05)' : '',
                                                border: selectedRoadmapId === strat._id ? '#0049EA solid 1px' : '',
                                            }}>
                                            <CardContent sx={{ flexGrow: 1 }}>
                                                <Grid container wrap="nowrap" spacing={3}>
                                                    <Grid container item direction="column">
                                                        <Grid item>
                                                            <Typography gutterBottom variant="title2" component="div">
                                                                Drug
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h1" color="primary">
                                                                {strat.product}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item direction="column">
                                                        <Grid item>
                                                            <Typography gutterBottom variant="title2" component="div">
                                                                Indication
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body1">{strat.indication}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                            <CardActions
                                                disableSpacing
                                                sx={{
                                                    marginTop: 'auto',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                }}>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => roadmapSelection(strat)}
                                                    variant="contained">
                                                    <ArrowForwardIosIcon color="primary" />
                                                </IconButton>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                )
                            })}
                    </Grid>
                    {inactiveRoadmaps.length > 0 && (
                        <>
                            <Box
                              onClick={() => setAreInactiveRoadmapsVisible(!areInactiveRoadmapsVisible)}
                              sx={{
                                p: 2,
                                pt: 4,
                                cursor: 'pointer'
                              }}
                            >
                                <Typography variant="body2" color="figma.grayscale.tier4" sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}>
                                    Inactive Roadmaps
                                    <SvgIcon component={ArrowDownIcon} inheritViewBox color="inherit" sx={{ fontSize: '16px', transform: areInactiveRoadmapsVisible ? 'rotate(180deg)' : '' }} />
                                </Typography>
                            </Box>
                            <Fade in={areInactiveRoadmapsVisible} mountOnEnter unmountOnExit>
                                <Grid container item alignItems="stretch" spacing={2}>
                                    {inactiveRoadmaps?.map((strat, i) => {
                                        return (
                                            <Grid item key={strat._id + i}>
                                                <Card
                                                    sx={{
                                                        height: '100%',
                                                        width: 350,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: 3,
                                                        boxShadow: 4,
                                                    }}>
                                                    <CardContent sx={{ flexGrow: 1 }}>
                                                        <Grid container wrap="nowrap" spacing={3}>
                                                            <Grid container item direction="column">
                                                                <Grid item>
                                                                    <Typography gutterBottom variant="title2" color="figma.grayscale.tier5" component="div">
                                                                        Drug
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="h1" color="figma.grayscale.tier5">
                                                                        {strat.product}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item direction="column">
                                                                <Grid item>
                                                                    <Typography gutterBottom variant="title2" color="figma.grayscale.tier5" component="div">
                                                                        Indication
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="body1" color="figma.grayscale.tier5">{strat.indication}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions
                                                        disableSpacing
                                                        sx={{
                                                            marginTop: 'auto',
                                                            display: 'flex',
                                                            justifyContent: 'flex-end',
                                                        }}>
                                                        <IconButton
                                                            size="small"
                                                            disabled
                                                            variant="contained"
                                                            sx={{ visibility: 'hidden' }}>
                                                            <ArrowForwardIosIcon />
                                                        </IconButton>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Fade>
                        </>
                    )}
                </Grid>
                <Prompt when={true} message={handleNavigation} />

                <BootstrapDialog
                    isDarkMode={isDarkMode}
                    open={openRoadmapAlert}
                    onClose={() => setOpenRoadmapAlert(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogContent sx={{ height: '100%', alignItems: 'center', display: 'flex' }}>
                        <DialogContentText id="alert-dialog-description" sx={{ height: '100%' }}>
                            <Grid container xs={6} justifyContent="center">
                                <Typography variant="h2">
                                    Please select a roadmap before navigating to other pages.
                                </Typography>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenRoadmapAlert(false)} variant="contained" color="primary">
                            Return
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </Grid>
        </Fade>
    )
}

const StrategySelection = connect(null, mapDispatchToProps)(ConnectedStrategySelection)

export default StrategySelection
