export default function callSecureAPI(url, requestType, token, body, options = {}) {
    let requestOb = {
        method: requestType,
        headers: { Authorization: `Bearer ${token}`, 'Content-type': 'application/json' },
    }

    if (body) {
        requestOb.body = JSON.stringify(body)
    }

    requestOb = { ...requestOb, ...options }

    return fetch(url, requestOb).then((res) => res.json())
}
