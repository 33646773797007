import { useInfoBannerStyles } from './InfoBanner.styles'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import React from 'react'
import { useIsDarkMode } from '../../hooks/useIsDarkMode'

type Props = {
    texts: string[]
}

export const InfoBanner = ({ texts }: Props) => {
    const isDarkMode = useIsDarkMode()
    const styles = useInfoBannerStyles({ isDarkMode })

    return (
        <Box className={styles.container}>
            {texts.map((text) => (
                <Typography key={text} className={styles.title}>
                    {text}
                </Typography>
            ))}
        </Box>
    )
}
