import React, { useEffect, useState } from 'react'
import './settings.scss'
import { styled } from '@mui/material/styles'
import { ReactComponent as GapIcon } from '../../assets/images/Gap_Icon.svg'
import { ReactComponent as EvidenceIcon } from '../../assets/images/Ev_Icon.svg'
import { ReactComponent as ActivityIcon } from '../../assets/images/Act_Icon.svg'
import { ReactComponent as VMIcon } from '../../assets/images/VM_Icon.svg'
import { ReactComponent as SettingsIcon } from '../../assets/images/settings-gear.svg'
import Fade from '@mui/material/Fade'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { Accordion, AccordionDetails, AccordionSummary, useStyles } from './settings-style'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { isStrategySelected } from '../../utilities/helpers'
import { useHistory } from 'react-router-dom'
import store from '../../redux/store'
import endpoints from '../../API/endpoints'
import callAPI from '../../API/callSecureAPI'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material//Alert'
import { useAuth0 } from '../../contexts/auth0-context'
import _ from 'underscore'
import callSecureAPI from '../../API/callSecureAPI'
import { setSettings, setStrategy, setUserRecord } from '../../redux/actions'
import { connect, useSelector } from 'react-redux'
import { SvgIcon } from '@mui/material'
import LeftPanel from './../Support/PageLeftPanel'


function mapDispatchToProps(dispatch) {
    return {
        setStrategy: (strat) => dispatch(setStrategy(strat)),
        setUserRecord: (userRecord) => dispatch(setUserRecord(userRecord)),
    }
}

function ConnectedSettings(props) {
    const classes = useStyles()
    const history = useHistory()
    const { getTokenSilently, isAuthenticated } = useAuth0()

    const isDarkMode = useSelector((state) => (state?.userRecord ? state?.userRecord?.isDarkMode : true))
    const strat = useSelector((state) => state.strategy)

    const [isLoaded, setIsLoaded] = useState(true)
    const [settings, setSettings] = useState({})
    const [forceRefresh, setForceRefresh] = useState(false)

    const [tempStrat, setTempStrat] = useState(strat)
    const [activityFunctions, setActivityFunctions] = useState([])
    const [vmCategories, setVmCategories] = useState([])
    const [vmAudiences, setVmAudiences] = useState([])
    const [activityCategories, setActivityCategories] = useState([])
    const [activityIntendedUses, setActivityIntendedUses] = useState([])
    const [evidenceCategories, setEvidenceCategories] = useState([])
    const [gapLevers, setGapLevers] = useState([])
    const [leverBuckets, setLeverBuckets] = useState([])
    const [reimbursableFiles, setReimbursableFiles] = useState([])

    const [expanded, setExpanded] = useState('')

    const [openAlert, setOpenAlert] = useState(false)
    const [severity, setSeverity] = useState('success')
    const [gapAnalysisVisible, setGapAnalysisVisible] = useState(true)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpenAlert(false)
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    const errorMessages = {
        success: 'Successfully saved Settings.',
        error: 'Error - unable to save your Settings.',
    }

    async function saveSettings() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let newSettings = {
            vmCategories: vmCategories,
            vmAudiences: vmAudiences,
            activityCategories: activityCategories,
            activityIntendedUses: activityIntendedUses,
            activityFunctions: activityFunctions,
            gapLevers: gapLevers,
            organizationId: settings.organizationId,
            evidenceCategories: evidenceCategories,
            enableLocalizedMarkets: settings.enableLocalizedMarkets,
            gapReimbursableFiles: reimbursableFiles,
            vmCategoryDisplayNames: settings.vmCategoryDisplayNames,
            displayNames: settings.displayNames,
        }

        let response = await callAPI(
            endpoints.saveSettings.url + settings._id + '/' + store.getState().strategy._id,
            endpoints.saveSettings.method,
            token,
            newSettings
        )

        let strategy = await callAPI(
            endpoints.getStrategy.url + store.getState().strategy._id,
            endpoints.getStrategy.method,
            token
        )
        if (!strategy.success) {
            return
        }
        props.setStrategy(strategy.data)
        setTempStrat(strategy.data)
        setForceRefresh(true)
    }

    const updateParameters = (index, parameters, leverName) => {
        const newGapLevers = [...gapLevers]
        newGapLevers.splice(index, 1, { leverName: leverName, parameters: parameters })
        setGapLevers(newGapLevers)
    }

    const handleLeverBuckets = (event, updatedLeverBuckets) => {
        let newGapLevers = [...gapLevers]

        if (updatedLeverBuckets.length < leverBuckets.length) {
            // find the lever and delete it
            for (let i = 0; i < leverBuckets.length; i++) {
                let index = updatedLeverBuckets.indexOf(leverBuckets[i])
                if (index < 0) {
                    // we found the leverName that we need to delete from gapLevers
                    let leverToDelete = leverBuckets[i]
                    newGapLevers = newGapLevers.filter((lever) => lever.leverName !== leverToDelete)
                }
            }
        } else if (updatedLeverBuckets.length > leverBuckets.length) {
            // add lever to list
            for (let i = 0; i < updatedLeverBuckets.length; i++) {
                let index = leverBuckets.indexOf(updatedLeverBuckets[i])
                if (index < 0) {
                    let leverToAdd = updatedLeverBuckets[i]
                    newGapLevers.push({ leverName: leverToAdd, parameters: [] })
                }
            }
        }
        setLeverBuckets(updatedLeverBuckets)
        setGapLevers(newGapLevers)
    }

    useEffect(() => {
        if (forceRefresh) {
            setForceRefresh(false)
        }

        getSettingsData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceRefresh])

    useEffect(() => {
        isAuthenticated && initGapAnalysisVisible()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, gapAnalysisVisible])

    async function initGapAnalysisVisible() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let org = (
            await callSecureAPI(
                endpoints.getOrganizationById.url + store.getState().strategy.organizationId,
                endpoints.getOrganizationById.method,
                token
            )
        ).data
        if (org && org.hideMenuItem && org.hideMenuItem['Gap Analyses']) {
            setGapAnalysisVisible(false)
        }
    }

    async function getSettingsData() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        let strategy = await callAPI(
            endpoints.getStrategy.url + store.getState().strategy._id,
            endpoints.getStrategy.method,
            token
        )
        if (!strategy.success) {
            return
        }
        props.setStrategy(strategy.data)
        setTempStrat(strategy.data)

        let settings = await callAPI(
            endpoints.getSettingsByOrganizationId.url + store.getState().strategy.organizationId,
            endpoints.getSettingsByOrganizationId.method,
            token
        )
        if (!settings.success) {
            return
        }
        setSettings(settings.data)
        setActivityFunctions(settings.data.activityFunctions)
        setVmCategories(settings.data.vmCategories)
        setVmAudiences(settings.data.vmAudiences)
        setActivityCategories(settings.data.activityCategories)
        setActivityIntendedUses(settings.data.activityIntendedUses)
        setEvidenceCategories(settings.data.evidenceCategories)
        setGapLevers(tempStrat.gapLevers)
        setLeverBuckets(tempStrat.gapLevers.map((lever) => lever.leverName))
        setReimbursableFiles(settings.data.gapReimbursableFiles)
        setIsLoaded(true)
        console.log('firing')
    }

    useEffect(() => {
        if (!isStrategySelected()) {
            history.push('/roadmaps')
        }
    })

    // function handleActivityCategories(event, value) {
    //     // Means we are deleting a value, and must go through DB to update fields
    //     // which had this value
    //     if (value.length < activityCategories.length) {
    //         let difference = activityCategories.filter((x) => !value.includes(x))
    //         console.log(difference)
    //     }
    //     setActivityCategories(value)
    // }

    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 62,
        height: 34,
        padding: 7,
        '& .MuiSwitch-switchBase': {
            margin: 1,
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
                color: '#fff',
                transform: 'translateX(22px)',
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20'><path fill='${encodeURIComponent(
                        '#fff'
                    )}' d='M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z'/></svg>')`,
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
            width: 32,
            height: 32,
            '&:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20'><path fill='${encodeURIComponent(
                    '#fff'
                )}' d='M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z'/></svg>')`,
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            borderRadius: 20 / 2,
        },
    }))

    async function handleColorModeChange() {
        // Hit the function we set up to update DB, and then refresh with new value

        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let updatedUserRecord = await callAPI(
            endpoints.changeUserColorMode.url + store.getState().userRecord._id + '/' + 'colorMode',
            endpoints.changeUserColorMode.method,
            token,
            { isDarkMode: !store.getState().userRecord.isDarkMode }
        )
        // set local storage with new user record
        if (updatedUserRecord.success) {
            props.setUserRecord(updatedUserRecord.user)
            setForceRefresh(true)
        }
    }

    return (
        <Fade in={isLoaded} timeout={800}>
            <Grid container item direction="column" spacing={2} xs={9} sx={{ p: 5 }} justifyContent="center">
                <Grid container item justifyContent="center">
                    <Grid container item>
                        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                expandIcon={<ExpandMoreIcon />}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <SvgIcon component={VMIcon} alt="value messages icon" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h4">
                                            {store.getState().settings?.displayNames?.valueMessages || 'Loading'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Autocomplete
                                    multiple
                                    id="tags-filled-2"
                                    options={vmAudiences || []}
                                    value={vmAudiences || []}
                                    onChange={(e, v) => setVmAudiences(v)}
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                sx={{ m: 1 }}
                                                label={option}
                                                key={index}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="filled"
                                            label="Audiences"
                                            onFocus={(event) => {
                                                event.target.setAttribute('autocomplete', 'off')
                                            }}
                                            sx={{
                                                '& .MuiAutocomplete-tag': { // Targets the chips
                                                    margin: 0.5, // Adds margin around each chip, you can increase the top margin as needed
                                                },
                                                // Adjust the vertical spacing of the chips container itself
                                                '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
                                                    paddingTop: 2.5, // Adds space on top of the container for chips
                                                    '& .MuiAutocomplete-input': {
                                                        paddingTop: 2, // Adds additional space on top of the chips
                                                        paddingBottom: 0.5, // Ensures the chips are vertically centered within the container
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid container item>
                        <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                aria-controls="panel2d-content"
                                id="panel2d-header"
                                expandIcon={<ExpandMoreIcon />}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <SvgIcon component={ActivityIcon} alt="activity icon" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h4">
                                            {store.getState().settings?.displayNames?.activities || 'Loading'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Autocomplete
                                    multiple
                                    id="tags-filled-3"
                                    options={activityCategories || []}
                                    value={activityCategories || []}
                                    onChange={(e, v) => setActivityCategories(v)}
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip label={option} key={index} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="filled"
                                            label="Category"
                                            onFocus={(event) => {
                                                event.target.setAttribute('autocomplete', 'off')
                                            }}
                                            sx={{
                                                '& .MuiAutocomplete-tag': { // Targets the chips
                                                    margin: 0.5, // Adds margin around each chip, you can increase the top margin as needed
                                                },
                                                // Adjust the vertical spacing of the chips container itself
                                                '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
                                                    paddingTop: 2.5, // Adds space on top of the container for chips
                                                    '& .MuiAutocomplete-input': {
                                                        paddingTop: 2, // Adds additional space on top of the chips
                                                        paddingBottom: 0.5, // Ensures the chips are vertically centered within the container
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                                <Autocomplete
                                    multiple
                                    id="tags-filled-4"
                                    options={activityIntendedUses || []}
                                    value={activityIntendedUses || []}
                                    onChange={(e, v) => setActivityIntendedUses(v)}
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip label={option} key={index} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="filled"
                                            label="Intended Use"
                                            onFocus={(event) => {
                                                event.target.setAttribute('autocomplete', 'off')
                                            }}
                                            sx={{
                                                '& .MuiAutocomplete-tag': { // Targets the chips
                                                    margin: 0.5, // Adds margin around each chip, you can increase the top margin as needed
                                                },
                                                // Adjust the vertical spacing of the chips container itself
                                                '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
                                                    paddingTop: 2.5, // Adds space on top of the container for chips
                                                    '& .MuiAutocomplete-input': {
                                                        paddingTop: 2, // Adds additional space on top of the chips
                                                        paddingBottom: 0.5, // Ensures the chips are vertically centered within the container
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                                <Autocomplete
                                    multiple
                                    id="tags-filled-5"
                                    options={activityFunctions || []}
                                    value={activityFunctions || []}
                                    onChange={(e, v) => setActivityFunctions(v)}
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip label={option} key={index} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="filled"
                                            label="Function"
                                            onFocus={(event) => {
                                                event.target.setAttribute('autocomplete', 'off')
                                            }}
                                            sx={{
                                                '& .MuiAutocomplete-tag': { // Targets the chips
                                                    margin: 0.5, // Adds margin around each chip, you can increase the top margin as needed
                                                },
                                                // Adjust the vertical spacing of the chips container itself
                                                '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
                                                    paddingTop: 2.5, // Adds space on top of the container for chips
                                                    '& .MuiAutocomplete-input': {
                                                        paddingTop: 2, // Adds additional space on top of the chips
                                                        paddingBottom: 0.5, // Ensures the chips are vertically centered within the container
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid container item>
                        <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                aria-controls="panel3d-content"
                                id="panel3d-header"
                                expandIcon={<ExpandMoreIcon />}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <SvgIcon component={EvidenceIcon} alt="evidence icon" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h4">Evidence</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Autocomplete
                                    multiple
                                    id="tags-filled-1"
                                    options={evidenceCategories || []}
                                    value={evidenceCategories || []}
                                    onChange={(e, v) => setEvidenceCategories(v)}
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip label={option} key={index} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="filled"
                                            label="Category"
                                            onFocus={(event) => {
                                                event.target.setAttribute('autocomplete', 'off')
                                            }}
                                            sx={{
                                                '& .MuiAutocomplete-tag': { // Targets the chips
                                                    margin: 0.5, // Adds margin around each chip, you can increase the top margin as needed
                                                },
                                                // Adjust the vertical spacing of the chips container itself
                                                '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
                                                    paddingTop: 2.5, // Adds space on top of the container for chips
                                                    '& .MuiAutocomplete-input': {
                                                        paddingTop: 2, // Adds additional space on top of the chips
                                                        paddingBottom: 0.5, // Ensures the chips are vertically centered within the container
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid container item>
                        {gapAnalysisVisible && (
                            <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary
                                    aria-controls="panel4d-content"
                                    id="panel4d-header"
                                    expandIcon={<ExpandMoreIcon />}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <SvgIcon component={GapIcon} alt="gap analysis icon" />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">Gap Analysis</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Autocomplete
                                        multiple
                                        id="tags-filled-6"
                                        options={leverBuckets || []}
                                        value={leverBuckets || []}
                                        onChange={(e, v) => handleLeverBuckets(e, v)}
                                        freeSolo
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip label={option} key={index} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                label="Levers"
                                                onFocus={(event) => {
                                                    event.target.setAttribute('autocomplete', 'off')
                                                }}
                                                sx={{
                                                    '& .MuiAutocomplete-tag': { // Targets the chips
                                                        margin: 0.5, // Adds margin around each chip, you can increase the top margin as needed
                                                    },
                                                    // Adjust the vertical spacing of the chips container itself
                                                    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
                                                        paddingTop: 2.5, // Adds space on top of the container for chips
                                                        '& .MuiAutocomplete-input': {
                                                            paddingTop: 2, // Adds additional space on top of the chips
                                                            paddingBottom: 0.5, // Ensures the chips are vertically centered within the container
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                    <div>
                                        {gapLevers.map((lever, index) => (
                                            <Autocomplete
                                                multiple
                                                key={lever.leverName}
                                                id={`tags-filled-${lever.leverName}`}
                                                options={lever.parameters || []}
                                                value={lever.parameters || []}
                                                onChange={(e, v) => updateParameters(index, v, lever.leverName)}
                                                freeSolo
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip label={option} key={index} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="filled"
                                                        label={lever.leverName}
                                                        onFocus={(event) => {
                                                            event.target.setAttribute('autocomplete', 'off')
                                                        }}
                                                        sx={{
                                                            '& .MuiAutocomplete-tag': { // Targets the chips
                                                                margin: 0.5, // Adds margin around each chip, you can increase the top margin as needed
                                                            },
                                                            // Adjust the vertical spacing of the chips container itself
                                                            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
                                                                paddingTop: 2.5, // Adds space on top of the container for chips
                                                                '& .MuiAutocomplete-input': {
                                                                    paddingTop: 2, // Adds additional space on top of the chips
                                                                    paddingBottom: 0.5, // Ensures the chips are vertically centered within the container
                                                                },
                                                            },
                                                            '& .MuiInputLabel-filled': { // Target the label of the TextField
                                                                paddingLeft: 2, // Add indentation here, adjust as needed
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        ))}
                                    </div>
                                    <Autocomplete
                                        multiple
                                        id="tags-filled-2"
                                        options={reimbursableFiles || []}
                                        value={reimbursableFiles || []}
                                        onChange={(e, v) => setReimbursableFiles(v)}
                                        freeSolo
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip label={option} key={index} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                label="Reimbursable Elements"
                                                onFocus={(event) => {
                                                    event.target.setAttribute('autocomplete', 'off')
                                                }}
                                                sx={{
                                                    '& .MuiAutocomplete-tag': { // Targets the chips
                                                        margin: 0.5, // Adds margin around each chip, you can increase the top margin as needed
                                                    },
                                                    // Adjust the vertical spacing of the chips container itself
                                                    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
                                                        paddingTop: 3, // Adds space on top of the container for chips
                                                        '& .MuiAutocomplete-input': {
                                                            paddingTop: 2, // Adds additional space on top of the chips
                                                            paddingBottom: 0.5, // Ensures the chips are vertically centered within the container
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Grid>
                    <Grid container item>
                        <Accordion square expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                expandIcon={<ExpandMoreIcon />}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <AccountCircleIcon />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h4">User Settings</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="subtitle2">Light vs Dark Mode</Typography>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>Light</Typography>
                                    <FormControlLabel
                                        control={
                                            <MaterialUISwitch
                                                style={{ m: 1 }}
                                                color="primary"
                                                checked={isDarkMode === undefined ? true : isDarkMode}
                                                onChange={handleColorModeChange}
                                            />
                                        }
                                    />
                                    <Typography>Dark</Typography>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={severity}>
                            {errorMessages[severity]}
                        </Alert>
                    </Snackbar>
                </Grid>
                <Grid container item justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Button variant="outlined" size="medium" color="primary" onClick={() => setForceRefresh(true)}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" size="medium" color="primary" onClick={() => saveSettings()}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Fade>
    )
}

const Settings = connect(null, mapDispatchToProps)(ConnectedSettings)
export default Settings
