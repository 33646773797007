import React from 'react'
import { EmptyPlaceholderTwoLineContentProps } from '../EmptyPlaceholder.types'
import { Typography } from '@mui/material'
import { EmptyPlaceholderOneLineContent } from './EmptyPlaceholderOneLineContent'

export const EmptyPlaceholderTwoLineContent = ({ title, subtitle }: EmptyPlaceholderTwoLineContentProps) => {
    return (
        <>
            <Typography
                variant="h2"
            >
                {title}
            </Typography>
            <EmptyPlaceholderOneLineContent text={subtitle}/>
        </>
    )
}
