export const getAuthorInitials = (author: string): string => {
    const parts = author.trim().split(' ')
    if (parts.length === 0) return ''

    const firstNameInitial = parts[0][0]
    const lastNameInitial = parts.length > 1 ? parts[1][0] : ''

    return `${firstNameInitial}${lastNameInitial}`
}

export const getAuthorAvatarColor = (author: string, isDarkMode: boolean): string => {
    const hashCode = (str: string) => {
        let hash = 0
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash)
        }
        return hash
    }

    const trimmedAuthor = author.trim()
    const hash = hashCode(trimmedAuthor)
    const baseHue = (Math.abs(hash) + trimmedAuthor.length * 10) % 360

    const shiftedHue = (baseHue + 140) % 360

    const saturation = 50
    const lightness = isDarkMode ? 40 : 55

    return `hsl(${shiftedHue}, ${saturation}%, ${lightness}%)`
}

export const formatCommentCreationDate = (createdAt: string): string => {
    const date = new Date(createdAt);

    const options = {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };

    return date.toLocaleString('en-US', options)
}
