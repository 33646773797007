import { SortableTableRowProps } from './SortableTableRow'
import { useSortable } from '@dnd-kit/sortable'
import { useIsDarkMode } from '../../../hooks/useIsDarkMode'
import { CSSProperties } from 'react'
import clsx from 'clsx'
import { useSortableTableRowStyles } from './SortableTableRow.styles'

export const useSortableTableRow = ({ id, disabled, ...tableRowProps }: SortableTableRowProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, index, activeIndex } = useSortable({ id })

    const isDarkMode = useIsDarkMode()
    const styles = useSortableTableRowStyles({ isDarkMode })

    const className: string = clsx(styles.container, {
        [styles.disabled]: disabled,
        [styles.active]: activeIndex === index,
    })

    const style: CSSProperties = transform
        ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
              transition,
          }
        : {}

    return {
        ref: setNodeRef,
        className,
        style,
        ...tableRowProps,
        ...attributes,
        ...listeners,
    }
}
