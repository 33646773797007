import * as React from 'react'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'

const drawerWidth = 310

export const layoutStyles = makeStyles((theme) => ({
    layoutRoot: {
        display: 'flex',
        flexGrow: 1,
        width: '100%',
        height: '100%',
    },
    // Nav & Header
    navRoot: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    hide: {
        display: 'none',
    },

    // Nav Bar
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: 'none',
        textDecoration: 'none',
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
    },
    toolbar: {
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },

    listText: {
        '& span': {
            fontWeight: '700',
        },
    },
    avatar: {
        marginRight: '35px',
    },
    materialIcon: {
        maxHeight: '25px',
        minWidth: '30px',
        maxWidth: '35px',
        objectFit: 'contain',
        marginRight: '50px',
    },
    profileLogout: {
        display: 'flex',
        flexFlow: 'column',
        height: '150px',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },

    // Header

    orgLogo: {
        maxWidth: '200px',
        maxHeight: '50px',
    },
    countryFlag: {
        borderRadius: '50%',
        width: '2em',
        height: '2em',
        padding: '2px',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.20)',
    },
}))

export const useStyles = makeStyles((theme) => ({
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },

    listIcon: {
        width: '30px',
        objectFit: 'contain',
    },
    '& .MuiListItemIcon-root': {
        minWidth: '40px',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    materialIcon: {
        objectFit: 'contain',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    profileLogout: {
        display: 'flex',
        flexFlow: 'column',
        height: '150px',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
}))

export function ElevationScroll(props) {
    const { children, window } = props

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    })

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    })
}
