import { ComponentProps } from 'react';
import TableRow from '@mui/material/TableRow';
import { StyledTableRow } from '../../../../components/Support/CustomElements';
import * as React from 'react';
import { useSortableTableRow } from './useSortableTableRow';

export type SortableTableRowProps = ComponentProps<typeof TableRow> & {
    id: string;
    disabled?: boolean;
};

export const SortableTableRow = ({
    children,
    ...props
}: SortableTableRowProps) => {
    const sortableTableRowProps = useSortableTableRow(props);

    return (
        <StyledTableRow hover tabIndex={-1} {...sortableTableRowProps}>
            {children}
        </StyledTableRow>
    );
};
