import React, { useEffect, useState } from 'react'
import '../../style/detailed-view.scss'
import { useTheme } from '@mui/styles'
import { countryLookup } from '../../utilities/countryLookup'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import SvgIcon from '@mui/material/SvgIcon'
import Button from '@mui/material/Button'
import FormLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Divider from '@mui/material/Divider'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CreateLink from '../Buttons/CreateLink'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import withStyles from '@mui/styles/withStyles'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Fade from '@mui/material/Fade'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import Link from '@mui/material/Link'
import { useAuth0 } from '../../contexts/auth0-context'
import store from '../../redux/store'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import Grid from '@mui/material/Grid'
import icon_pin from '../../assets/images/icon_pin.png'
import icon_pin_hole from '../../assets/images/icon_pin_hole.svg'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import ReactCountryFlag from 'react-country-flag'
import { ReactComponent as GreenCircleIcon } from '../../assets/images/green_circle.svg'
import { ReactComponent as OrangeTriangleIcon } from '../../assets/images/orange_triangle.svg'
import { ReactComponent as RedSquareIcon } from '../../assets/images/red_square.svg'

import { useSelector } from 'react-redux'
import FormControl from '@mui/material/FormControl'

const MAX_PRIMARY_EVIDENCE = 3

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}

export default function VMDetailedView({
    valueMessage,
    forceParentRefresh,
    closePanel,
    editing,
    setEditing,
    triggerSave,
    triggerCancel,
    triggerDelete,
    navigationStack,
}) {
    const snapshotMode = useSelector((state) => (state.strategy ? !!state.strategy.sourceId : false))
    const userReadonly = useSelector((state) => (state.userRecord ? !!state.userRecord.readonly : false))
    const isDarkMode = useSelector((state) => (state?.userRecord ? state?.userRecord?.isDarkMode : true))

    const theme = useTheme()
    const history = useHistory()
    const [isLoaded, setIsLoaded] = useState(false)
    const { isAuthenticated, getTokenSilently } = useAuth0()

    const [value, setValue] = useState(0)
    const [forceRefresh, setForceRefresh] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openSaveCountrySpecificModal, setOpenSaveCountrySpecificModal] = useState(false)

    const [valueMessageName, setValueMessageName] = useState('')
    const [newValueMessageName, setNewValueMessageName] = useState('')

    const [audiences, setAudiences] = useState({})
    const [newAudiences, setNewAudiences] = useState({})

    const [description, setDescription] = useState('')
    const [newDescription, setNewDescription] = useState('')

    const [category, setCategory] = useState('')

    const [status, setStatus] = useState('')
    const [newStatus, setNewStatus] = useState('')

    const [linkedActivities, setLinkedActivities] = useState([])
    const [linkedEvidence, setLinkedEvidence] = useState([])

    const [localMarkets, setLocalMarkets] = useState([])
    const [newLocalMarkets, setNewLocalMarkets] = useState([])

    const [settings, setSettings] = useState({})

    const [openModal, setOpenModal] = useState(false)
    const vmDisplayName = store.getState().settings?.displayNames?.valueMessage || 'Loading'

    useEffect(() => {
        // When a VM updates, fetch the most up to date version and update local values
        if (forceRefresh) {
            setForceRefresh(false)
        }

        if (!isAuthenticated || !valueMessage) {
            return
        }
        getVMData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueMessage, forceRefresh, isAuthenticated])

    async function getVMData() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        updateState(valueMessage)

        let incomingSettings = await callAPI(
            endpoints.getSettingsByOrganizationId.url + store.getState().strategy.organizationId,
            endpoints.getSettingsByOrganizationId.method,
            token
        )
        if (incomingSettings.success) {
            setSettings(incomingSettings.data)
        }

        setIsLoaded(true)

        let linkedActivityData = await callAPI(
            endpoints.getLinkedActivities.url,
            endpoints.getLinkedActivities.method,
            token,
            {
                listOfActivities: valueMessage.linkedActivities,
            }
        )
        setLinkedActivities(linkedActivityData.success ? linkedActivityData.data : [])

        let linkedEvidenceData = await callAPI(
            endpoints.getLinkedEvidence.url,
            endpoints.getLinkedEvidence.method,
            token,
            { listOfEvidence: valueMessage.linkedEvidence }
        )
        if (linkedEvidenceData.success) {
            let sortedEvidence = linkedEvidenceData.data.sort((a, b) => a.name.localeCompare(b.name))
            let starredElements = []
            for (let i = 0; i < sortedEvidence.length; i++) {
                if (valueMessage.primaryEvidence && valueMessage.primaryEvidence.includes(sortedEvidence[i]._id)) {
                    let el = sortedEvidence[i]
                    sortedEvidence.splice(i, 1)
                    i--
                    starredElements.push(el)
                }
            }
            starredElements = starredElements.sort((a, b) => a.name.localeCompare(b.name))
            starredElements = starredElements.concat(sortedEvidence)
            setLinkedEvidence(starredElements)
        } else {
            setLinkedEvidence([])
        }
    }

    useEffect(() => {
        if (triggerSave === 0) {
            return
        }
        saveValueMessage()
    }, [triggerSave])

    useEffect(() => {
        if (triggerCancel === 0) {
            return
        }
        handleCancel()
    }, [triggerCancel])

    useEffect(() => {
        if (triggerDelete === 0) {
            return
        }
        handleDeleteModalOpen()
    }, [triggerDelete])

    function updateState(vm) {
        setValueMessageName(vm.name)
        setNewValueMessageName(vm.name)
        setDescription(vm.description)
        setNewDescription(vm.description)
        setAudiences(vm.audiences)
        setNewAudiences(vm.audiences)
        setCategory(vm.category.name)
        setStatus(vm.status)
        setNewStatus(vm.status)
        setLocalMarkets(vm.localMarkets)
        setNewLocalMarkets(vm.localMarkets)
    }

    async function deleteTheValueMessage() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        setOpenDeleteModal(false)

        if (store.getState().selectedMarket === 'Global') {
            await callAPI(
                endpoints.deleteValueMessage.url + valueMessage._id,
                endpoints.deleteValueMessage.method,
                token
            )
        } else {
            await callAPI(
                endpoints.removeCountryFromVM.url + valueMessage._id,
                endpoints.removeCountryFromVM.method,
                token,
                { countryToRemove: store.getState().selectedMarket }
            )
        }
        forceParentRefresh(true)
        closePanel()
    }

    async function saveValueMessage() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        if (store.getState().selectedMarket && store.getState().selectedMarket !== 'Global') {
            setOpenSaveCountrySpecificModal(true)
            return
        }

        setEditing(!editing)
        let newValueMessage = {
            name: newValueMessageName,
            priority: valueMessage.priority,
            status: newStatus,
            order: valueMessage.order,
            strategyId: valueMessage.strategyId,
            organizationId: valueMessage.organizationId,
            linkedActivities: valueMessage.linkedActivities,
            linkedEvidence: valueMessage.linkedEvidence,
            description: newDescription,
            category,
            audiences: newAudiences,
            primaryEvidence: valueMessage.primaryEvidence,
            localMarkets: newLocalMarkets,
            isCountrySpecific: false,
        }
        callAPI(
            endpoints.updateValueMessage.url + valueMessage._id,
            endpoints.updateValueMessage.method,
            token,
            newValueMessage
        )
        setValueMessageName(newValueMessageName)
        setDescription(newDescription)
        setAudiences(newAudiences)
        setStatus(newStatus)
        setLocalMarkets(newLocalMarkets)
        forceParentRefresh(true)
    }

    async function addLinkedEvidence(checkedEvidence) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        // Find the differences between the checked values and current values
        let toDelete = valueMessage.linkedEvidence.filter((x) => !checkedEvidence.includes(x))
        toDelete = toDelete.filter((x) => x.length > 5)
        let toAdd = checkedEvidence.filter((x) => !valueMessage.linkedEvidence.includes(x))
        toAdd = toAdd.filter((x) => x.length > 5)

        // Trigger appropriate linking function for each to enable two-way binding
        // for...of loops execute in sequence. Using 'await' prevents DB versioning  conflicts
        for (let evidenceId of toAdd) {
            await callAPI(
                endpoints.linkEvidenceToVM.url + valueMessage._id + '/' + evidenceId,
                endpoints.linkEvidenceToVM.method,
                token
            )
        }
        for (let evidenceId of toDelete) {
            await callAPI(
                endpoints.deleteLinkEvidenceToVM.url + valueMessage._id + '/' + evidenceId,
                endpoints.deleteLinkEvidenceToVM.method,
                token
            )
        }
        setForceRefresh(true)
        forceParentRefresh(true)
    }

    async function addLinkedActivities(checkedActivities) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        // Find the differences between the checked values and current values
        let toDelete = valueMessage.linkedActivities.filter((x) => !checkedActivities.includes(x))
        toDelete = toDelete.filter((x) => x.length > 5)
        let toAdd = checkedActivities.filter((x) => !valueMessage.linkedActivities.includes(x))
        toAdd = toAdd.filter((x) => x.length > 5)

        // Trigger appropriate linking function for each to enable two-way binding
        // for...of loops execute in sequence. Using 'await' prevents DB versioning  conflicts
        for (let activityId of toAdd) {
            await callAPI(
                endpoints.linkActivityToVM.url + valueMessage._id + '/' + activityId,
                endpoints.linkActivityToVM.method,
                token
            )
        }
        for (let activityId of toDelete) {
            await callAPI(
                endpoints.deleteLinkActivityToVM.url + valueMessage._id + '/' + activityId,
                endpoints.deleteLinkActivityToVM.method,
                token
            )
        }
        setForceRefresh(true)
        forceParentRefresh(true)
    }

    function handleDeleteModalClose() {
        setOpenDeleteModal(false)
    }

    function handleDeleteModalOpen() {
        setOpenDeleteModal(true)
    }

    const handleVMCheckboxes = (event) => {
        setNewAudiences({
            ...newAudiences,
            [event.target.name]: event.target.checked,
        })
    }

    const handleLocalMarkets = (event) => {
        if (event.target.checked) {
            if (!newLocalMarkets.includes(event.target.name)) {
                let temp = Array.from(newLocalMarkets)
                temp.push(event.target.name)
                setNewLocalMarkets(temp)
            }
        } else {
            if (newLocalMarkets.includes(event.target.name)) {
                let temp = Array.from(newLocalMarkets)
                let filtered = temp.filter((e) => e !== event.target.name)
                setNewLocalMarkets(filtered)
            }
        }
    }

    const WhiteOutlineCheckbox = withStyles({
        checked: {},
    })((props) => <Checkbox color="default" value={props.val} {...props} />)

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
    }

    const linkButtonTabIndexMapping = {
        0: 'evidence',
        1: 'activity',
    }

    function handleCancel() {
        setEditing(!editing)
        setNewValueMessageName(valueMessageName)
        setNewAudiences(audiences)
        setNewDescription(description)
        setNewStatus(status)
        setNewLocalMarkets(localMarkets)
    }

    async function addPrimaryEvidence(evidenceId) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        if (valueMessage.primaryEvidence.length >= MAX_PRIMARY_EVIDENCE) {
            setOpenModal(true)
            return
        }
        await callAPI(
            endpoints.addPrimaryEvidence.url + valueMessage._id + '/' + evidenceId,
            endpoints.addPrimaryEvidence.method,
            token
        )
        setForceRefresh(true)
        forceParentRefresh(true)
    }

    async function removePrimaryEvidence(evidenceId) {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callAPI(
            endpoints.removePrimaryEvidence.url + valueMessage._id + '/' + evidenceId,
            endpoints.removePrimaryEvidence.method,
            token
        )
        setForceRefresh(true)
        forceParentRefresh(true)
    }

    async function handleSaveCountrySpecificView() {
        setEditing(!editing)
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let newValueMessage = {
            name: newValueMessageName,
            priority: valueMessage.priority,
            status: newStatus,
            order: valueMessage.order,
            strategyId: valueMessage.strategyId,
            organizationId: valueMessage.organizationId,
            linkedActivities: valueMessage.linkedActivities,
            linkedEvidence: valueMessage.linkedEvidence,
            description: newDescription,
            category,
            audiences: newAudiences,
            primaryEvidence: valueMessage.primaryEvidence,
            localMarkets: [store.getState().selectedMarket],
            isCountrySpecific: true,
        }
        callAPI(endpoints.createValueMessage.url, endpoints.createValueMessage.method, token, newValueMessage)
        callAPI(endpoints.removeCountryFromVM.url + valueMessage._id, endpoints.removeCountryFromVM.method, token, {
            countryToRemove: store.getState().selectedMarket,
        })
        setValueMessageName(newValueMessageName)
        setDescription(newDescription)
        setAudiences(newAudiences)
        setStatus(newStatus)
        setLocalMarkets(newLocalMarkets)
        history.push('/value-narratives')
    }

    function flattenMarkets() {
        let keyMarkets = []
        for (let phase of store.getState().strategy.keyMarkets) {
            keyMarkets = keyMarkets.concat(phase.phaseKeyMarkets)
        }
        return keyMarkets
    }

    function addToStack() {
        let arr = Array.from(navigationStack)
        arr.push({
            path: '/value-narratives',
            data: valueMessage,
        })
        return arr
    }

    return (
        <Fade in={isLoaded} timeout={800}>
            <Grid container item sx={{ backgroundColor: isDarkMode ? '#363943' : 'white' }}>
                <Grid container item direction="column" xs spacing={0}>
                    <Dialog
                        open={openDeleteModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleDeleteModalClose}
                        aria-labelledby="alert-dialog-slide-title6"
                        aria-describedby="alert-dialog-slide-description6">
                        <DialogTitle id="alert-dialog-slide-title6">
                            <Typography variant="h4">
                                {store.getState().selectedMarket === 'Global'
                                    ? 'Are you sure you want to delete this Value Message?'
                                    : 'The Message will only be removed from Country View. Do you wish to continue?'}
                            </Typography>
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleDeleteModalClose} sx={{ mb: 1 }} variant="outlined-sm-button">
                                CANCEL
                            </Button>
                            <Button onClick={deleteTheValueMessage} sx={{ mr: 1, mb: 1 }} variant="contained-sm-button">
                                {store.getState().selectedMarket === 'Global' ? 'Delete' : 'Remove'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {isLoaded && (
                        <Grid
                            container
                            spacing={1}
                            pb={1.5}
                            sx={{
                                borderBottomLeftRadius: '24px',
                                borderBottomRightRadius: '24px',
                            }}>
                            {editing ? (
                                <Grid item container direction="column" spacing={2} p={3} m={2}>
                                    <Grid container item spacing={2}>
                                        <Grid container item direction="column" xs spacing={1}>
                                            <Grid item>
                                                <Typography variant="h5">{vmDisplayName}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    id="edit-vm"
                                                    multiline
                                                    minRows={4}
                                                    variant="outlined"
                                                    placeholder={valueMessageName}
                                                    value={newValueMessageName}
                                                    onFocus={(event) => {
                                                        event.target.setAttribute('autocomplete', 'off')
                                                    }}
                                                    onChange={(e) => setNewValueMessageName(e.target.value)}
                                                    fullWidth
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: '12px',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item direction="column" xs spacing={1}>
                                            <Grid item>
                                                <Typography variant="h5">Description</Typography>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    id="edit-vm"
                                                    multiline
                                                    variant="outlined"
                                                    minRows={4}
                                                    placeholder={description}
                                                    value={newDescription}
                                                    onFocus={(event) => {
                                                        event.target.setAttribute('autocomplete', 'off')
                                                    }}
                                                    onChange={(e) => setNewDescription(e.target.value)}
                                                    fullWidth
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: '12px',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={4}>
                                        <Grid container item direction="column" xs="auto" spacing={1}>
                                            <Grid item>
                                                <Typography variant="h5">Status</Typography>
                                            </Grid>
                                            <Grid item>
                                                <FormControl fullWidth>
                                                   <Select
                                                       variant={'outlined'}
                                                       sx={{ borderRadius: '12px' }}
                                                       value={newStatus}
                                                       onChange={(e) => setNewStatus(e.target.value)}>
                                                       <MenuItem value={'Supported'}>
                                                           Supported
                                                       </MenuItem>
                                                       <MenuItem value={'Supported-suboptimal'}>
                                                           Supported-suboptimal
                                                       </MenuItem>
                                                        <MenuItem value={'Aspirational'}>
                                                           Aspirational
                                                       </MenuItem>
                                                   </Select>
                                               </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={2}>
                                        <Grid container item spacing={1} direction="column">
                                            <Grid item>
                                                <Typography variant="h5">Audiences</Typography>
                                            </Grid>
                                            <Grid item>
                                                <FormGroup row>
                                                    {settings.vmAudiences.map((audience, i) => (
                                                        <FormControlLabel
                                                            key={i}
                                                            control={
                                                                <WhiteOutlineCheckbox
                                                                    checked={newAudiences[audience]}
                                                                    onChange={handleVMCheckboxes}
                                                                    name={audience}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={audience}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </Grid>
                                        </Grid>

                                        {settings &&
                                            settings.enableLocalizedMarkets &&
                                            store.getState().selectedMarket === 'Global' && (
                                                <Grid container item direction="column" xs={6} spacing={1}>
                                                    <Grid item>
                                                        <Typography variant="h5">Markets</Typography>
                                                    </Grid>
                                                    <Grid container item sx={{ pl: '0 !important' }}>
                                                        <FormGroup row>
                                                            {store.getState().strategy &&
                                                                flattenMarkets().map((market) => (
                                                                    <Grid
                                                                        container
                                                                        item
                                                                        xs="auto"
                                                                        alignItems="center"
                                                                        spacing={1}
                                                                        sx={{ pr: 4 }}>
                                                                        <Grid item>
                                                                            <WhiteOutlineCheckbox
                                                                                checked={newLocalMarkets.includes(
                                                                                    market.code
                                                                                )}
                                                                                onChange={handleLocalMarkets}
                                                                                name={market.code}
                                                                                color="primary"
                                                                                inputProps={{
                                                                                    'aria-label': 'controlled',
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <ReactCountryFlag
                                                                                countryCode={market.code?.toLowerCase()}
                                                                                svg
                                                                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                                                cdnSuffix="svg"
                                                                                style={{
                                                                                    width: '1.7em',
                                                                                    height: '1.7em',
                                                                                    margin: '2px',
                                                                                }}
                                                                                title={market.label}
                                                                                className="countryFlag"
                                                                            />
                                                                        </Grid>

                                                                        <Grid item>
                                                                            <FormLabel>{market.label}</FormLabel>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                        </FormGroup>
                                                    </Grid>
                                                </Grid>
                                            )}
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid item container xs={12}>
                                    <Accordion
                                        defaultExpanded
                                        square
                                        sx={{
                                            width: '100%',
                                            backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6',
                                            boxShadow: 'none',
                                            p: 1,
                                        }}>
                                        <AccordionSummary
                                            sx={{
                                                backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6',
                                            }}
                                            expandIcon={
                                                <IconButton>
                                                    <ExpandMoreIcon color="primary" />
                                                </IconButton>
                                            }
                                            aria-controls="panel1a-content"
                                            id="panel1a-header">
                                            <Grid
                                                container
                                                item
                                                direction="column"
                                                spacing={1}
                                                sx={{
                                                    backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6',
                                                    pt: 0.5,
                                                }}>
                                                <Grid item>
                                                    <Typography variant="h5">Title:</Typography>
                                                </Grid>
                                                <Grid container direction="row"></Grid>
                                                <Grid container item direction="row" spacing={1}>
                                                    <Grid item xs={10}>
                                                        <Typography variant="h2">{valueMessageName}</Typography>
                                                    </Grid>
                                                    <Grid item xs>
                                                        {status === 'Supported' && (
                                                            <Chip
                                                                icon={
                                                                    <SvgIcon fontSize="small" component={GreenCircleIcon} inheritViewBox />
                                                                }
                                                                label={
                                                                    <Typography variant="chip">Supported</Typography>
                                                                }
                                                            />
                                                        )}
                                                        {status === 'Supported-suboptimal' && (
                                                            <Chip
                                                                icon={
                                                                    <SvgIcon fontSize="small" component={OrangeTriangleIcon} inheritViewBox />
                                                                }
                                                                label={
                                                                    <Typography variant="chip">Supported-suboptimal</Typography>
                                                                }
                                                            />
                                                        )}
                                                        {status === 'Aspirational' && (
                                                            <Chip
                                                                icon={
                                                                    <SvgIcon fontSize="small" component={RedSquareIcon} inheritViewBox />
                                                                }
                                                                label={
                                                                    <Typography variant="chip">Aspirational</Typography>
                                                                }
                                                            />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={3}>
                                                {
                                                    <Grid container item direction="column">
                                                        <Grid item sx={{ mt: 1 }}>
                                                            <Typography variant="h5">Description</Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sx={{
                                                                p: 1,
                                                                mt: 1,
                                                                border: 1,
                                                                borderColor: '#CCCCCC',
                                                                borderRadius: '16px',
                                                            }}
                                                            elevation={1}>
                                                            <Typography variant="body3">
                                                                {description && description.length > 1
                                                                    ? description
                                                                    : 'No description to display'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Divider // Dividing line between Description and Details
                                                sx={{
                                                    borderBottom: 1,
                                                    borderColor: '#CCCCCC',
                                                    pt: 3,
                                                    mb: 5,
                                                }}></Divider>
                                            <Grid container direction="row">
                                                <Grid
                                                    container
                                                    item
                                                    spacing={3}
                                                    xs={12}
                                                    sm={6}
                                                    direction="row"
                                                    sx={{ mt: -6 }}>
                                                    <Grid item xs="auto">
                                                        <Typography variant="h5">Details</Typography>
                                                    </Grid>
                                                    <Grid container item alignItems="center" spacing={2}>
                                                        <Grid item xs="auto">
                                                            <Typography variant="title2">Category:</Typography>
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Chip
                                                                label={
                                                                    <Typography variant="chip">
                                                                        {category}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid // horizontal divider betwen Category and Audiences
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            ml: 3,
                                                            mt: -1,
                                                            mb: -1,
                                                            borderBottom: 1,
                                                            borderColor: '#CCCCCC',
                                                        }}></Grid>
                                                    <Grid container item spacing={2} alignItems="flex-start">
                                                        <Grid item xs="auto">
                                                            <Typography variant="title2">Audiences:</Typography>
                                                        </Grid>
                                                        {Object.values(audiences).some((value) => value) ? (
                                                            <Grid container item xs={6} spacing={2} direction="row">
                                                                <Grid container item xs="auto">
                                                                    {Object.keys(audiences).map((audience, i) => {
                                                                        if (audiences[audience]) {
                                                                            return (
                                                                                <Grid item key={i}>
                                                                                    <Chip
                                                                                        label={
                                                                                            <Typography variant="chip">
                                                                                                {audience}
                                                                                            </Typography>
                                                                                        }
                                                                                        sx={{ mr: 1 }}
                                                                                    />
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                        return null
                                                                    })}
                                                                </Grid>
                                                            </Grid>
                                                        ) : (
                                                            <Grid item xs={12} sx={{ mt: -2 }}>
                                                                <Typography variant="body3">
                                                                    No audience to display
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid // horizontal divider betwen Audiences and Markets only when breakpoint is xs
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        mt: 2,
                                                        mb: 4,
                                                        borderBottom: 1,
                                                        borderColor: '#CCCCCC',
                                                        display: {
                                                            xs: 'block',
                                                            sm: 'none',
                                                        },
                                                    }}></Grid>
                                                <Grid // vertical dividing line between Markets and Linked Data
                                                    item
                                                    xs={0}
                                                    sx={{
                                                        ml: 2,
                                                        mr: 2,
                                                        mt: -3,
                                                        mb: -1,
                                                        borderLeft: 1,
                                                        borderColor: '#CCCCCC',
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'block',
                                                            md: 'block',
                                                            lg: 'block',
                                                            xl: 'block',
                                                        },
                                                    }}></Grid>
                                                <Grid
                                                    container
                                                    item
                                                    spacing={2}
                                                    xs={5}
                                                    alignItems="flex-start"
                                                    sx={{ mt: -5 }}>
                                                    {settings && settings.enableLocalizedMarkets && (
                                                        <Grid container item direction="column" xs="auto">
                                                            <Grid item sx={{ mb: 2 }}>
                                                                <Typography variant="h5">Markets</Typography>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                elevation={1}
                                                                sx={{
                                                                    backgroundColor: isDarkMode ? '#3B3F4D' : '#D3D5DB',
                                                                    borderRadius: '16px',
                                                                    width: '75%',
                                                                    pt: 0.5,
                                                                    pb: 0.5,
                                                                }}>
                                                                {newLocalMarkets.map((market, i) => (
                                                                    <ReactCountryFlag
                                                                        key={i}
                                                                        countryCode={market.toLowerCase()}
                                                                        svg
                                                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                                        cdnSuffix="svg"
                                                                        style={{
                                                                            width: '1.7em',
                                                                            height: '1.7em',
                                                                            margin: 8,
                                                                        }}
                                                                        title={countryLookup[market]}
                                                                        className="countryFlag"
                                                                    />
                                                                ))}
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
                <Divider light />
                <Grid container item direction="column" sx={{ m: 4, p: 0 }}>
                    <Grid item sx={{ mb: 1.3, pl: 2, mt: 1 }}>
                        <Typography variant="h2">Linked data</Typography>
                    </Grid>
                    <Grid container item alignItems="center" justifyContent="space-between" xs>
                        <Grid item xs={6} pl={2}>
                            <Tabs value={value} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                                <Tab
                                    label={store.getState().settings?.displayNames?.evidence || 'Loading'}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    label={store.getState().settings?.displayNames?.activities || 'Loading'}
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </Grid>
                        <Grid container item xs="auto" sx={{ pr: 3, pt: 2.9, mb: 1 }}>
                            {!snapshotMode && !userReadonly && (
                                <CreateLink
                                    srcPage={'vm'}
                                    id={linkButtonTabIndexMapping[value]}
                                    listItems={value === 1 ? linkedActivities : linkedEvidence}
                                    update={value === 1 ? addLinkedActivities : addLinkedEvidence}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item sx={{ ml: -1, pl: 1, pt: 3 }}>
                        <TabPanel
                            value={value}
                            index={0}
                            dir={theme.direction}
                            style={{ marginLeft: 8, marginRight: -8, marginTop: -8, width: '100%' }}>
                            {linkedEvidence && linkedEvidence.length ? (
                                linkedEvidence.map((ev, i) => (
                                    <Grid
                                        container
                                        item
                                        wrap="nowrap"
                                        spacing={1}
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{
                                            backgroundColor: isDarkMode ? 'rgba(0, 0, 0, .3)' : '#F6F6F7',
                                            mb: 3,
                                            pb: 2,
                                            p: 1,
                                            borderRadius: '16px',
                                            width: '100%',
                                        }}>
                                        {valueMessage.primaryEvidence &&
                                        valueMessage.primaryEvidence.includes(ev._id.toString()) ? (
                                            // eslint-disable-next-line
                                            <Grid item sx={{ p: 1 }}>
                                                <a href="javascript:void(0)">
                                                    <img
                                                        src={icon_pin}
                                                        alt="Icon Pin"
                                                        style={{ width: '1.5vw', objectFit: 'contain' }}
                                                        onClick={() =>
                                                            !snapshotMode &&
                                                            !userReadonly &&
                                                            removePrimaryEvidence(ev._id)
                                                        }
                                                    />
                                                </a>
                                            </Grid>
                                        ) : (
                                            // eslint-disable-next-line
                                            <Grid item sx={{ p: 1, mt: 1 }}>
                                                <a href="javascript:void(0)">
                                                    <img
                                                        src={icon_pin_hole}
                                                        alt="Icon Pin Hole"
                                                        style={{
                                                            width: '1.6vw',
                                                            padding: '.4vw',
                                                            objectFit: 'contain',
                                                        }}
                                                        onClick={() =>
                                                            !snapshotMode && !userReadonly && addPrimaryEvidence(ev._id)
                                                        }
                                                    />
                                                </a>
                                            </Grid>
                                        )}
                                        <Grid
                                            container
                                            item
                                            wrap="nowrap"
                                            alignItems="flex-start"
                                            justifyContent="space-between"
                                            sx={{ pb: 1 }}>
                                            <Grid item xs={0} sx={{ mr: 1, pt: 1 }}>
                                                <Typography variant="body1">{i + 1}.</Typography>
                                            </Grid>
                                            <Grid item xs zeroMinWidth sx={{ pb: 1, pt: 1 }}>
                                                <Typography variant="body1">{ev.name}</Typography>
                                            </Grid>
                                            <Link
                                                component={RouterLink}
                                                underline="none"
                                                color="secondary"
                                                to={{
                                                    pathname: '/evidence',
                                                    autoOpenData: {
                                                        isAutoOpenPanel: true,
                                                        autoOpenData: ev,
                                                        previousDataStack: addToStack(),
                                                    },
                                                }}>
                                                <Grid xs={0} sx={{ ml: 1, mr: 1, mb: 1 }}>
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="upload picture"
                                                        component="label">
                                                        <ArrowForwardIosIcon fontSize="small" color="primary" />
                                                    </IconButton>
                                                </Grid>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                ))
                            ) : (
                                <Alert
                                    icon={<LinkOffIcon fontSize="inherit" />}
                                    severity="info"
                                    sx={{ borderRadius: '12px', mt: -2, ml: -1, mr: 1 }}>
                                    {`No Linked ${store.getState().settings?.displayNames?.evidence || 'Loading'}`}
                                </Alert>
                            )}
                        </TabPanel>
                        <TabPanel
                            value={value}
                            index={1}
                            dir={theme.direction}
                            style={{ width: '100%', marginLeft: 16 }}>
                            {linkedActivities && linkedActivities.length ? (
                                linkedActivities.map((act, i) => (
                                    <Link
                                        component={RouterLink}
                                        underline="none"
                                        key={i}
                                        color="secondary"
                                        to={{
                                            pathname: '/activities',
                                            autoOpenData: {
                                                isAutoOpenPanel: true,
                                                autoOpenData: act,
                                                previousDataStack: addToStack(),
                                            },
                                        }}>
                                        <Grid
                                            container
                                            item
                                            wrap="nowrap"
                                            spacing={2}
                                            justifyContent="flex-start"
                                            sx={{
                                                backgroundColor: isDarkMode ? 'rgba(0, 0, 0, .3)' : '#F6F6F7',
                                                mb: 4,
                                                p: 0,
                                                pb: 2,
                                                pt: 1,
                                                pl: 1,
                                                borderRadius: '16px',
                                            }}>
                                            <Grid container item spacing={2}>
                                                <Grid item xs={0} sx={{ pb: 1, pl: 1 }}>
                                                    <Typography variant="body1">{i + 1}.</Typography>
                                                </Grid>
                                                <Grid item xs zeroMinWidth sx={{ ml: -1 }}>
                                                    <Typography variant="body1">{act.title}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={0} sx={{ pr: 3, pt: 2.5 }}>
                                                <ArrowForwardIosIcon fontSize="small" color="primary" />
                                            </Grid>
                                        </Grid>
                                    </Link>
                                ))
                            ) : (
                                <Alert
                                    icon={<LinkOffIcon fontSize="inherit" />}
                                    severity="info"
                                    sx={{ borderRadius: '12px', mt: -3, ml: -2 }}>
                                    {`No Linked ${store.getState().settings?.displayNames?.activities || 'Loading'}`}
                                </Alert>
                            )}
                        </TabPanel>
                    </Grid>
                </Grid>
                <Dialog
                    open={openModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title">{'Maximum Primary Links Exceeded'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Only {MAX_PRIMARY_EVIDENCE} Primary Linkages are allowed. Please remove other Primary Links
                            before continuing.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenModal(false)}>OK</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openSaveCountrySpecificModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenSaveCountrySpecificModal(false)}
                    aria-labelledby="alert-dialog-slide-title1"
                    aria-describedby="alert-dialog-slide-description1">
                    <DialogTitle id="alert-dialog-slide-title1">{'Editing Global ' + vmDisplayName}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description1">
                            {`Editing a Global ${vmDisplayName} will create a new ${vmDisplayName} specific to your selected
                            country. Changes made to this new ${vmDisplayName} will not affect the Global ${vmDisplayName} and
                            vice-versa. Do you wish to continue?`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenSaveCountrySpecificModal(false)
                                handleCancel()
                            }}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                handleSaveCountrySpecificView()
                                setOpenSaveCountrySpecificModal(false)
                            }}>
                            {`YES, EDIT AND CREATE LOCAL ${vmDisplayName}`}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Fade>
    )
}
