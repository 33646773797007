import store from '../redux/store'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import { visuallyHidden } from '@mui/utils'
import React from 'react'
import { Typography } from '@mui/material'

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0) + s.slice(1)
}

export const isStrategySelected = () => {
    return store.getState().strategy && store.getState().strategy._id && store.getState().strategy._id.length > 0
}

export function quarterOfYear(date) {
    return Math.floor((date.getMonth() + 3) / 3)
}

// -------------------------------------------------------
// https://mui.com/material-ui/react-table/
// Under the Sorting section
export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

export function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, columns, align } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            sx={{
                                visibility: headCell.label ?  'visible' : 'hidden',
                                '&:hover .MuiTableSortLabel-icon': {
                                    color: 'primary.main',
                                },
                            }}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
                            <Typography variant="h5" noWrap>
                                {headCell.label}
                            </Typography>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

// MATERIAL + REACT DND CODE ADAPTED FROM: https://github.com/hmarggraff/react-material-ui-table-row-drag-and-drop/blob/master/src/index.js
// a little function to help us with reordering the result
export const newOrder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

export function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay))
}

export function getISOStringFromCalendarDate(date) {
    if (!date) {
        return null
    }
    let x = new Date(date)
    x.setMinutes(1)
    return x.toISOString()
}
