import React, { useMemo } from 'react'
import { useIsSnapshotMode } from '../../../shared/hooks/useIsSnapshotMode'
import { useIsMarketMode } from '../../../shared/hooks/useIsMarketMode'
import { InfoBanner } from '../../../shared/components/InfoBanner'
import { countryLookup } from '../../../utilities/countryLookup'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { SnapshotModeFloatingNotification } from './components/SnapshotModeFloatingNotification'
import Collapse from '@mui/material/Collapse'

export const NavbarInfoBanner = () => {
    const isSnapshotMode = useIsSnapshotMode()
    const { isMarketMode, selectedMarket } = useIsMarketMode()

    const snapshotCreationDate = useSelector(
        // @ts-expect-error redux store is not typed properly
        (state) => state?.strategy?.createdAt
    )

    const formattedSnapshotCreationDate = new Date(snapshotCreationDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })

    const snapshotModeInfoText = isSnapshotMode ? `Viewing snapshot: ${formattedSnapshotCreationDate}` : ''
    const marketModeInfoText = isMarketMode ? `Market: ${countryLookup[selectedMarket]}` : ''

    const infoBannerTexts = useMemo(
        () => [snapshotModeInfoText, marketModeInfoText],
        [snapshotModeInfoText, marketModeInfoText]
    )

    return (
        <Collapse collapsedSize={0} appear in={isSnapshotMode || isMarketMode} unmountOnExit>
            <Box>
                <InfoBanner texts={infoBannerTexts} />
                <SnapshotModeFloatingNotification />
            </Box>
        </Collapse>
    )
}
