import { useCallback, useMemo } from 'react';

import { useIsSanofiOrganization } from '../useIsSanofiOrganization';

import { ActivityStatus, UseActivityStatusProps } from './useActivityStatus.types';
import { getActivityStatusDetails } from './useActivityStatus.helpers';

export const useActivityStatus = ({ activityStatus }: Omit<UseActivityStatusProps, 'isSanofiOrganization'> = {}) => {
    const isSanofiOrganization = useIsSanofiOrganization();

    const handleGetActivityStatusDetails = useCallback(
        (activityStatus: ActivityStatus | null) => getActivityStatusDetails({ activityStatus, isSanofiOrganization }),
        [isSanofiOrganization],
    );

    const activityStatusDetails = useMemo(
        () => handleGetActivityStatusDetails(activityStatus),
        [handleGetActivityStatusDetails, activityStatus],
    );

    return {
        activityStatusDetails,
        handleGetActivityStatusDetails,
    };
};
