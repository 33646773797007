import React from 'react'
import Typography from '@mui/material/Typography'
import { EmptyPlaceholderOneLineContentProps } from '../EmptyPlaceholder.types'
import { COLORS } from '../../../../style/colors'

export const EmptyPlaceholderOneLineContent = ({ text }: EmptyPlaceholderOneLineContentProps) => {
    return (
        <Typography variant="subtitle1" color={COLORS.granite}>
            {text}
        </Typography>
    )
}
