import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import { quarterOfYear } from '../../utilities/helpers'
import { useStyles } from '../../style/views'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

var ProgressBar = require('progressbar.js')

export default function DateProgressBar(props) {
    const { dates } = props

    const [remainingQuarters, setRemainingQuarters] = useState()
    const [percentDone, setPercentDone] = useState()
    const [oldDates, setOldDates] = useState({})
    const [oldBar, setOldBar] = useState()
    const classes = useStyles()

    useEffect(() => {
        if (dates && !_.isEmpty(dates)) {
            if (!_.isEqual(oldDates, dates)) {
                setOldDates(dates)
                if (oldBar) {
                    oldBar.destroy()
                }
                var bar = new ProgressBar.Line('#activitiesDateProgress', {
                    strokeWidth: 3,
                    easing: 'easeInOut',
                    duration: 2500,
                    color: '#9EF391',
                    trailColor: '#F2A047',
                    trailWidth: 3,
                    svgStyle: { width: '100%', height: '60%', borderRadius: '30px' },
                })

                // Start to end calculations
                let yearDiff = new Date(dates.endDate).getFullYear() - new Date(dates.startDate).getFullYear()
                let qDiff = quarterOfYear(new Date(dates.endDate)) - quarterOfYear(new Date(dates.startDate))
                let totalQuarters = yearDiff * 4 + qDiff + 1

                // Today to end calculations
                let yearDiffCurrentToEnd = new Date(dates.endDate).getFullYear() - new Date().getFullYear()
                let qDiffCurrentToEnd = quarterOfYear(new Date(dates.endDate)) - quarterOfYear(new Date())
                let quartersRemainingToEnd = yearDiffCurrentToEnd * 4 + qDiffCurrentToEnd + 1

                // Start to today calculations
                let startYearDiff = new Date().getFullYear() - new Date(dates.startDate).getFullYear()
                let startQDiff = quarterOfYear(new Date()) - quarterOfYear(new Date(dates.startDate))
                let progress = startYearDiff * 4 + startQDiff

                let percentCalculation = (progress / totalQuarters) * 100
                let progressCalculation = progress / totalQuarters

                if (progressCalculation < 0) {
                    progressCalculation = 0
                } else if (progressCalculation > 1) {
                    progressCalculation = 1
                }

                if (percentCalculation < 0) {
                    setPercentDone(0)
                } else if (percentCalculation > 100) {
                    setPercentDone(100)
                } else {
                    setPercentDone(percentCalculation)
                }

                setRemainingQuarters(quartersRemainingToEnd < 0 ? 0 : quartersRemainingToEnd)
                bar.animate(progressCalculation)
                setOldBar(bar)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    return (
        <Grid container item wrap="nowrap" spacing={1} alignItems="center" direction="column">
            <Grid container item wrap="nowrap">
                <Grid container item direction="column">
                    <Grid item>
                        <Typography variant="title2">Start:</Typography>
                    </Grid>
                    <Typography variant="body1">
                        {new Date(dates.startDate).getFullYear()} Q{quarterOfYear(new Date(dates.startDate))}
                    </Typography>
                </Grid>
                <Grid container item direction="column" alignItems="flex-end">
                    <Grid item>
                        <Typography variant="title2">End:</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            {new Date(dates.endDate).getFullYear()} Q{quarterOfYear(new Date(dates.endDate))}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Tooltip title={`${Math.round(percentDone)}% Complete`}>
                <Grid container item id="activitiesDateProgress" xs />
            </Tooltip>
            <Grid container item spacing={1} alignItems="center">
                <Grid item>
                    <Typography variant="title2">Remaining:</Typography>
                </Grid>
                <Grid item>
                    {new Date(oldDates.startDate).getTime() < new Date().getTime() ? (
                        <Typography variant="body1">
                            {remainingQuarters}
                            {remainingQuarters === 1 ? <> Quarter Remaining</> : <> Quarters Remaining</>}
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}
