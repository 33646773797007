import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '../../contexts/auth0-context'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import { setStrategy } from '../../redux/actions'
import { useHistory } from 'react-router-dom'
import { useIsSnapshotMode } from './useIsSnapshotMode'

export const useExitSnapshotMode = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const isSnapshotMode = useIsSnapshotMode()

    // @ts-expect-error redux store is not typed properly
    const strategy = useSelector((state) => state?.strategy)
    const { getTokenSilently } = useAuth0()

    const handleExitSnapshotMode = async () => {
        if (!isSnapshotMode) {
            return
        }

        const token = await getTokenSilently({
            audience: 'https://atlas.aesara.com',
        })
        const result = await callAPI(endpoints.getStrategy.url + strategy.sourceId, endpoints.getStrategy.method, token)
        dispatch(setStrategy(result.data))
        history.push('/')
    }

    return {
        handleExitSnapshotMode,
    }
}
