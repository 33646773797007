import { makeStyles } from '@mui/styles'

export const useFloatingNotificationStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        position: 'fixed',
        top: theme.spacing(14),
        right: 0,
        zIndex: theme.zIndex.appBar,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(0.5),
        paddingBlock: theme.spacing(2),
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.20) !important',
        borderRadius: `${theme.spacing(2)} 0px 0px ${theme.spacing(2)} !important`,
        overflow: 'visible !important',
    },
    closed: {
        paddingLeft: theme.spacing(0.5),
        transform: `translateX(calc(100% - ${theme.spacing(5.5)}))`,
    },
    button: {
        borderRadius: `${theme.spacing(1.5)} !important`,
        paddingBlock: `${theme.spacing(1.5)} !important`,
        paddingInline: `${theme.spacing(6)} !important`,
    },
    arrow: {
        width: theme.spacing(4.5),
    },
    flipped: {
        transform: 'rotate(180deg)',
    },
}))
