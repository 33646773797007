import React, { ReactElement } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { ConditionalWrapper, ConditionalWrapperProps } from '../ConditionalWrapper/ConditionalWrapper'
import Box from '@mui/material/Box'
import { TooltipProps } from '@mui/material'

export type ConditionalTooltipWrapperProps = Omit<ConditionalWrapperProps, 'wrapper'> & TooltipProps

export const ConditionalTooltipWrapper = ({
    condition,
    children,
    sx,
    ...tooltipProps
}: ConditionalTooltipWrapperProps) => {
    const tooltipWrapper = (children: ReactElement) => {
        return (
            <Tooltip {...tooltipProps}>
                <Box sx={sx}>{children}</Box>
            </Tooltip>
        )
    }

    return (
        <ConditionalWrapper condition={condition} wrapper={tooltipWrapper}>
            {children}
        </ConditionalWrapper>
    )
}
