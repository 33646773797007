import React, { useEffect, useMemo, useState } from 'react'
import { Card, Fade } from '@mui/material/'
import { Avatar, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { ReactComponent as RemoveIcon } from '../../../../../../../assets/images/bin.svg'
import { ReactComponent as SaveIcon } from '../../../../../../../assets/images/check-circle-1.svg'
import { ReactComponent as CancelIcon } from '../../../../../../../assets/images/Close.svg'
import SvgIcon from '@mui/material/SvgIcon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import useIsHovered from '../../../../../../hooks/useIsHovered'
import { formatCommentCreationDate, getAuthorAvatarColor, getAuthorInitials } from './CommentListItem.helpers'
import CircularProgress from '@mui/material/CircularProgress'
import { useCommentListItemStyles } from './CommentListItem.styles'
import { useSelector } from 'react-redux'
import { Comment } from '../../../../Comments.types'
import { useIsDarkMode } from '../../../../../../hooks/useIsDarkMode'
import clsx from 'clsx'

type Props = {
    comment: Comment
    onRemove: () => void
    isLoading: boolean
    minimized: boolean
}

export const CommentListItem = ({ comment, onRemove, isLoading, minimized }: Props) => {
    const styles = useCommentListItemStyles()

    // @ts-expect-error store is not typed properly
    const userId = useSelector((state) => state.userRecord?._id)

    const isDarkMode = useIsDarkMode()

    const [isRemoveConfirmationOpen, setRemoveConfirmationOpen] = useState<boolean>(false)

    const { isHovered, handlers } = useIsHovered()

    useEffect(() => {
        if (!isHovered) {
            handleClickCancelRemove()
        }
    }, [isHovered])

    const isYourComment = comment.user._id === userId
    const canRemove = isYourComment

    const handleClickRemove = () => {
        if (isLoading || !canRemove) {
            return
        }

        setRemoveConfirmationOpen(true)
    }

    const handleClickCancelRemove = () => {
        setRemoveConfirmationOpen(false)
    }

    const handleClickConfirmRemove = () => {
        if (isLoading || !canRemove) {
            return
        }

        onRemove()
        handleClickCancelRemove()
    }

    const formattedText = useMemo(() => comment.text.split('\n').map((line, index) => <div key={index}>{line}</div>), [
        comment.text,
    ])

    const isRemoveButtonVisible = canRemove && (isHovered || isLoading)
    const isDateVisible = minimized ? !isRemoveConfirmationOpen : !isRemoveButtonVisible

    return (
        <Box display="flex" justifyContent={isYourComment ? 'flex-end' : 'flex-start'} className={clsx(styles.container, minimized && styles.minimizedContainer)} {...handlers}>
            <Card className={clsx(styles.innerContainer, minimized && styles.minimizedInnerContainer)} elevation={0}>
                <Stack direction="column" gap={1}>
                    <Stack direction="row" gap={1.5} alignItems="center" position="relative">
                        <Avatar
                            alt={comment.user.name}
                            className={styles.avatar}
                            sx={{
                                backgroundColor: getAuthorAvatarColor(comment.user.name, isDarkMode),
                            }}>
                            {getAuthorInitials(comment.user.name)}
                        </Avatar>
                        <Typography
                            className={clsx(styles.author, minimized && styles.minimizedAuthor)}
                            // @ts-expect-error theme is not typed properly
                            variant="title2">
                            {comment.user.name}
                        </Typography>
                        <Fade in={isDateVisible}>
                            <Typography
                                className={styles.date}
                                whiteSpace="nowrap"
                                // @ts-expect-error theme is not typed properly
                                variant="subtitle5">
                                {formatCommentCreationDate(comment.createdAt)}
                            </Typography>
                        </Fade>
                        <Fade in={isRemoveButtonVisible}>
                            <Stack direction="row" mr={-1} position="absolute" right={0} bgcolor="figma.grayscale.contentWrapper">
                                {isRemoveConfirmationOpen ? (
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <Tooltip enterDelay={600} enterNextDelay={600} title="Confirm Removing" arrow>
                                            <IconButton disabled={isLoading} onClick={handleClickConfirmRemove}>
                                                <SvgIcon
                                                    component={SaveIcon}
                                                    color="primary"
                                                    inheritViewBox
                                                    fontSize="small"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip enterDelay={600} enterNextDelay={600} title="Cancel Removing" arrow>
                                            <IconButton
                                                disabled={isLoading}
                                                onClick={handleClickCancelRemove}
                                                classes={{
                                                    root: styles.date,
                                                }}>
                                                <SvgIcon
                                                    component={CancelIcon}
                                                    color="inherit"
                                                    inheritViewBox
                                                    fontSize="small"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                ) : (
                                    <Tooltip enterDelay={600} enterNextDelay={600} title="Remove Comment" arrow>
                                        <IconButton
                                            disabled={isLoading}
                                            onClick={handleClickRemove}
                                            classes={{
                                                root: styles.date,
                                            }}
                                            sx={{
                                                '&:hover': {
                                                    color: 'primary.main',
                                                },
                                            }}>
                                            {isLoading ? (
                                                <CircularProgress size={20} color="inherit" />
                                            ) : (
                                                <SvgIcon
                                                    component={RemoveIcon}
                                                    inheritViewBox
                                                    fontSize="small"
                                                    color="inherit"
                                                />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Stack>
                        </Fade>
                    </Stack>
                    <Typography
                        className={clsx(styles.text, minimized && styles.minimizedText)}
                        // @ts-expect-error theme is not typed properly
                        variant="body4">
                        {formattedText}
                    </Typography>
                </Stack>
            </Card>
        </Box>
    )
}
