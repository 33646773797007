import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import '../../node_modules/react-datepicker/dist/react-datepicker.css'
import Grid from '@mui/material/Grid'
import '../components/Activities/activity.scss'
import { useStyles } from '../style/views'
import CloseIcon from '@mui/icons-material/Close'
import { Typography } from '@mui/material'

export default function QuarterDatePicker(props) {
    const [startDate, setStartDate] = useState(props.oldStart ? new Date(props.oldStart) : null)
    const [endDate, setEndDate] = useState(props.oldEnd ? new Date(props.oldEnd) : null)
    const classes = useStyles()

    function handleStartChange(date) {
        setStartDate(date)
        props.start(date)
    }

    function handleEndChange(date) {
        setEndDate(date)
        props.end(date)
    }

    return (
        <Grid container item xs="auto" direction="column">
            <Grid container item wrap="noWrap" spacing={1} alignItems="center" xs="auto">
                <Grid item>
                    <Typography variant="subtitle3">Start Quarter:</Typography>
                </Grid>
                <Grid item>
                    <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => handleStartChange(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="yyyy, QQQ"
                        showQuarterYearPicker
                    />
                </Grid>
                <Grid item>
                    <CloseIcon fontSize="small" onClick={() => handleStartChange(null)} />
                </Grid>
            </Grid>
            <Grid container item wrap="noWrap" spacing={1} alignItems="center" xs="auto">
                <Grid item>
                    <Typography variant="subtitle3">End Quarter:</Typography>
                </Grid>
                <Grid item>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => handleEndChange(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="yyyy, QQQ"
                        minDate={startDate}
                        showQuarterYearPicker
                    />
                </Grid>
                <Grid item>
                    <CloseIcon fontSize="small" onClick={() => handleEndChange(null)} />
                </Grid>
            </Grid>
        </Grid>
    )
}
