import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import React, { ComponentProps, useState } from 'react'
import { ValueNarrativeSelectorItem } from './ValueNarrativeSelectorItem'
import { alpha, SvgIcon } from '@mui/material'
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/images/arrow-left.svg'
import { ValueNarrative, ValueNarrativeCategory } from '../../queries/useGetValueNarrativesQuery'
import Box from '@mui/material/Box'
import { TAB_HEIGHT } from '../ValueNarrativeCategoryTabs/ValueNarrativeCategoryTabsWrapper'
import { FigmaAccentColor } from '../../../../style/colors'

type Props = {
    isAIEnabled: boolean
    selectedCategoryColor: FigmaAccentColor
    selectedCategory: ValueNarrativeCategory
    selectedNarrative: ValueNarrative
    handleSelectNarrative: (categoryId: string, narrativeId: string) => void
}

export const ValueNarrativeSelector = ({
    isAIEnabled,
    selectedCategory,
    selectedCategoryColor,
    selectedNarrative,
    handleSelectNarrative,
}: Props) => {
    const [isAILoading, setIsAILoading] = useState(false)
    const [isAIGeneratedValueStatement, setIsASGeneratedValueStatement] = useState(false)

    const [isEditMode, setIsEditMode] = useState(false)

    const selectedNarrativeIndex = selectedCategory.narratives.findIndex(
        (narrative) => narrative._id === selectedNarrative._id
    )

    const selectNarrative = (narrativeIndex) => {
        handleSelectNarrative(selectedCategory._id, selectedCategory.narratives[narrativeIndex]._id)
        setIsEditMode(false)
        setIsASGeneratedValueStatement(false)
    }

    const selectPreviousNarrative = () => {
        selectNarrative(
            (selectedNarrativeIndex - 1 + selectedCategory.narratives.length) % selectedCategory.narratives.length
        )
    }

    const selectNextNarrative = () => {
        selectNarrative((selectedNarrativeIndex + 1) % selectedCategory.narratives.length)
    }

    const canSelectOtherNarrative = !isAILoading && !isAIGeneratedValueStatement && !isEditMode
    const canSelectPrevNarrative = canSelectOtherNarrative && !!selectedCategory.narratives[selectedNarrativeIndex - 1]
    const canSelectNextNarrative = canSelectOtherNarrative && !!selectedCategory.narratives[selectedNarrativeIndex + 1]

    const iconButtonSx: ComponentProps<typeof IconButton>['sx'] = (theme) => ({
        backgroundColor: `${alpha(selectedCategoryColor, 0.1)} !important`,
        borderColor: selectedCategoryColor,
        borderRadius: '12px',
        borderStyle: 'solid',
        borderWidth: '1px',
        height: '100%',
        width: '100%',
        minWidth: '55px',
    })

    return (
        <Grid container width="100%" gap={2} position="relative">
            {canSelectPrevNarrative && (
                <Grid item mb={3.5}>
                    <IconButton onClick={selectPreviousNarrative} sx={iconButtonSx}>
                        <SvgIcon
                            component={ArrowLeftIcon}
                            inheritViewBox
                            fontSize="small"
                            color="primary"
                            sx={{
                                transform: 'rotate(180deg)',
                            }}
                        />
                    </IconButton>
                </Grid>
            )}
            <Grid item xs>
                <ValueNarrativeSelectorItem
                    selectedNarrative={selectedNarrative}
                    isAIEnabled={isAIEnabled}
                    isAILoading={isAILoading}
                    setIsAILoading={setIsAILoading}
                    isAIGeneratedValueStatement={isAIGeneratedValueStatement}
                    setIsASGeneratedValueStatement={setIsASGeneratedValueStatement}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    categoryColor={selectedCategoryColor}
                />
            </Grid>
            {canSelectNextNarrative && (
                <Grid item mb={3.5}>
                    <IconButton onClick={selectNextNarrative} sx={iconButtonSx}>
                        <SvgIcon component={ArrowLeftIcon} inheritViewBox fontSize="small" color="primary" />
                    </IconButton>
                </Grid>
            )}
            {!isEditMode && (
                <Box
                    sx={{
                        position: 'absolute',
                        height: 0,
                        top: `calc(${TAB_HEIGHT} * 2 - 1px)`,
                        width: 'calc(100% + 75px)',
                        marginLeft: '-25px',
                        borderBottom: '1px solid',
                        borderBottomColor: 'figma.grayscale.stroke',
                    }}
                />
            )}
        </Grid>
    )
}
