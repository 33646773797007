import React from 'react'
import Box from '@mui/material/Box'
import { EmptyPlaceholderProps } from './EmptyPlaceholder.types'
import { EmptyPlaceholderOneLineContent } from './components/EmptyPlaceholderOneLineContent'
import { EmptyPlaceholderTwoLineContent } from './components/EmptyPlaceholderTwoLineContent'
import { useEmptyPlaceholderStyles } from './EmptyPlaceholder.styles'

export const EmptyPlaceholder = ({ sx, ...props }: EmptyPlaceholderProps) => {
    const styles = useEmptyPlaceholderStyles();

    return (
        <Box className={styles.container} sx={sx}>
            {'text' in props ? (
                <EmptyPlaceholderOneLineContent {...props} />
            ) : (
                <EmptyPlaceholderTwoLineContent {...props} />
            )}
        </Box>
    )
}
