import { UseActivityStatusProps } from './useActivityStatus.types';
import { ACTIVITY_STATUS } from './useActivityStatus.constants';
import { ReactComponent as PlannedIcon } from '../../../assets/images/blue_down_triangle.svg';
import { ReactComponent as InitiatedIcon } from '../../../assets/images/orange_up_triangle.svg';
import { ReactComponent as OnHoldIcon } from '../../../assets/images/diamond-purple.svg';
import { ReactComponent as CompletedIcon } from '../../../assets/images/green_circle.svg';
import { ReactComponent as CancelledIcon } from '../../../assets/images/red_square.svg';
import StopIcon from '@mui/icons-material/Stop';
import { styled } from '@mui/system';
import { COLORS } from '../../../style/colors';

const getSanofiActivityStatusIcon = ({ activityStatus }: UseActivityStatusProps) => {
    const color = getActivityStatusColor({ activityStatus, isSanofiOrganization: true });
    return styled(StopIcon)({
        color: `${color} !important`,
        height: '1.25em !important',
        width: '1.25em !important',
        transform: 'scale(1.25) !important'
    });
};

const getActivityStatusIcon = ({ activityStatus, isSanofiOrganization }: UseActivityStatusProps) => {
    if (isSanofiOrganization) {
        return getSanofiActivityStatusIcon({ activityStatus });
    }

    switch (activityStatus) {
        case ACTIVITY_STATUS.PLANNED:
            return PlannedIcon;
        case ACTIVITY_STATUS.INITIATED:
            return InitiatedIcon;
        case ACTIVITY_STATUS.ON_HOLD:
            return OnHoldIcon;
        case ACTIVITY_STATUS.CANCELLED:
            return CancelledIcon;
        case ACTIVITY_STATUS.COMPLETED:
            return CompletedIcon;
        default:
            return null;
    }
};

const getSanofiActivityStatusName = ({ activityStatus }: UseActivityStatusProps) => {
    switch (activityStatus) {
        case ACTIVITY_STATUS.PLANNED:
            return 'Planned';
        case ACTIVITY_STATUS.INITIATED:
            return 'Ongoing';
        case ACTIVITY_STATUS.ON_HOLD:
            return 'On-hold';
        case ACTIVITY_STATUS.CANCELLED:
            return 'Cancelled';
        case ACTIVITY_STATUS.COMPLETED:
            return 'Completed';
        default:
            return activityStatus;
    }
};

const getActivityStatusName = ({ activityStatus, isSanofiOrganization }: UseActivityStatusProps) => {
    if (isSanofiOrganization) {
        return getSanofiActivityStatusName({ activityStatus });
    }

    switch (activityStatus) {
        case ACTIVITY_STATUS.PLANNED:
            return 'Planned';
        case ACTIVITY_STATUS.INITIATED:
            return 'Initiated';
        case ACTIVITY_STATUS.ON_HOLD:
            return 'On Hold';
        case ACTIVITY_STATUS.CANCELLED:
            return 'Cancelled';
        case ACTIVITY_STATUS.COMPLETED:
            return 'Completed';
        default:
            return activityStatus;
    }
};

const getSanofiActivityStatusColor = ({ activityStatus }: UseActivityStatusProps) => {
    switch (activityStatus) {
        case ACTIVITY_STATUS.PLANNED:
            return COLORS.sanofiActivityPlanned;
        case ACTIVITY_STATUS.INITIATED:
            return COLORS.sanofiActivityInitiated;
        case ACTIVITY_STATUS.ON_HOLD:
            return COLORS.sanofiActivityOnHold;
        case ACTIVITY_STATUS.CANCELLED:
            return COLORS.sanofiActivityCancelled;
        case ACTIVITY_STATUS.COMPLETED:
            return COLORS.sanofiActivityCompleted;
        default:
            return COLORS.black;
    }
};

const getActivityStatusColor = ({ activityStatus, isSanofiOrganization }: UseActivityStatusProps) => {
    if (isSanofiOrganization) {
        return getSanofiActivityStatusColor({ activityStatus });
    }

    switch (activityStatus) {
        case ACTIVITY_STATUS.PLANNED:
            return COLORS.butterflyBlue;
        case ACTIVITY_STATUS.INITIATED:
            return COLORS.sunshade;
        case ACTIVITY_STATUS.ON_HOLD:
            return COLORS.purpleHeart;
        case ACTIVITY_STATUS.CANCELLED:
            return COLORS.lipstickRed;
        case ACTIVITY_STATUS.COMPLETED:
            return COLORS.caribbeanGreen;
        default:
            return COLORS.black;
    }
};

export const getActivityStatusDetails = (props: UseActivityStatusProps) => {
    const name = getActivityStatusName(props);
    const icon = getActivityStatusIcon(props);
    const color = getActivityStatusColor(props);

    return {
        name,
        icon,
        color,
    };
};
