import { useEffect } from 'react'
import { useIsDarkMode } from '../shared/hooks/useIsDarkMode'

const LIGHT_FAVICON_HREF = `${process.env.PUBLIC_URL}/favicon-white.ico`;
const DARK_FAVICON_HREF = `${process.env.PUBLIC_URL}/favicon-black.ico`;

export const useDynamicFavicon = () => {
    const isDarkMode = useIsDarkMode();

    useEffect(() => {
        const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement;

        if (favicon) {
            favicon.href = isDarkMode ? DARK_FAVICON_HREF : LIGHT_FAVICON_HREF;
        }

    }, [isDarkMode]);
};
