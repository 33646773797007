import React, { ChangeEvent, KeyboardEvent } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import TextField from '@mui/material/TextField'
import { ReactComponent as SendIcon } from '../../../../../assets/images/arrow-up.svg'
import CircularProgress from '@mui/material/CircularProgress'
import { useCommentInputStyles } from './CommentInput.styles'
import { useIsReadonlyMode } from '../../../../hooks/useIsReadonlyMode'
import { useIsSnapshotMode } from '../../../../hooks/useIsSnapshotMode'

type Props = {
    value: string
    onChange: (value: string) => void
    onSubmit: () => void
    isLoading: boolean
}

export const CommentInput = ({ onSubmit, isLoading, value, onChange }: Props) => {
    const isReadonlyMode = useIsReadonlyMode();
    const isSnapshotMode = useIsSnapshotMode();

    const styles = useCommentInputStyles()

    const canPostMessage = value.trim().length > 0 && !isLoading

    const handleSetValue = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value)
    }

    const handleAddComment = () => {
        onSubmit()
    }

    const handleAddCommentByPressingShiftEnter = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && event.shiftKey) {
            event.preventDefault()

            if (canPostMessage) {
                handleAddComment()
            }
        }
    }

    const isDisabled = isLoading || isReadonlyMode || isSnapshotMode;

    return (
        <TextField
            disabled={isDisabled}
            multiline
            minRows={1}
            maxRows={4}
            variant="outlined"
            placeholder="Enter your new comment here"
            onChange={handleSetValue}
            onKeyDown={handleAddCommentByPressingShiftEnter}
            value={value}
            fullWidth
            InputProps={{
                classes: {
                    root: styles.root,
                    input: styles.input,
                    notchedOutline: styles.notchedOutline,
                    disabled: styles.notchedOutline,
                },
                endAdornment: (
                    <InputAdornment position="end">
                        <Tooltip enterDelay={600} enterNextDelay={600} title="Post Comment" arrow>
                            <IconButton
                                onClick={handleAddComment}
                                disabled={!canPostMessage}
                                classes={{
                                    root: styles.iconButton,
                                }}>
                                {isLoading ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : (
                                    <SvgIcon
                                        component={SendIcon}
                                        color={canPostMessage ? 'primary' : 'inherit'}
                                        inheritViewBox
                                        fontSize="small"
                                    />
                                )}
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                ),
            }}
        />
    )
}
