import React, { useEffect, useState } from 'react'
import './gap.scss'
import '../../style/Views.scss'
import { ReactComponent as GapIcon } from '../../assets/images/Gap_Icon.svg'
import LeftPanel from './../Support/PageLeftPanel'
import GapTable from './GapTable'
import Grid from '@mui/material/Grid'
import { useHistory } from 'react-router-dom'
import { isStrategySelected } from '../../utilities/helpers'
import { useSelector } from 'react-redux'

const buttonToCategoryGapMapping = {
    0: 'Reimbursable Files',
    1: 'Gap Assessment',
    2: 'Evidence Gaps',
}

export default function GapAnalysis() {
    let storeGapAnalysis = useSelector((state) => state.gapAnalysisView)
    const history = useHistory()
    const [formData, setFormData] = useState({})

    const [filterCategory, setFilterCategory] = useState(
        storeGapAnalysis ? buttonToCategoryGapMapping[storeGapAnalysis] : buttonToCategoryGapMapping[0]
    )

    function createNew(incomingData) {
        setFormData(incomingData)
    }

    function filter(category) {
        setFilterCategory(category)
    }

    // if no strategy selected, navigate to strategy selection page
    useEffect(() => {
        if (!isStrategySelected()) {
            history.push('/roadmaps')
        }
    })

    return (
        <Grid container item spacing={2} direction="column" sx={{ p: 2 }}>
            <LeftPanel imgsrc={GapIcon} text={'Gap Analyses'} id="gap" createNew={createNew} filter={filter} />
            <GapTable newFormData={formData} filterCategory={buttonToCategoryGapMapping[storeGapAnalysis]} />
        </Grid>
    )
}
