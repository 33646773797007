import React from 'react'
import { makeStyles, withStyles, styled } from '@mui/styles'
import TableRow from '@mui/material/TableRow'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'

export const useStyles = makeStyles((theme) => ({
    dashContainer: {
        alignContent: 'center',
        display: 'flex',
        justifyContent: 'space-evenly',
        marginRight: '25px',
        // minHeight: '80vh',
    },
    dashColumn: {
        display: 'flex',
        flex: '1',
        margin: '0px 20px',
        // flexFlow: 'column',
        // alignContent: 'space-between',
        // flexWrap: 'wrap',
    },
    dashMidColumn: {
        display: 'flex',
        flex: '1',
        flexFlow: 'column',
        alignContent: 'center',
        margin: '0px 20px',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        height: '100%',
        '&h3': {
            margin: '0',
        },
    },
    navGrid: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    vmRouting: {
        width: '100%',
        '&:hover .arrowChange': {
            color: 'rgb(25, 110, 244)',
        },
        margin: '10px 0',
    },
    vmRoutingContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 20px',
    },
    noDataVmContent: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
    },
    vmPaper: {
        display: 'flex',
        flexFlow: 'column',
        minHeight: '30vh',
        maxHeight: '60vh',
        textAlign: 'center',
    },
    routing: {
        margin: '10px 0',
        width: '100%',
        '&:hover .arrowChange': {
            color: 'black',
        },
    },
    routingPaper: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'space-between',
        padding: '20px',
    },
    keyMarket: {
        '& > *': {
            width: '25ch',
            margin: '0',
        },
    },
    inputLabel: {
        paddingLeft: '20px',
        borderLeft: '5px solid #55B8F9',
        '& label': {
            paddingLeft: '20px',
        },
        // '& label.Mui-focused': {
        //     color: '#55B8F9',
        // },
        // '&:hover label': {
        //     color: '#55B8F9',
        // },

        '& .MuiOutlinedInput-root': {
            borderColor: 'none',

            '&:hover fieldset': {
                borderColor: '#55B8F9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#55B8F9',
            },
        },
    },
    tableRow: {
        '&:hover': {
            backgroundColor: 'blue !important',
        },
        formControl: {
            margin: theme.spacing(1),
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    },
    flexTable: {
        display: 'flex',
        flexFlow: 'column',
        width: '100%',
    },
    compColOne: {
        flex: 1,
        marginRight: '10px',
    },
    fileUploadArea: {
        marginRight: '60px',
        display: 'flex',
    },
    compColTwo: {
        flex: 1,
        marginLeft: '10px',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        '& input#file-upload-button': {
            background: 'blue',
        },
        '& input[type="file"]': {
            display: 'none',
        },
    },
    customFileUpload: {
        marginTop: '10px',
        background: '#55B8F9',
    },
    displayFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    editBtn: {
        right: '0',
        bottom: '0',
        margin: '20px',
        position: 'fixed',
    },
    btnGroupRoot: {
        display: 'flex',
        height: '100%',
    },
    midBtn: {
        display: 'flex',
        justifyContent: 'space-between !important',
        alignContent: 'center',
        flex: '1',
        color: 'secondary',
        borderColor: 'rgba(0, 0, 0, 0.23) !important',
        '& > *': {
            paddingBottom: theme.spacing(15),
        },
    },
    btnStyle: {
        margin: '30px 0 30px 0',
        background: '#55B8F9',
        color: 'white !important',
    },
}))

export const styles = (theme) => ({
    root: {
        // margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        // color: theme.palette.grey[500],
    },
})

export const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent)

export const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions)

export const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
        <MuiDialogTitle className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
})

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))
