import React, { useEffect, useState } from 'react'
import { useStyles } from '../../style/views.js'
import './gap.scss'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import { StyledTableRow } from '../Support/CustomElements.js'
import TextField from '@mui/material/TextField'
import store from '../../redux/store'
import callAPI from '../../API/callSecureAPI'
import Grid from '@mui/material/Grid'
import endpoints from '../../API/endpoints'
import { useAuth0 } from '../../contexts/auth0-context'
import { Fade } from '@mui/material/'
import * as _ from 'underscore'
import Button from '@mui/material/Button'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'

let colorMapping = {
    0: 'greyDot',
    10: 'blueDot',
    20: 'redDot',
    30: 'yellowDot',
    40: 'greenDot',
}

function createData(leverName, rationale) {
    return {
        leverName,
        rationale,
    }
}

export default function GapAssessmentTable() {
    const classes = useStyles()
    const snapshotMode = useSelector((state) => (state.strategy ? !!state.strategy.sourceId : false))
    const userReadonly = useSelector((state) => (state.userRecord ? !!state.userRecord.readonly : false))
    const { isAuthenticated, getTokenSilently } = useAuth0()

    const [dataColumns, setDataColumns] = useState([])
    const [dataRows, setDataRows] = useState([])
    const [cellValues, setCellValues] = useState({})
    const [newCellValues, setNewCellValues] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)

    const [gapAnalysis, setGapAnalysis] = useState({})

    useEffect(() => {
        if (!isAuthenticated) {
            return
        }
        getGap()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    async function getGap() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        let incomingGapAnalysis = await callAPI(
            endpoints.getGapAnalysis.url + store.getState().strategy._id,
            endpoints.getGapAnalysis.method,
            token
        )
        let goalsObj = {}
        if (incomingGapAnalysis.success) {
            goalsObj = incomingGapAnalysis.data.goals
            setGapAnalysis(incomingGapAnalysis.data)
        }

        let newColumns = []
        newColumns.push({ id: 'leverName', label: '', width: 130 })
        for (let goal of goalsObj) {
            newColumns.push({ id: goal.title, label: goal.title, align: 'center' })
        }
        newColumns.push({ id: 'rationale', label: 'Rationale' })

        setDataColumns(newColumns)

        let strat = await callAPI(
            endpoints.getStrategy.url + store.getState().strategy._id,
            endpoints.getStrategy.method,
            token
        )

        // This houses the values for the Gap Assessment
        if (incomingGapAnalysis.success && incomingGapAnalysis.data.gapAssessment) {
            setCellValues(JSON.parse(incomingGapAnalysis.data.gapAssessment))
            setNewCellValues(JSON.parse(incomingGapAnalysis.data.gapAssessment))
        }

        // if (incomingSettings.success) {
        //     createDataRows(incomingSettings.data.gapLevers)
        // }
        console.log(strat)
        createDataRows(strat.data.gapLevers)

        setIsLoaded(true)
    }

    function createDataRows(data) {
        let arr = []
        if (data) {
            data.forEach((gap) => {
                let dataRow = createData([gap.leverName, gap.parameters], gap.rationale)
                arr.push(dataRow)
            })
        }
        setDataRows(arr)
    }

    async function updateColor(key) {
        if (!snapshotMode && !userReadonly) {
            let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
            let newValue = cellValues[key] === 40 ? 0 : cellValues[key] + 10
            let newGapAssessment = await callAPI(
                endpoints.updateGapAssessmentValue.url + gapAnalysis._id,
                endpoints.updateGapAssessmentValue.method,
                token,
                { key: key, value: newValue }
            )
            if (newGapAssessment.success) {
                setCellValues(JSON.parse(newGapAssessment.data))
                setNewCellValues(JSON.parse(newGapAssessment.data))
            }
        }
    }

    function renderTableRows() {
        const newTableRows = []

        dataRows.forEach((row) => {
            const dataRow = row.leverName
            const lever = dataRow[0]
            const params = dataRow[1]

            newTableRows.push(renderTableLevers(lever, params))
            newTableRows.push(renderTableParams(lever, params))
        })

        return newTableRows
    }

    function renderTableLevers(lever, params) {
        let isRationaleColumnSpanSet = false

        return (
            <StyledTableRow>
                {dataColumns.map((col) => {
                    if (col.id === 'leverName') {
                        return (
                            <TableCell sx={{ border: 'none' }}>
                                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{lever}</Typography>
                            </TableCell>
                        )
                    } else if (col.id === 'rationale' && !isRationaleColumnSpanSet) {
                        isRationaleColumnSpanSet = true

                        // Add 1 to account for the label as well as all the params
                        return (
                            <TableCell rowSpan={params.length + 1} sx={{ border: 'none' }}>
                                <Grid item>
                                    <TextField
                                        id="filled-multiline-static"
                                        color="secondary"
                                        multiline
                                        rows={params.length + 1}
                                        fullWidth
                                        variant="filled"
                                        disabled={snapshotMode || userReadonly}
                                        label={'Rationale'}
                                        value={newCellValues['rationale|' + lever]}
                                        onChange={(e) => {
                                            let newObj = { ...newCellValues };
                                            newObj['rationale|' + lever] = e.target.value;
                                            setNewCellValues(newObj);
                                        }}
                                        sx={{
                                            '& .MuiFilledInput-root': {
                                                borderRadius: 3,
                                                position: 'relative',
                                                '&:after': {
                                                    // Underline color when textfield is active (focused)
                                                    borderBottom: '2px solid',
                                                    borderBottomColor: 'primary.main',
                                                    left: '0.5em', // Adjust left position to account for border radius
                                                    right: '0.5em', // Adjust right position to account for border radius
                                                    bottom: 0, // Adjust bottom position if needed
                                                },
                                                '&:before': {
                                                    // Underline color when textfield is inactive
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                    left: '0.5em', // Adjust left position to account for border radius
                                                    right: '0.5em', // Adjust right position to account for border radius
                                                    bottom: 0, // Adjust bottom position if needed
                                                },
                                                '&:hover:not(.Mui-disabled):before': {
                                                    // Underline color when textfield is hovered
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
                                                    left: '0.5em', // Adjust left position to account for border radius
                                                    right: '0.5em', // Adjust right position to account for border radius
                                                },
                                            },
                                        }}
                                    />
                                </Grid>

                                {!_.isEqual(cellValues['rationale|' + lever], newCellValues['rationale|' + lever]) && (
                                    <Grid container item spacing={1} alignItems="center" justifyContent="flex-end" nowrap sx={{ marginTop: 1 }}>
                                        <Grid item xs="auto">
                                            <Button
                                                variant="outlined"
                                                sx={{ borderRadius: 3 }}
                                                onClick={() => {
                                                    let newObj = {
                                                        ...newCellValues,
                                                    }
                                                    newObj['rationale|' + lever] = cellValues['rationale|' + lever]
                                                    setNewCellValues(newObj)
                                                }}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Button 
                                                variant="contained" 
                                                sx={{ borderRadius: 3 }}
                                                onClick={handleSaveRationale}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </TableCell>
                        )
                    } else {
                        return <TableCell sx={{ border: 'none' }}></TableCell>
                    }
                })}
            </StyledTableRow>
        )
    }

    function renderTableParams(lever, params) {
        return params.map((param) => {
            return (
                <StyledTableRow >
                    {dataColumns.map((col) => {
                        if (col.id === 'leverName') {
                            return (
                                <TableCell sx={{ border: 'none' }}>
                                    <Typography variant="body1">{param}</Typography>
                                </TableCell>
                            )
                        } else if (col.id === 'rationale') {
                            // do nothing because the rationale has been set by renderTableCells and spans all rows
                        } else {
                            return (
                                <TableCell align="center" sx={{ border: 'none' }}>
                                    <a
                                        href="javascript:void(0)"
                                        // eslint-disable-next-line
                                        onClick={() => updateColor(param + '|' + col.id)}>
                                        <span className={colorMapping[cellValues[param + '|' + col.id]]} />
                                    </a>
                                </TableCell>
                            )
                        }
                    })}
                </StyledTableRow>
            )
        })
    }

    async function handleSaveRationale() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        let updatedGap = {
            goals: gapAnalysis.goals,
            overallSummaryRating: gapAnalysis.overallSummaryRating,
            description: gapAnalysis.description,
            strategyId: gapAnalysis.strategyId,
            organizationId: gapAnalysis.organizationId,
            gapAssessment: JSON.stringify(newCellValues),
            reimbursableFile: gapAnalysis.reimbursableFile,
        }

        callAPI(
            endpoints.updateGapAnalysis.url + gapAnalysis._id,
            endpoints.updateGapAnalysis.method,
            token,
            updatedGap
        )
        setCellValues(newCellValues)
    }

    return (
        <Fade in={isLoaded && dataColumns.length > 0} timeout={800}>
            <Grid container>
                {isLoaded && (
                    <TableContainer>
                        {!gapAnalysis.gapAssessment ||
                        _.isEmpty(JSON.parse(gapAnalysis.gapAssessment)) ||
                        gapAnalysis.goals.length === 0 ? (
                            <Grid container item>
                                <Grid item>
                                    <Typography variant="caption">No Market Access Goals to display.</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        Create a new Market Access Goal on the dashboard to initialize this page.
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <StyledTableRow sx={{ backgroundColor: '#FAFAFA' }}>
                                        {dataColumns.map((column) => (
                                            <TableCell key={'gap' + column.id} align={column.align} sx={{ border: 'none' }}>
                                                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{column.label}</Typography>
                                            </TableCell>
                                        ))}
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>{renderTableRows()}</TableBody>
                            </Table>
                        )}
                    </TableContainer>
                )}
            </Grid>
        </Fade>
    )
}
