import { makeStyles } from '@mui/styles'

export const useSortableTableRowStyles = makeStyles({
    container: (props: { isDarkMode: boolean }) => ({
        position: 'relative',
        zIndex: 1000,
        cursor: 'grab',
        border: 'none',
        backgroundColor: props.isDarkMode ? '#1B1D20' : '#FAFAFA',
    }),
    disabled: {
        cursor: 'default !important',
    },
    active: {
        cursor: 'grabbing !important',
        zIndex: '1001 !important',
        backgroundColor: 'rgb(85, 184, 249, 0.55) !important',
    },
})
