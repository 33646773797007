import { useDispatch, useSelector } from 'react-redux'
import { setSnapshotModeNotification } from '../../../../../redux/actions'

export const useSnapshotModeFloatingNotification = () => {
    // @ts-expect-error redux store is not typed properly
    const isMinimized = useSelector((state) => !!state?.snapshotModeNotification?.isMinimized)
    // @ts-expect-error redux store is not typed properly
    const isAcknowledged = useSelector((state) => !!state?.snapshotModeNotification?.isAcknowledged)

    const dispatch = useDispatch()

    const handleToggleIsMinimized = () => {
        dispatch(setSnapshotModeNotification({ isMinimized: !isMinimized }))
    }

    return {
        isMinimized: !!isMinimized,
        isAcknowledged: !!isAcknowledged,
        handleToggleIsMinimized
    }
}