import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { AtlasAIButton } from '../../../../shared/components/AtlasAIButton'
import { useIsSnapshotMode } from '../../../../shared/hooks/useIsSnapshotMode'
import { useIsReadonlyMode } from '../../../../shared/hooks/useIsReadonlyMode'
import callAPI from '../../../../API/callSecureAPI'
import { useAuth0 } from '../../../../contexts/auth0-context'
import { PopoverComments } from '../../../../shared/components/Comments/variants/PopoverComments/PopoverComments'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { COLORS, FigmaAccentColor } from '../../../../style/colors'
import { useIsDarkMode } from '../../../../shared/hooks/useIsDarkMode'
import { alpha } from '@mui/material'
import { useUpdateValueNarrativeMutation } from '../../queries/useUpdateValueNarrativeMutation'
import { makeStyles } from '@mui/styles'

const useValueNarrativeSelectorItemStyles = (categoryColor: FigmaAccentColor) => {
    const styles = makeStyles((theme) => ({
        root: {
            paddingInline: `${theme.spacing(2)} !important`,
            paddingBlock: `${theme.spacing(1)} !important`,
            backgroundColor: `${alpha(categoryColor, 0.1)} !important`,
            borderRadius: '16px !important',
        },
        input: {
            fontFamily: 'acumin-pro !important',
            fontWeight: `700 !important`,
            fontSize: '16px !important',
            color: `${theme.palette.figma.grayscale.textHier1} !important`,
        },
        notchedOutline: {
            borderStyle: 'solid',
            borderWidth: `1px !important`,
            borderColor: `${categoryColor} !important`,
        },
    }))

    return styles()
}

export const ValueNarrativeSelectorItem = ({
    selectedNarrative,
    isAIEnabled,
    setIsASGeneratedValueStatement,
    setIsAILoading,
    isAILoading,
    isAIGeneratedValueStatement,
    isEditMode,
    setIsEditMode,
    categoryColor,
}) => {
    const isDarkMode = useIsDarkMode()
    const isSnapshotMode = useIsSnapshotMode()
    const isReadonlyMode = useIsReadonlyMode()

    const styles = useValueNarrativeSelectorItemStyles(categoryColor)

    const [name, setName] = useState(selectedNarrative.name)

    const [aiRequestController, setAiRequestController] = useState(null)

    const handleFocus = () => {
        setIsEditMode(true)
    }

    const handleSetName = (narrative: string, isAiGenerated = false) => {
        setName(narrative)
        setIsASGeneratedValueStatement(isAiGenerated)
    }

    const handleResetName = (resetName: string) => {
        handleSetName(resetName)
        setIsEditMode(false)
        setIsASGeneratedValueStatement(false)
    }

    useEffect(() => {
        handleResetName(selectedNarrative.name)

        if (aiRequestController) {
            aiRequestController.abort()
            setAiRequestController(null)
        }
    }, [selectedNarrative.name])

    useEffect(() => {
        if (name !== selectedNarrative.name) {
            setIsEditMode(true)
        }
    }, [name])

    const { getTokenSilently } = useAuth0()
    // @ts-expect-error store is not typed properly
    const strategyId = useSelector((state) => state.strategy?._id)
    // @ts-expect-error store is not typed properly
    const organizationId = useSelector((state) => state.strategy?.organizationId)

    async function generateAI() {
        if (isAILoading) {
            return
        }

        setIsAILoading(true)

        const token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        const abortController = new AbortController()
        setAiRequestController(abortController)

        try {
            const res = await callAPI(
                `/api/narrative/ai`,
                'post',
                token,
                {
                    categoryId: selectedNarrative.categoryId,
                    strategyId: strategyId,
                    narrativeId: selectedNarrative._id,
                },
                { signal: abortController.signal }
            )

            if (res.success) {
                handleSetName(res.message, true)
                setIsEditMode(true)
            }
        } catch (e) {
            console.log(e)
        }

        setIsAILoading(false)
    }

    const { mutate, isLoading } = useUpdateValueNarrativeMutation({
        onUpdateValueNarrative: () => {
            setIsAILoading(false)
            setIsASGeneratedValueStatement(false)
            setIsEditMode(false)
        },
    })

    const handleSave = () => {
        if (name !== selectedNarrative.name) {
            mutate({
                ...selectedNarrative,
                name,
            })
        } else {
            handleResetName(selectedNarrative.name)
        }
    }

    const isAIButtonVisible = isAIEnabled && !isSnapshotMode && !isReadonlyMode

    return (
        <>
            <TextField
                fullWidth
                variant="outlined"
                multiline
                helperText={isAIGeneratedValueStatement ? '*Generated with ATLAS AI - please verify accuracy.' : ' '}
                disabled={isSnapshotMode || isReadonlyMode || isLoading || isAILoading}
                value={name}
                maxRows={isEditMode ? 10 : 2}
                onChange={(e) => {
                    setName(e.target.value)
                    setIsEditMode(true)
                }}
                onFocus={handleFocus}
                InputProps={{
                    classes: {
                        notchedOutline: styles.notchedOutline,
                        input: styles.input,
                        root: styles.root,
                    },
                    endAdornment: (
                        <Box display="flex" gap={1.5} alignItems="center">
                            <PopoverComments smallerIcon dataId={selectedNarrative._id} dataType="valueNarrative" />
                            {isAIButtonVisible && (
                                <AtlasAIButton
                                    name="Generate Value Statement"
                                    onClick={generateAI}
                                    isLoading={isAILoading}
                                    isGenerated={isAIGeneratedValueStatement}
                                    size={30}
                                />
                            )}
                        </Box>
                    ),
                }}
            />
            {isEditMode && (
                <Grid container item justifyContent="flex-end" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            // @ts-expect-error theme is not typed properly
                            variant="no-outline-sm-button"
                            sx={{ color: isDarkMode ? COLORS.tuna : COLORS.gravel }}
                            onClick={() => {
                                handleResetName(selectedNarrative.name)
                            }}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            // @ts-expect-error theme is not typed properly
                            variant="no-outline-sm-button"
                            disabled={isLoading}
                            onClick={handleSave}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    )
}
