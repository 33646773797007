import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
    // setting overall height for containers inside of layout
    root: {
        backgroundColor: 'rgba( 255,255,255, 0)',
        flex: '4',
        marginLeft: '25px',
        flexWrap: 'wrap',
        width: '75vw',
    },
    container: {
        justifyContent: 'space-between',
        alignContent: 'center',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
    },
    // helper css utilized on both table/detailed views
    displayFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    // Used on Add New Button (CreateNew component)
    btnGroupSelection: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
    },
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(4),
        right: theme.spacing(5),
    },
    btnStyle: {
        margin: '30px 0 30px 0',
        background: '#55B8F9',
        color: 'white !important',
    },
    delivName: {
        fontSize: 'clamp(.6rem, 2vw, .8rem)',
    },
    datePicker: {
        border: 'transparent',
        borderBottom: '1px solid rgb(0, 51, 163)',

        '&:hover': {
            border: ' 1px solid #48a8f7',
        },
        '& .makeStyles-displayFlex-3': {
            margin: '0 !important',
        },
    },
    datePickerClose: {
        '&:hover': {
            color: 'rgb(0, 51, 163)',
        },
    },
    managerialFilter: {
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    // utilized specifically in the detailed view
    editBtn: {
        alignItems: 'center',
        display: 'flex',
    },
    vmTextOverview: {
        '& label': {},
        '& label.Mui': {},
    },
    headerFont: {
        fontSize: '1.2vw',
    },
    evidenceOverview: {
        maxHeight: '200px',
        overflowY: 'auto',
        overflowX: 'hidden',
        whiteSpace: 'break-spaces',
    },
    abstractOverview: {
        maxHeight: '250px',
        overflowY: 'auto',
        overflowX: 'hidden',
        whiteSpace: 'break-spaces',
    },
    connectedVMs: {
        height: '500px',
        overflowY: 'auto',
        overflowX: 'hidden',
    },

    selectInputLabel: {
        '& label.Mui-focused': {
            color: '#55B8F9',
        },

        '&:hover label': {
            color: '#55B8F9',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: '#55B8F9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#55B8F9',
            },
        },
    },
    inputLabel: {
        paddingLeft: '20px',
        borderLeft: '5px solid #55B8F9',
        '& label': {
            paddingLeft: '20px',
        },
        '& label.Mui-focused': {
            color: '#55B8F9',
        },

        '&:hover label': {
            color: '#55B8F9',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: '#55B8F9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#55B8F9',
            },
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 170,

        '& .MuiFilledInput-underline:before': {
            borderBottom: '10px solid #55B8F9',
        },
        '& .MuiSelect-icon': {
            color: '#55B8F9',
        },
        '& .MuiGrid-root': {
            fontSize: '18px',
        },
    },
    marketInputLabel: {
        color: '#ffffff',
        '& label.Mui': {
            color: '#ffffff',
        },
        '& label-focused': {
            color: '#ffffff',
        },
        '& label': {
            color: '#ffffff',
        },
        '& label.Mui-focused': {
            color: '#55B8F9',
        },

        '&:hover label': {
            color: '#55B8F9',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#55B8F9',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#bcbcbc',
            },
            '&:hover fieldset': {
                borderColor: '#48a8f7',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#48a8f7',
            },
        },
    },
    // Gap CSS
    dotsFormControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableRoot: {
        width: '100%',

        height: '75vh',
    },
    fab: {
        width: '40px',
        height: '40px',
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
        }),
    },
    fabExtended: {
        width: '180px',
        height: '48px',
        '& span': {
            marginLeft: theme.spacing(1),
        },
    },
    headerCSS: {
        fontSize: '.7vw',
        margin: '0 3px',
    },

    // Strategy UI

    aboutFab: {
        background: '#55B8F9',
        '&:hover': {
            background: '#35688a',
        },
    },
    managerialModal: {
        position: 'absolute',
        width: 400,
        border: '2px solid #000000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    managerialImg: {
        display: 'block',
        objectFit: 'contain',
        width: '100%',
    },
    managerialGrid: {
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        // width: '100%',
        // flexGrow: 1,
    },
    managerialCard: {
        display: 'flex',
        flexFlow: 'column',
        alignContent: 'center',
        flex: '1',
    },

    managerialContent: {
        display: 'flex',
        flexFlow: 'column',
        flex: 1,
    },
    leftManagerialChartArea: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-around',
        flexWrap: 'wrap',
    },
    rightManagerialChartArea: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    managerialLegend: {
        padding: 0,
        flex: 1,
        '& li': {
            listStyle: 'none',
            textAlign: 'center',
        },
    },
    orangeInitiated: {
        color: '#FF9C2B',
    },
    greenCompleted: {
        color: '#07CC9E',
    },
    greyOngoing: {
        color: '#999999',
    },
    chartContainer: {
        margin: '25px 0px',
        // height: '400px',
        width: '100%',
        '& p': {
            margin: 0,
        },
    },
    statusBar: {
        width: '100%',
        marginTop: '5px',
    },

    barStatusArea: {
        justifyContent: 'space-between',
    },

    // Deliverables CSS {
    delTable: {
        width: '100%',
        textAlign: 'center',
        margin: '10px 0 30px 0',
    },
    delHeader: {
        borderBottom: '2px solid white',
    },
    delRows: {
        padding: '5px 0',
    },
    deliverablesNum: {
        fontSize: 'clamp(.6rem, 2vw, 1.3rem)',
        marginRight: '5px',
    },
}))

